import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Button } from '../button';
import { Close } from '../../../assets/svg';
import './styles.scss';

type Props = {
  isOpen: boolean,
  type?: string,
  onClick: Function,
  message?: string,
  onDeleteGetValue: Function,
  onSubmitGetValue: Function,
  onCloseClick?: Function,
  t: Function,
};

class CommonModal extends PureComponent<Props> {
  static defaultProps = {
    message: '',
    type: 'delete',
    onCloseClick: () => {},
  };

  render() {
    const { type, t } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={this.props.onClick}
      >
        <div
          className={
            // eslint-disable-next-line no-constant-condition
            type === 'error' || 'contact'
              ? 'headerContainerError'
              : 'headerContainer'
          }
        >
          {this.props.type === 'error' && (
            <p className="headerText">{t('error')}</p>
          )}
          {this.props.type === 'delete' && (
            <p className="headerText">{t('modalDeleteHeader')}</p>
          )}
          {this.props.type === 'confirmation' && (
            <p className="headerText">{t('modalConfirmationHeader')}</p>
          )}
          {this.props.type === 'contact' && (
            <p className="headerText">{t('modalContactHeader')}</p>
          )}
          {this.props.type === 'error' ||
            ('contact' && (
              <button type="button" onClick={this.props.onCloseClick}>
                <Close />
              </button>
            ))}
        </div>
        <div className="bodyContainer">
          <p className="bodyParagraph">
            {this.props.message || `${t('modalDeleteMessage')}`}
          </p>
        </div>
        {this.props.type === 'delete' && (
          <div className="buttonContainer">
            <div className="footer">
              <Button
                label={t('modalDeleteYes')}
                className="buttonProceed"
                onClick={() => {
                  this.props.onClick();
                  this.props.onDeleteGetValue(true);
                }}
              />
              <Button
                label={t('modalDeleteNo')}
                className="buttonCancel"
                onClick={() => {
                  this.props.onClick();
                  this.props.onDeleteGetValue(false);
                }}
              />
            </div>
          </div>
        )}
        {this.props.type === 'confirmation' && (
          <div className="buttonContainer">
            <div className="footer">
              <Button
                label={t('modalConfirmationYes')}
                className="buttonProceed"
                onClick={() => {
                  this.props.onClick();
                  this.props.onSubmitGetValue(true);
                }}
              />
              <Button
                label={t('modalConfirmationNo')}
                className="buttonCancel"
                onClick={() => {
                  this.props.onClick();
                  this.props.onSubmitGetValue(false);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

if (process.env.NODE_ENV === 'test')
  Modal.setAppElement(document.createElement('div'));
else Modal.setAppElement('#root');

export default withTranslation()(CommonModal);
