import React, { PureComponent } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Growl } from 'primereact/growl';
import { CommonModal } from '../../../components/common/modal';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  Calendar,
  Dropdown,
} from '../../../components/primereact/inputs';
import ProgressSpinner from '../../../components/primereact/misc';
import { CalendarIcon } from '../../../assets/svg';
import { livingPrefecture, genderOptions } from '../../constants';
import userSchema from './adminUser.validation';
import './styles.scss';
import { updateUserRequestAction } from '../store/admin.request';
import { userUpdateSelector } from '../store/admin.selector';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  initialValues: Object,
  selectedId: string | null,
  isShowMessage: boolean,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
};

export const defaultInitialValues = {
  nickname: '',
  sex: '',
  mobileNumber: '',
  birthday: '',
  prefecture: '',
  balance: 0,
};

class AdminUsersEdit extends PureComponent<*, State> {
  static buildEditableData = (data: Object) => {
    const editData = { ...data };
    return {
      nickname: editData.nickname,
      sex: editData.sex,
      mobileNumber: editData.mobileNumber,
      birthday: editData.birthday ? new Date(editData.birthday) : '',
      prefecture: editData.prefecture,
    };
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    let data;
    let selectedId;
    Object.keys(nextProps).forEach((key: string) => {
      if (key === 'location') {
        const { state } = nextProps.location;
        if (state) {
          data = this.buildEditableData(state);
          selectedId = state.id;
        }
      }
    });
    if (data) return { initialValues: { ...data }, selectedId };
    return { initialValues: { ...defaultInitialValues }, selectedId };
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    initialValues: null,
    selectedId: null,
    isShowMessage: false,
    isDisconnected: false,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });

    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});

    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: `${t('toastSuccessSummmary')}`,
      detail: `${t('toastSuccessDetail')}`,
    });
  };

  showError = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'error',
      summary: `${t('toastErrorSummary')}`,
      detail: `${t('toastErrorDetail')}`,
    });
  };

  submitForm = (data: Object) => {
    const { birthday, sex, nickname, prefecture } = { ...data };
    const formattedValues = {
      sex: sex || undefined,
      nickname,
      birthday: birthday ? birthday.toISOString() : undefined,
      prefecture: prefecture || undefined,
    };
    this.props.updateUser(formattedValues, this.state.selectedId);

    this.setState({
      isShowMessage: true,
    });
  };

  growl: any;

  render() {
    const { t } = this.props;
    return (
      <div className="restaurant-container">
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onClick={() => {}}
            onDeleteGetValue={() => {}}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="users"
          userType="admin"
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'usersEditPageContainerUnscrollable'
              : 'usersEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.submitForm}
            validationSchema={userSchema}
            render={props => (
              <Form>
                <div className="restaurant-title">
                  <Button
                    icon="pi pi-arrow-left"
                    type="button"
                    className="restaurant-button restaurant-button--icon"
                    onClick={() => this.props.history.goBack()}
                  />
                  {t('editUser')}
                  <div className="pull-right">
                    <div>
                      <Button
                        label={t('save')}
                        type="submit"
                        className={
                          this.props.loading
                            ? 'restaurant-button restaurant-button--save transparent-text'
                            : 'restaurant-button restaurant-button--save'
                        }
                        disabled={!props.isValid || this.props.loading}
                      />
                      {this.props.loading && (
                        <ProgressSpinner
                          loadingText=""
                          className="restaurant-spinner restaurant-spinner--button"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <fieldset
                  className="restaurant-fieldset"
                  disabled={this.props.loading}
                >
                  <div className="restaurant-main">
                    <div className="restaurant-main-inner">
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('nickname')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputText
                              id="nickname"
                              name="nickname"
                              type="text"
                              className="restaurant-form-input"
                              value={props.values.nickname}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <ErrorMessage
                              name="nickname"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('sex')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <Dropdown
                              id="sex"
                              name="sex"
                              className="restaurant-form-input restaurant-form-input--dropdown"
                              placeholder={t('selectGender')}
                              options={genderOptions}
                              value={props.values.sex}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              disabled={this.props.loading}
                            />
                            <ErrorMessage
                              name="sex"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('mobileNumber')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputText
                              id="mobileNumber"
                              name="mobileNumber"
                              type="tel"
                              className="restaurant-form-input"
                              value={props.values.mobileNumber}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              disabled
                            />
                            <ErrorMessage
                              name="mobileNumber"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('livingPrefecture')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <Dropdown
                              id="prefecture"
                              name="prefecture"
                              className="restaurant-form-input restaurant-form-input--dropdown"
                              placeholder={t('selectPrefecture')}
                              options={livingPrefecture}
                              value={props.values.prefecture}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              disabled={this.props.loading}
                            />
                            <ErrorMessage
                              name="prefecture"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('birthday')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <div
                              className="calendar-container"
                              style={
                                this.props.loading
                                  ? { opacity: '0.5' }
                                  : { opacity: 'initial' }
                              }
                            >
                              <Calendar
                                id="birthday"
                                name="birthday"
                                value={props.values.birthday}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                disabled={this.props.loading}
                                readOnlyInput
                              />
                              <span className="restaurantCalendarIcon">
                                <CalendarIcon />
                              </span>
                            </div>
                            <ErrorMessage
                              name="birthday"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => userUpdateSelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  updateUser: (payload: Object, id: string) =>
    dispatch(updateUserRequestAction(payload, id)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminUsersEdit),
);
