import React, { PureComponent } from 'react';

type Props = {};

class ShowPass extends PureComponent<Props> {
  render() {
    return (
      <svg width="23" height="14" viewBox="0 0 23 14" fill="none">
        <path
          d="M11.5 0C7.10561 0 3.12054 2.40421 0.179963 6.3093C-0.0599877 6.62923 -0.0599877 7.0762 0.179963 7.39613C3.12054 11.3059 7.10561 13.7101 11.5 13.7101C15.8944 13.7101 19.8795 11.3059 22.82 7.40084C23.06 7.0809 23.06 6.63394 22.82 6.314C19.8795 2.40421 15.8944 0 11.5 0ZM11.8152 11.6823C8.89818 11.8658 6.48926 9.46159 6.67275 6.53984C6.82331 4.13092 8.77585 2.17838 11.1848 2.02782C14.1018 1.84433 16.5107 4.24854 16.3272 7.1703C16.172 9.57451 14.2194 11.5271 11.8152 11.6823ZM11.6694 9.45218C10.0979 9.55099 8.79938 8.25713 8.90288 6.68569C8.98287 5.38713 10.0368 4.33794 11.3353 4.25325C12.9068 4.15444 14.2053 5.4483 14.1018 7.01974C14.0171 8.323 12.9632 9.3722 11.6694 9.45218Z"
          fill="#1F0F08"
        />
      </svg>
    );
  }
}

export default ShowPass;
