import React, { PureComponent } from 'react';
// import { PagingButton } from '../button';

type Props = {
  currentPage: number,
  goToFirstPage?: Function,
  goToNextPage?: Function,
  goToPreviousPage?: Function,
  isTableLoading?: boolean,
  isTableEmpty?: boolean,
};

class Paginator extends PureComponent<Props> {
  static defaultProps = {
    goToFirstPage: () => {},
    goToNextPage: () => {},
    goToPreviousPage: () => {},
    isTableLoading: false,
    isTableEmpty: false,
  };

  render() {
    const {
      currentPage,
      goToFirstPage,
      goToNextPage,
      goToPreviousPage,
      isTableLoading,
      isTableEmpty,
    } = this.props;

    return (
      <div className="p-paginator p-paginator-bottom">
        <button
          className="p-button p-button-container"
          onClick={goToFirstPage}
          disabled={currentPage === 1 || isTableLoading}
        >
          <span className="pi-step-backward" />
        </button>
        <button
          className="p-button p-button-container"
          onClick={goToPreviousPage}
          disabled={currentPage === 1 || isTableLoading}
        >
          <span className="pi-caret-left" />
        </button>
        <span className="p-paginator-current">{currentPage}</span>
        <button
          className="p-button p-button-container"
          onClick={goToNextPage}
          disabled={isTableLoading || isTableEmpty}
        >
          <span className="pi-caret-right" />
        </button>
      </div>
    );
  }
}

export default Paginator;
