import * as yup from 'yup';

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('invalidEmailAddress')
    .required('required'),
  password: yup.string().required('required'),
});

export default loginFormSchema;
