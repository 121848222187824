/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'qs';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { Paginator } from '../../../components/common/paginator';
import './styles.scss';
import { Table } from '../../../components/common/table';
import { CommonModal } from '../../../components/common/modal';
import { tableQuerySelector } from '../store/admin.selector';
import {
  fetchRestaurantListRequestAction,
  tableRequestAction,
} from '../store/admin.request';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  query: Object,
  currFilter: string,
  currFilterValue: string,
  isSortAsc: boolean,
  sortField: string,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class AdminRestaurantsPage extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    query: {},
    currFilter: '',
    currFilterValue: '',
    isSortAsc: false,
    sortField: '',
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.getWindowWidth);
    this.props.fetchQueriedRestaurants(`/restaurants?_limit=10&_page=1`);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  }

  onClickToRedirect(destination: string, params?: Object) {
    this.props.history.push(destination, params);
  }

  getWindowWidth = () => this.setState({ currentWidth: window.innerWidth });

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  adminRestaurantQueryGenerator = (queryData?: Object, field?: string) => {
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (!key.includes('_') && key !== field && field !== undefined)
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchQueriedRestaurants(
      `/restaurants?${queryString.stringify(this.state.query)}`,
    );
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.adminRestaurantQueryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.adminRestaurantQueryGenerator({ _page: this.state.currentPage + 1 });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.adminRestaurantQueryGenerator({ _page: this.state.currentPage - 1 });
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.adminRestaurantQueryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string, value: string) => {
    const filterQuery = {
      [field]: value,
    };
    const { sortField } = this.state;
    const currSortField = sortField === field ? sortField : '';

    this.setState({
      currFilterValue: filterQuery[field],
      currFilter: field,
      sortField: currSortField,
    });

    this.adminRestaurantQueryGenerator(filterQuery, field);
  };

  reactTable: any;

  render() {
    const { t } = this.props;
    const restaurantCols = [
      {
        field: '',
        body: (rowData: Object) => {
          return (
            <div className="notificationPageLabeledButtonContainer">
              <Button
                onClick={() => this.onClickToRedirect('edit', rowData)}
                label={t('edit')}
                className="restaurant-button restaurant-button--edit"
              />
              <Button
                onClick={() => this.onClickToRedirect('details', rowData)}
                label={t('details')}
                className="restaurant-button restaurant-button--details"
              />
            </div>
          );
        },
        className: 'actions-width',
        header: `${t('actions')}`,
        filterElement: [''],
      },
      {
        field: 'userId',
        header: `${t('userID')}`,
        filterElement: ['userId', ''],
        className: 'userid-width',
        filter: true,
        sortable: false,
      },
      {
        field: 'email',
        header: `${t('emailAddress')}`,
        filterElement: ['email', ''],
        className: 'email-width',
        filter: true,
        sortable: true,
      },
      {
        field: 'name',
        header: `${t('name')}`,
        filterElement: ['name', ''],
        className: 'name-width',
        filter: true,
        sortable: true,
        body: (rowData: Object) => {
          return (
            <button
              type="button"
              onClick={() => this.onClickToRedirect('details', rowData)}
              className="restaurantPageLinkCells"
            >
              {rowData.name}
            </button>
          );
        },
      },
      {
        field: 'telephoneNumber',
        header: `${t('telephoneNum')}`,
        filterElement: ['telephoneNumber', ''],
        className: 'phone-width',
        filter: true,
        sortable: false,
      },
      {
        field: 'homepageUrl',
        header: `${t('homepageURL')}`,
        filterElement: ['homepageUrl', ''],
        className: 'homepage-width',
        filter: true,
        body: (rowData: Object) => {
          return (
            <a
              href={rowData.homepageUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="restaurantPageLinkCells"
            >
              {rowData.homepageUrl}
            </a>
          );
        },
      },
    ];
    return (
      <div className="restaurant-container">
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="restaurants"
          userType="admin"
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'restaurantEditPageContainerUnscrollable'
              : 'restaurantEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <div className="restaurant-title">
            {t('store')}
            <div className="pull-right">
              <Button
                label={t('issueAccount')}
                type="submit"
                className="restaurant-button restaurant-button--save"
                onClick={() => this.onClickToRedirect('add')}
              />
            </div>
          </div>
          <div>
            <div className="restaurantPageTableContainer restaurantRows">
              <Table
                currFilterValue={this.state.currFilterValue}
                currFilter={this.state.currFilter}
                onSort={this.getSortQuery}
                onFilter={this.getFilterQuery}
                sortOrder={this.state.isSortAsc}
                data={this.props.data}
                columns={restaurantCols}
                loading={this.props.loading}
                sortField={this.state.sortField}
                emptyMessage={t('noStoreRecords')}
              />
              <Paginator
                currentPage={this.state.currentPage}
                goToFirstPage={this.goToFirstPage}
                goToNextPage={this.goToNextPage}
                goToPreviousPage={this.goToPreviousPage}
                isTableLoading={this.props.loading}
                isTableEmpty={this.props.data.length === 0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  ...tableQuerySelector(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchRestaurants: () => dispatch(fetchRestaurantListRequestAction()),
  fetchQueriedRestaurants: (route: string) =>
    dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminRestaurantsPage),
);
