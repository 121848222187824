import React, { PureComponent } from 'react';

type Props = {};

class Upload extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.798 8.21876C11.6636 8.07288 11.5 8 11.3077 8H8.22838C8.12749 8.29164 7.95798 8.5312 7.72001 8.71868C7.48195 8.9062 7.2164 9.00001 6.92319 9.00001H5.07686C4.78363 9.00001 4.51798 8.90617 4.28006 8.71868C4.04205 8.5312 3.87264 8.29167 3.77168 8H0.692299C0.500027 8 0.336576 8.07288 0.201921 8.21876C0.06729 8.36449 0 8.54157 0 8.74996V11.2501C0 11.4584 0.06729 11.6357 0.201921 11.7813C0.336551 11.9272 0.500002 12 0.692299 12H11.3078C11.5 12 11.6636 11.9272 11.7981 11.7813C11.9328 11.6355 12 11.4584 12 11.2501V8.74996C12 8.54157 11.9328 8.36468 11.798 8.21876ZM9.09373 10.8517C9.00226 10.9507 8.89406 11.0002 8.76905 11.0002C8.64404 11.0002 8.53598 10.9507 8.44455 10.8517C8.35326 10.7528 8.30762 10.6355 8.30762 10.5001C8.30762 10.3647 8.35326 10.2474 8.44455 10.1485C8.53598 10.0497 8.64404 10 8.76905 10C8.89406 10 9.00224 10.0496 9.09373 10.1485C9.18499 10.2473 9.23066 10.3647 9.23066 10.5001C9.23066 10.6355 9.18499 10.7528 9.09373 10.8517ZM10.6152 11.0002C10.7402 11.0002 10.8485 10.9507 10.9397 10.8517C11.0312 10.7528 11.0768 10.6355 11.0768 10.5001C11.0768 10.3647 11.0311 10.2473 10.9397 10.1485C10.8484 10.0496 10.7402 10 10.6152 10C10.4902 10 10.3821 10.0497 10.2907 10.1485C10.1995 10.2474 10.1538 10.3647 10.1538 10.5001C10.1538 10.6355 10.1995 10.7528 10.2907 10.8517C10.3821 10.9507 10.4902 11.0002 10.6152 11.0002Z"
          fill="#4A4A4A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.94357 4.23527H4.69008V7.52938C4.69008 7.65681 4.73331 7.76714 4.81969 7.86024C4.90612 7.95328 5.00846 8 5.12667 8H6.87335C6.99158 8 7.09383 7.95331 7.18033 7.86024C7.26669 7.76716 7.30987 7.65683 7.30987 7.52938V4.23527H9.05642C9.24751 4.23527 9.38171 4.13718 9.45892 3.94117C9.53627 3.75002 9.50437 3.58087 9.36341 3.4338L6.30695 0.139739C6.22501 0.0465881 6.12281 0 5.99996 0C5.87723 0 5.77489 0.0465881 5.69298 0.139739L2.63661 3.4338C2.49558 3.58087 2.46378 3.74992 2.54105 3.94117C2.61845 4.13728 2.7526 4.23527 2.94357 4.23527Z"
          fill="#4A4A4A"
        />
      </svg>
    );
  }
}

export default Upload;
