import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class Restaurant extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={this.props.className ? this.props.className : ''}
          d="M22.5768 2.60128V1.29205C22.5768 0.578514 22.0026 0 21.2944 0H2.70419C1.99617 0 1.42203 0.578514 1.42203 1.29205V2.60161C1.42592 2.60161 1.42981 2.60128 1.4337 2.60128H22.5768Z"
          fill="white"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          d="M2.2161 9.60173C3.26809 9.60173 4.22282 9.02889 4.75027 8.19313C5.17107 9.02889 6.05253 9.60173 7.10452 9.60173C8.15666 9.60173 9.08773 9.02889 9.5813 8.19313C10.036 9.02889 10.9408 9.60173 11.9928 9.60173C13.0448 9.60173 13.9526 9.02889 14.412 8.19313C14.9009 9.02889 15.8292 9.60173 16.8812 9.60173C17.9332 9.60173 18.8175 9.02889 19.2429 8.19313C19.7658 9.02889 20.7174 9.60173 21.7694 9.60173C23.4721 9.60173 24 8.38116 24 8.06054C23.9989 7.96458 23.983 7.86813 23.9525 7.77493L22.588 4.1617H1.43368L0.0487517 7.78401C0.0100114 7.90023 -0.00554958 8.02164 0.00174465 8.14061C0.00174465 8.62187 0.797461 9.60173 2.2161 9.60173Z"
          fill="white"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          d="M22.5767 21.672V11.0875C22.3147 11.1367 22.0448 11.1625 21.7694 11.1625C20.887 11.1625 20.0282 10.8959 19.2998 10.4218C18.6086 10.8962 17.7706 11.1625 16.8812 11.1625C15.9963 11.1625 15.1447 10.8959 14.4292 10.4213C13.7252 10.896 12.8799 11.1625 11.9929 11.1625C11.1055 11.1625 10.2613 10.896 9.55877 10.4216C8.84167 10.8959 7.98922 11.1625 7.10467 11.1625C6.21494 11.1625 5.37773 10.8965 4.68818 10.4219C3.95827 10.8959 3.09853 11.1625 2.21625 11.1625C1.94539 11.1625 1.68005 11.1376 1.42232 11.0897V21.672H3.6122V13.8492C3.6122 13.4195 3.96038 13.0712 4.39025 13.0712H9.6202C10.0499 13.0712 10.3983 13.4195 10.3983 13.8492V21.672H22.5767ZM12.7579 13.8492C12.7579 13.4195 13.1064 13.0712 13.5359 13.0712H19.6086C20.0383 13.0712 20.3866 13.4195 20.3866 13.8492V18.884C20.3866 19.3135 20.0383 19.662 19.6086 19.662H13.5359C13.1064 19.662 12.7579 19.3135 12.7579 18.884V13.8492Z"
          fill="white"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          d="M5.16834 14.6273H8.84186V21.6721H5.16834V14.6273Z"
          fill="white"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          d="M14.314 14.6273H18.8305V18.1061H14.314V14.6273Z"
          fill="white"
        />
        <rect
          className={this.props.className ? this.props.className : ''}
          x="1.40517"
          y="2.16356"
          width="21.246"
          height="3.03514"
          fill="white"
        />
      </svg>
    );
  }
}

export default Restaurant;
