import React, { PureComponent } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { TimePickerInput } from '../../../components/common/input';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
  Dropdown,
} from '../../../components/primereact/inputs';
import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
};

class AdminWhatToEatTodayEdit extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  submitForm = (data: Object) => {
    console.log(data);
  };

  fileInput: any;

  render() {
    const { t } = this.props;

    const restaurantSchema = yup.object().shape({
      active: yup.string().required(`${t('required')}`),
      title: yup.string().required(`${t('required')}`),
      description: yup.string().required(`${t('required')}`),
      day: yup.number().required(`${t('required')}`),
      time: yup.string().required(`${t('required')}`),
      menu: yup.string(),
    });

    const options = [
      { value: '', label: 'None', image: '' },
      {
        value:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD-FFLeDKGXgyoWGVZ_WmZpV1VC06fKxxUrO_V-mKSIyF0Wen7BQ',
        label: 'Steak Sandwich',
      },
      {
        value:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6GhcDyddzimB7IrAXna9XTrROKWvxavSQZz4FYPjcADksddEx3Q',
        label: 'Meatball and Veggies',
      },
      {
        value:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvPMHARPMxRPjnFjmEs_gbrhIGKSBQPEpxyGalEVenJpyV6Tdsyg',
        label: 'Pasta',
      },
    ];

    return (
      <>
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="what-to-eat-today"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'campaignEditPageContainerUnscrollable'
              : 'campaignEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <Formik
            initialValues={{
              title: '',
              description: '',
              day: 0,
              time: '00:00',
              menu: '',
            }}
            onSubmit={this.submitForm}
            validationSchema={restaurantSchema}
            render={props => (
              <Form>
                <div className="restaurant-title">
                  <Button
                    icon="pi pi-arrow-left"
                    type="button"
                    className="restaurant-button restaurant-button--icon"
                    onClick={() => this.props.history.goBack()}
                  />
                  {t('editWhatToEatToday')}
                  <div className="pull-right">
                    <Button
                      label={t('save')}
                      type="submit"
                      className="restaurant-button restaurant-button--save"
                      disabled={!props.isValid}
                    />
                  </div>
                </div>
                <div className="restaurant-main">
                  <div className="restaurant-main-inner">
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('title')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="title"
                            name="title"
                            type="text"
                            className="restaurant-form-input"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage
                            name="title"
                            component="label"
                            className="restaurant-form-error"
                          />
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('description')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputTextarea
                            id="description"
                            name="description"
                            className="restaurant-form-input"
                            value={props.values.description}
                            rows={5}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage
                            name="description"
                            component="label"
                            className="restaurant-form-error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="restaurant-column">
                      <div className="restaurant-form-group restaurant-form-group--timer">
                        <div>
                          <div className="restaurant-form-group__label">
                            {t('after')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputText
                              id="day"
                              name="day"
                              type="text"
                              className="restaurant-form-input"
                              value={props.values.day}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <ErrorMessage
                              name="day"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                        <span>{t('daysAt')}</span>
                        <div>
                          <div className="restaurant-form-group__label">
                            {t('time')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <Field
                              id="time"
                              name="time"
                              inputReadOnly
                              use12Hours
                              allowEmpty={false}
                              showSecond={false}
                              minuteStep={10}
                              value={props.values.time}
                              component={TimePickerInput}
                            />
                            <ErrorMessage
                              name="time"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('menuOptional')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <Dropdown
                            id="menu"
                            name="menu"
                            className="restaurant-form-input restaurant-form-input--dropdown"
                            placeholder="Select menu"
                            options={options}
                            value={props.values.menu}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage
                            name="menu"
                            component="label"
                            className="restaurant-form-error"
                          />
                        </div>
                        <div
                          className={
                            props.values.menu === ''
                              ? 'campaignEditImageContainerHidden'
                              : 'campaignEditImageContainer'
                          }
                        >
                          <div className="campaignEditImage">
                            <img
                              className="campaignEditMenuImage"
                              src={props.values.menu}
                              alt={props.values.menu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </>
    );
  }
}

export default withTranslation()(AdminWhatToEatTodayEdit);
