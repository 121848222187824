import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../../sass/App.scss';
import { Route, Switch } from 'react-router-dom';
import AdminRoutes from '../admin/routes';
import RestaurantRoutes from '../restaurant/routes';
import Login from '../auth/Login';
import { Error404, MaintenancePage } from '../errors';
import Index from './index/Index';
import { AuthContext } from '../auth/AuthContextProvider';
import CaptchaValidation from '../captchaValidation/CaptchaValidation';
import ProgressSpinner from '../../components/primereact/misc';

const App = () => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  let content;

  if (auth.isAuthenticated && !auth.isAdmin)
    content = <Route path="/store" component={RestaurantRoutes} />;
  if (auth.isAuthenticated && auth.isAdmin)
    content = <Route path="/admin" component={AdminRoutes} />;

  // TODO: Show activity indicator when transitioning from not authenticated and still fetching
  // to authenticated but still fetching for other authentication like restaurant.
  return (
    <Switch>
      {content}
      {!auth.fetching && !auth.isAuthenticated ? (
        <Route exact path="/login" component={Login} />
      ) : null}
      {auth.fetching ? (
        <Fragment>
          <div className="restaurant-spinner-container restaurant-spinner-container--auth">
            <ProgressSpinner
              loadingText={t('loading')}
              className="restaurant-spinner"
            />
          </div>
        </Fragment>
      ) : null}
      <Route exact path="/" component={Index} />
      <Route exact path="/captcha-validation" component={CaptchaValidation} />
      <Route exact path="/maintenance" component={MaintenancePage} />
      <Route component={Error404} />
    </Switch>
  );
};

export default App;
