import { createAction } from 'redux-starter-kit';
import ApiService from '../../../service/api/Api.service';
import { searchRequestAction } from '../../../store/request/request.action';
import {
  fetchRestaurantListReducerAction,
  fetchRestaurantReducerAction,
  addRestaurantReducerAction,
  updateRestaurantReducerAction,
  fetchCampaignReducerAction,
  fetchCampaignItemReducerAction,
  addCampaignReducerAction,
  updateCampaignReducerAction,
  fetchHiringReducerAction,
  fetchUserReducerAction,
  updateUserReducerAction,
  fetchQueriedListReducerAction,
  fetchCouponStatsReducerAction,
  fetchCuisinesReducerAction,
  deleteUserReducerAction,
  fetchAppNotificationInfoAction,
  addAppNotificationReducerAction,
  updateAppNotificationReducerAction,
} from './admin.reducer';

const FETCH_LIMIT = 10;
const DEFAULT_PAGE = 1;

export const refreshUserAfterDeleteAction = createAction(
  'REQUEST_REFRESH_USER_AFTER_DELETE',
);

export const updateRestaurantAction = createAction('REQUEST_UPDATE_RESTAURANT');

export const deleteRestaurantAction = createAction(
  'DELETE_RESTAURANT_RESULT_ACTION',
);

export const addCampaign = createAction('REQUEST_ADD_CAMPAIGN');

export const updateCampaign = createAction('REQUEST_UPDATE_CAMPAIGN');

export const deleteCampaign = createAction('REQUEST_DELETE_CAMPAIGN');

export const deleteAppNotification = createAction(
  'REQUEST_DELETE_APP_NOTIFICATION',
);

export const addAppNotification = createAction('REQUEST_ADD_APP_NOTIFICATION');

export const updateAppNotification = createAction(
  'REQUEST_UPDATE_APP_NOTIFICATOIN',
);

export const fetchRestaurantListRequestAction = () => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants?_limit=${FETCH_LIMIT}`,
  resultReducerAction: fetchRestaurantListReducerAction,
});

export const fetchRestaurantRequestAction = (id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${id}`,
  resultReducerAction: fetchRestaurantReducerAction,
});

export const fetchCouponsRequestAction = () => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: '/coupons/stats',
  resultReducerAction: fetchCouponStatsReducerAction,
});

export const tableRequestAction = (route: Object) =>
  searchRequestAction(
    ApiService.getApi().get,
    route,
    fetchQueriedListReducerAction,
  );

export const addRestaurantRequestAction = (payload: Object) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: '/restaurants/register',
  resultReducerAction: addRestaurantReducerAction,
  payload,
});

export const updateRestaurantRequestAction = (payload: Object, id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/restaurants/${id}`,
  resultReducerAction: updateRestaurantReducerAction,
  payload,
});

export const deleteRestaurantRequestAction = (id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/restaurants/${id}`,
  resultReducerAction: deleteRestaurantAction,
});

export const fetchCampaignRequestAction = () => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/campaigns?_limit=${FETCH_LIMIT}`,
  resultReducerAction: fetchCampaignReducerAction,
});

export const fetchCampaignItemRequestAction = (campaignId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/campaigns/${campaignId}`,
  resultReducerAction: fetchCampaignItemReducerAction,
});

export const addCampaignRequestAction = (payload: Object) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: `/campaigns`,
  resultReducerAction: addCampaignReducerAction,
  payload,
});

export const updateCampaignRequestAction = (payload: Object, id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/campaigns/${id}`,
  resultReducerAction: updateCampaignReducerAction,
  payload,
});

export const deleteCampaignRequestAction = (id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/campaigns/${id}`,
  resultReducerAction: deleteCampaign,
});

export const fetchHiringRequestAction = () => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/hirings?_limit=${FETCH_LIMIT}`,
  resultReducerAction: fetchHiringReducerAction,
});

export const fetchUserRequestAction = (page?: number) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/users?_limit=${FETCH_LIMIT}&_page=${page || DEFAULT_PAGE}`,
  resultReducerAction: fetchUserReducerAction,
});

export const updateUserRequestAction = (payload: Object, id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/users/${id}`,
  resultReducerAction: updateUserReducerAction,
  payload,
});

export const deleteUserRequestAction = (id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/users/${id}`,
  resultReducerAction: deleteUserReducerAction,
});

export const fetchCuisinesRequestAction = () => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/lists/cuisine`,
  resultReducerAction: fetchCuisinesReducerAction,
});

export const fetchAppNotificationInfo = (notificationId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/appNotifications/${notificationId}`,
  resultReducerAction: fetchAppNotificationInfoAction,
});

export const deleteAppNotificationRequestAction = (notificationId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/appNotifications/${notificationId}`,
  resultReducerAction: deleteAppNotification,
});

export const addAppNotificationRequestAction = (payload: Object) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: `/appNotifications`,
  resultReducerAction: addAppNotificationReducerAction,
  payload,
});

export const updateAppNotificationRequestAction = (
  payload: Object,
  id: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/appNotifications/${id}`,
  resultReducerAction: updateAppNotificationReducerAction,
  payload,
});
