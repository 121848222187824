import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isModalOpen: boolean,
  selectedId: string,
};

class AdminWhatToEatTodayDetails extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isModalOpen: false,
    selectedId: '',
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = (id: number) => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      selectedId: id.toString(),
    }));
  };

  onClickToRedirect = (destination: string, params?: Object) => {
    this.props.history.push(destination, params);
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    if (isDeleting) this.props.deleteCampaign(this.state.selectedId);
  };

  fileInput: any;

  render() {
    const { t } = this.props;

    return (
      <>
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="what-to-eat-today"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'campaignEditPageContainerUnscrollable'
              : 'campaignEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <div className="restaurant-title">
            <Button
              icon="pi pi-arrow-left"
              type="button"
              className="restaurant-button restaurant-button--icon"
              onClick={() => this.props.history.goBack()}
            />
            {t('viewWhatToEatToday')}
            <div className="pull-right">
              <Button
                label={t('edit')}
                type="button"
                className="restaurant-button restaurant-button--edit"
                onClick={() => this.onClickToRedirect('edit')}
              />
              <Button
                label={t('delete')}
                type="button"
                className="restaurant-button restaurant-button--delete"
                onClick={this.onDeleteClick}
              />
            </div>
          </div>
          <div className="restaurant-main">
            <div className="restaurant-main-inner">
              <div className="restaurant-column">
                <div className="restaurant-form-group">
                  <div className="restaurant-form-group__label">
                    {t('active')}
                  </div>
                  <div className="restaurant-form-group__data">On</div>
                </div>
                <div className="restaurant-form-group">
                  <div className="restaurant-form-group__label">
                    {t('title')}
                  </div>
                  <div className="restaurant-form-group__data">
                    30% OFF on our Double Beef Hamburg with Egg
                  </div>
                </div>
                <div className="restaurant-form-group">
                  <div className="restaurant-form-group__label">
                    {t('description')}
                  </div>
                  <div className="restaurant-form-group__data">
                    Takoyaki is a ball-shaped Japanese snack made of a wheat
                    flour-based batter and cooked in a special takoyaki pan. It
                    is typically filled with minced or diced octopus, tempura
                    scraps, pickled ginger, and green onion. Takoyaki are
                    brushed with takoyaki sauce and mayonnaise, and then
                    sprinkled with green laverand shavings of dried bonito.
                  </div>
                </div>
              </div>
              <div className="restaurant-column">
                <div className="restaurant-form-group restaurant-form-group--timer">
                  <div>
                    <div className="restaurant-form-group__label">
                      {t('after')}
                    </div>
                    <div className="restaurant-form-group__data">3</div>
                  </div>
                  <span>{t('daysAt')}</span>
                  <div>
                    <div className="restaurant-form-group__label">
                      {t('time')}
                    </div>
                    <div className="restaurant-form-group__data">11:00 AM</div>
                  </div>
                </div>
                <div className="restaurant-form-group">
                  <div className="restaurant-form-group__label">
                    {t('menuOptional')}
                  </div>
                  <div className="restaurant-form-group__data">
                    <img
                      className="notificationDetailsLogo"
                      src="https://www.logomoose.com/wp-content/uploads/2013/04/sushi_logo.jpg"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AdminWhatToEatTodayDetails);
