/* eslint-disable no-underscore-dangle */
import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'qs';
import firebase from 'firebase/app';
import 'firebase/auth';
import { CommonModal } from '../../../components/common/modal';
import { Table } from '../../../components/common/table';
import { Paginator } from '../../../components/common/paginator';
import { tableRequestAction } from '../store/restaurant.request';
import { tableQuerySelector } from '../store/restaurant.selector';
import './styles.scss';

type State = {
  query: Object,
  currFilter: string,
  currFilterValue: string,
  isSortAsc: boolean,
  sortField: string | boolean,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class RestaurantUsageHistoriesPage extends PureComponent<*, State> {
  state = {
    query: {},
    currFilter: '',
    currFilterValue: '',
    sortField: '',
    isSortAsc: false,
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchQueriedTransactionHistory(
        `/restaurants/${uid}/transactionHistory?_limit=10&_page=1`,
      );
    }
  }

  componentDidUpdate(props: Object, nextState: Object) {
    /* eslint-disable-next-line react/no-did-update-set-state */
    if (nextState.isErrorModalOpen) this.setState({ isErrorModalOpen: false });
  }

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  getPaginationQuery = (pageNum: number) => {
    this.restaurantTransactionHistoryGenerator({ _page: pageNum });
  };

  restaurantTransactionHistoryGenerator = (
    queryData?: Object,
    field?: string,
  ) => {
    let uid = '';
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (!key.includes('_') && key !== field && field !== undefined)
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }

    const user = firebase.auth().currentUser;
    if (user) {
      /* eslint-disable-next-line prefer-destructuring */
      uid = user.uid;
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchQueriedTransactionHistory(
      `/restaurants/${uid}/transactionHistory?${queryString.stringify(
        this.state.query,
      )}`,
    );
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.restaurantTransactionHistoryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string, value: string) => {
    const filterQuery = {
      [field]: value,
    };
    const { sortField } = this.state;
    const currSortField = sortField === field ? sortField : '';

    this.setState({
      currFilterValue: filterQuery[field],
      currFilter: field,
      sortField: currSortField,
    });

    this.restaurantTransactionHistoryGenerator(filterQuery, field);
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.restaurantTransactionHistoryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.restaurantTransactionHistoryGenerator({
      _page: this.state.currentPage + 1,
    });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.restaurantTransactionHistoryGenerator({
      _page: this.state.currentPage - 1,
    });
  };

  roundUsageRatePercentage = (number: number) => {
    return parseFloat(number).toFixed(1);
  };

  reactTable: any;

  render() {
    const { t } = this.props;

    const usageHistoryCols = [
      {
        field: 'redeemerWalletId',
        header: `${t('walletAddress')}`,
        className: 'wallet-add-width',
        sortable: true,
        filter: true,
        filterElement: ['redeemerWalletId', ''],
      },
      {
        field: 'totalBillAmount',
        header: `${t('totalAmtPaid')}`,
        className: 'total-paid-width',
        sortable: true,
        filter: true,
        filterElement: ['totalBillAmount', ''],
      },
      {
        field: 'totalCouponAmount',
        header: `${t('couponsUsed')}`,
        className: 'coupons-used-width',
        sortable: true,
        filter: true,
        filterElement: ['totalCouponAmount', ''],
      },
      {
        sortable: true,
        filter: true,
        field: 'usageRatePercent',
        header: `${t('actualUsageRate')}`,
        className: 'actual-usage-width',
        filterElement: ['usageRatePercent', ''],
        body: rawData => {
          return `${this.roundUsageRatePercentage(rawData.usageRatePercent)}%`;
        },
      },
      {
        field: 'maximumUsageRate',
        header: `${t('maxUsageRate')}`,
        className: 'max-usage-width',
        sortable: true,
        filter: true,
        filterElement: ['maximumUsageRate', ''],
        body: () => {
          return `${this.props.usageRate}%`;
        },
      },
    ];

    return (
      <Fragment>
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <div className="restaurant-title">{t('usageHistories')}</div>
        <div className="restaurant-main">
          <div className="table-container usage-history">
            <Table
              data={this.props.data}
              columns={usageHistoryCols}
              sortOrder={this.state.isSortAsc}
              currFilterValue={this.state.currFilterValue}
              currFilter={this.state.currFilter}
              onSort={this.getSortQuery}
              onFilter={this.getFilterQuery}
              loading={this.props.loading}
              sortField={this.state.sortField}
              emptyMessage={t('noUsageHistory')}
            />
            <Paginator
              currentPage={this.state.currentPage}
              goToFirstPage={this.goToFirstPage}
              goToNextPage={this.goToNextPage}
              goToPreviousPage={this.goToPreviousPage}
              isTableLoading={this.props.loading}
              isTableEmpty={this.props.data.length === 0}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => tableQuerySelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  fetchQueriedTransactionHistory: (route: string) =>
    dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantUsageHistoriesPage),
);
