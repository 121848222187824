import React, { PureComponent } from 'react';

type Props = {};

class Logo extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="124"
        height="52"
        viewBox="0 0 124 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M47.3415 38.147C47.3415 43.196 43.2313 47.2889 38.1608 47.2889H11.8289C6.75842 47.2889 2.64819 43.1982 2.64819 38.147V11.9239C2.64819 6.87487 6.75842 2.78198 11.8289 2.78198H38.1608C43.2313 2.78198 47.3415 6.87487 47.3415 11.9239V38.147Z"
          fill="#E7251E"
        />
        <path
          d="M29.6841 36.2043C29.1366 36.2043 28.65 36.027 28.2807 35.6917C28.0439 35.4905 27.8484 35.2287 27.7137 34.9302L27.6855 34.8588L25.1372 27.3047L22.565 34.9021C22.3956 35.2871 22.1371 35.603 21.796 35.8366C21.4419 36.0789 21.0313 36.2022 20.5794 36.2022C20.0298 36.2022 19.5562 36.0335 19.1739 35.6981C18.9371 35.5056 18.7372 35.233 18.6004 34.9086L18.5808 34.8566L14.1034 21.6326C14.0274 21.3903 13.9905 21.1827 13.9905 20.9858C13.9905 20.4645 14.2164 20.0037 14.6444 19.6554C15.0311 19.3374 15.4981 19.1773 16.0304 19.1773C16.5626 19.1773 17.0297 19.3374 17.4164 19.6554C17.6554 19.8609 17.8226 20.0837 17.9313 20.3304L17.9573 20.3974L20.5751 28.1138L23.182 20.4082C23.2602 20.1551 23.4644 19.7766 23.9597 19.4845C24.3052 19.2812 24.7005 19.1773 25.1329 19.1773C25.5152 19.1773 25.8802 19.2682 26.2191 19.4477C26.6058 19.6554 26.8925 19.9691 27.0468 20.3585L27.0598 20.3952L29.6797 28.1181L32.3214 20.3606C32.4257 20.0946 32.6038 19.8566 32.8493 19.6533C33.2382 19.3353 33.7031 19.1752 34.2353 19.1752C34.7697 19.1752 35.2346 19.3374 35.6213 19.6554C36.0428 20.0059 36.2644 20.4645 36.2644 20.9837L36.2622 21.0291C36.2513 21.2259 36.2101 21.4293 36.1405 21.637L31.6632 34.8977C31.4937 35.285 31.233 35.5986 30.892 35.8344C30.5444 36.081 30.1359 36.2043 29.6841 36.2043Z"
          fill="white"
        />
        <path
          d="M25.3457 42.9191C24.0422 42.9191 22.7301 42.7763 21.4288 42.4886C16.7472 41.4567 12.7478 38.6705 10.1713 34.6425C7.59263 30.6145 6.74539 25.8294 7.78164 21.1675C8.81788 16.5057 11.616 12.5232 15.661 9.95536C19.7039 7.38757 24.5115 6.5439 29.1931 7.57578C31.1396 8.0041 32.9818 8.74177 34.6676 9.76716C34.9196 9.92075 35.1716 10.083 35.4192 10.2496C35.58 10.3577 35.7451 10.4745 35.9059 10.5914C36.7488 11.2079 36.9291 12.3847 36.3121 13.224C35.693 14.0634 34.509 14.2429 33.6683 13.6286C33.5444 13.5377 33.4184 13.4512 33.2924 13.3647C33.0969 13.2305 32.8992 13.1051 32.6994 12.9839C31.3677 12.1749 29.9143 11.593 28.3762 11.2533C24.6809 10.4378 20.8879 11.1062 17.6944 13.131C14.5031 15.1558 12.2938 18.2991 11.4769 21.9788C10.6579 25.6585 11.327 29.4355 13.3626 32.6155C15.3982 35.7933 18.5525 37.9934 22.2478 38.8068C25.9431 39.6223 29.7362 38.956 32.9296 36.929C36.1209 34.9021 38.3303 31.761 39.1493 28.0792C39.6251 25.9375 39.6033 23.7786 39.0798 21.6629C38.83 20.6527 39.4491 19.6316 40.4636 19.3828C41.4781 19.1341 42.5035 19.7506 42.7534 20.7608C43.4159 23.4433 43.4464 26.1776 42.8446 28.8925C41.8083 33.5544 39.0103 37.5369 34.9652 40.1047C32.0411 41.963 28.726 42.9191 25.3457 42.9191Z"
          fill="white"
        />
        <path
          d="M40.796 22.9803H34.6154C33.5705 22.9803 32.7233 22.1366 32.7233 21.0961C32.7233 20.0556 33.5705 19.2119 34.6154 19.2119H40.796C41.8409 19.2119 42.6882 20.0556 42.6882 21.0961C42.6882 22.1366 41.8409 22.9803 40.796 22.9803Z"
          fill="white"
        />
        <path
          d="M79.3077 24.7299C79.0377 24.7299 78.8087 24.6264 78.6309 24.4231C78.5181 24.3018 78.4292 24.1484 78.3677 23.9772L76.7474 17.8489L75.1305 23.9451C75.0485 24.1877 74.9289 24.3696 74.7716 24.5087C74.4024 24.8333 73.8282 24.7941 73.5 24.4267C73.394 24.3161 73.3017 24.1627 73.2402 23.9736L70.7175 14.4601C70.6833 14.3317 70.6662 14.2104 70.6662 14.1069C70.6662 13.8323 70.7585 13.5968 70.9465 13.4042C71.3157 13.0154 71.9276 13.0154 72.2933 13.4042C72.3959 13.5148 72.4677 13.6397 72.5155 13.7752L74.1768 20.0213L75.8279 13.8002C75.8757 13.604 75.9885 13.4328 76.1629 13.3043C76.4774 13.0653 76.9252 13.0404 77.2807 13.2829C77.455 13.4007 77.5781 13.5719 77.643 13.7823L79.3009 20.0213L80.9622 13.793C81.0066 13.6432 81.0853 13.5148 81.1912 13.4042C81.5604 13.019 82.1757 13.0225 82.5381 13.4042C82.7192 13.5968 82.8115 13.8323 82.8115 14.1034C82.8047 14.2282 82.7876 14.3424 82.7568 14.4637L80.2478 23.9451C80.1623 24.1877 80.0461 24.3696 79.8889 24.5087C79.7316 24.655 79.5333 24.7299 79.3077 24.7299Z"
          fill="#E7251E"
        />
        <path
          d="M88.0958 24.7299C86.935 24.7299 85.9271 24.3116 85.1048 23.4933C84.2825 22.6713 83.8677 21.6675 83.8677 20.5072C83.8677 19.347 84.2825 18.3395 85.1048 17.5175C85.9271 16.6956 86.9314 16.2809 88.0921 16.2809C89.2456 16.2809 90.2498 16.6956 91.0795 17.5175C91.4906 17.9285 91.7999 18.3904 92.0037 18.8851C92.2074 19.3797 92.313 19.9253 92.3166 20.5036C92.3166 21.6784 91.8982 22.6822 91.0758 23.4933C90.2535 24.3152 89.2492 24.7299 88.0958 24.7299ZM88.0958 18.285C87.7865 18.285 87.4918 18.3432 87.2261 18.4596C86.9569 18.5759 86.7204 18.7396 86.5239 18.936C86.3274 19.136 86.1673 19.3725 86.0509 19.6452C85.9344 19.9107 85.8762 20.2017 85.8762 20.5036C85.8762 20.8055 85.9344 21.0928 86.0509 21.3619C86.1673 21.6311 86.3274 21.8711 86.5239 22.0675C86.7204 22.2676 86.9569 22.4276 87.2261 22.544C87.7574 22.7768 88.4196 22.784 88.9618 22.544C89.231 22.4276 89.4712 22.2639 89.6713 22.0639C89.8714 21.8639 90.0315 21.6238 90.148 21.3547C90.2644 21.0892 90.3226 20.8018 90.3226 20.5C90.3226 20.1944 90.2644 19.9144 90.148 19.6452C90.0315 19.3761 89.8714 19.136 89.6713 18.9396C89.4712 18.736 89.2347 18.5759 88.9654 18.4559C88.6925 18.3432 88.4014 18.285 88.0958 18.285Z"
          fill="#E7251E"
        />
        <path
          d="M94.3492 24.7299C94.0768 24.7299 93.8432 24.6317 93.6557 24.4353C93.4682 24.2426 93.3727 24.0025 93.3727 23.7298V17.2783C93.3727 17.0056 93.4682 16.7655 93.6557 16.5728C94.0343 16.1873 94.6535 16.18 95.0391 16.5728C95.1453 16.6819 95.2266 16.8092 95.2726 16.9474C95.5344 16.7728 95.814 16.631 96.1041 16.5255C96.7126 16.2964 97.3459 16.2273 98.1668 16.3255C98.418 16.3582 98.655 16.4128 98.8638 16.4928C99.0902 16.5764 99.2848 16.6964 99.4369 16.8492C99.6174 17.031 99.7094 17.2601 99.7094 17.5365C99.7094 17.6747 99.6811 17.8093 99.628 17.9329C99.5749 18.0529 99.5077 18.1584 99.4228 18.2457C99.3343 18.3402 99.2282 18.413 99.1079 18.4639C98.8921 18.5584 98.6303 18.5802 98.3224 18.442C97.8342 18.242 97.169 18.2275 96.6419 18.4602C96.38 18.5766 96.1465 18.7366 95.9555 18.9366C95.7609 19.1367 95.6052 19.373 95.492 19.6422C95.3788 19.9113 95.3222 20.2022 95.3222 20.5077V23.7334C95.3222 24.0098 95.2266 24.2462 95.0356 24.4389C94.8481 24.6317 94.6181 24.7299 94.3492 24.7299Z"
          fill="#E7251E"
        />
        <path
          d="M102.906 24.7299H101.789C101.508 24.7299 101.26 24.6335 101.062 24.4409C100.863 24.2518 100.766 24.0199 100.766 23.7487V14.0974C100.766 13.8262 100.863 13.5907 101.058 13.4016C101.452 13.0163 102.142 13.0163 102.535 13.4016C102.73 13.5943 102.828 13.8298 102.828 14.1009V22.7675H102.906C103.191 22.7675 103.435 22.8638 103.634 23.0565C103.833 23.2456 103.934 23.4847 103.934 23.7558C103.934 24.0234 103.833 24.2589 103.634 24.448C103.435 24.6335 103.188 24.7299 102.906 24.7299Z"
          fill="#E7251E"
        />
        <path
          d="M108.949 24.7299C107.858 24.7299 106.916 24.3195 106.149 23.5167C105.379 22.7103 104.99 21.7254 104.99 20.5872C104.99 19.4489 105.379 18.4605 106.149 17.6541C106.92 16.8477 107.861 16.4409 108.949 16.4409C109.712 16.4409 110.411 16.6443 111.029 17.0511V14.0894C111.029 13.8111 111.117 13.5756 111.298 13.3901C111.646 13.0261 112.263 13.0154 112.631 13.3865C112.815 13.572 112.911 13.8111 112.911 14.0894V20.5872C112.911 21.7361 112.519 22.721 111.751 23.5203C110.974 24.3231 110.03 24.7299 108.949 24.7299ZM108.949 18.407C108.655 18.407 108.383 18.4641 108.134 18.5783C107.881 18.696 107.66 18.853 107.476 19.0457C107.292 19.242 107.141 19.4775 107.032 19.7415C106.923 20.0056 106.869 20.2874 106.869 20.5836C106.869 20.8798 106.923 21.1617 107.032 21.4257C107.141 21.6898 107.288 21.9217 107.476 22.1179C107.66 22.3142 107.881 22.4712 108.134 22.5854C108.632 22.8137 109.256 22.8173 109.76 22.5854C110.012 22.4712 110.234 22.3106 110.425 22.1144C110.613 21.9146 110.763 21.6826 110.872 21.4221C110.981 21.1617 111.035 20.8798 111.035 20.5836C111.035 20.2839 110.981 20.0091 110.872 19.7451C110.763 19.481 110.613 19.2491 110.425 19.0493C110.238 18.8494 110.013 18.6924 109.764 18.5747C109.508 18.4641 109.235 18.407 108.949 18.407Z"
          fill="#E7251E"
        />
        <path
          d="M65.5101 38.4594C63.8774 38.4594 62.4633 37.8886 61.3117 36.7611C60.1601 35.6336 59.577 34.2492 59.577 32.6508C59.577 31.0559 60.1601 29.6751 61.3117 28.5476C62.467 27.4166 63.881 26.8422 65.5101 26.8422C66.9095 26.8422 68.1887 27.2989 69.3149 28.198C69.5153 28.38 69.6101 28.6154 69.6101 28.9044C69.6101 29.0436 69.5846 29.1756 69.5335 29.2969C69.4825 29.4182 69.406 29.5253 69.3149 29.6145C69.2274 29.7037 69.1144 29.775 68.9905 29.825C68.6188 29.9713 68.2033 29.9035 67.8972 29.6216C67.5801 29.379 67.2084 29.1756 66.8039 29.0365C66.403 28.9009 65.9656 28.8295 65.5137 28.8295C64.424 28.8295 63.5166 29.197 62.744 29.9534C61.9896 30.692 61.6106 31.5983 61.6106 32.6508C61.6106 33.7212 61.9823 34.606 62.744 35.3553C63.5129 36.1081 64.4168 36.4721 65.5137 36.4721C66.2645 36.4721 66.946 36.2901 67.5473 35.9298V34.1815H65.8563C65.572 34.1815 65.3279 34.0851 65.1347 33.8924C64.9416 33.7033 64.8395 33.4643 64.8395 33.186C64.8395 33.0504 64.865 32.922 64.916 32.8007C64.9671 32.6793 65.04 32.5723 65.1347 32.4795C65.2295 32.3868 65.3388 32.3154 65.4591 32.269C65.5793 32.2191 65.7142 32.1941 65.86 32.1941H68.575C68.7135 32.1941 68.8447 32.2226 68.965 32.2762C69.0853 32.3297 69.191 32.401 69.2821 32.4902C69.3732 32.5759 69.4461 32.6829 69.5007 32.8042C69.5554 32.9291 69.5846 33.0575 69.5846 33.1896V36.4435C69.5846 36.7076 69.4789 36.9466 69.2748 37.15C68.145 38.0241 66.8877 38.4594 65.5101 38.4594Z"
          fill="#E7251E"
        />
        <path
          d="M74.8907 38.4594C73.7263 38.4594 72.722 38.0411 71.9033 37.2228C71.081 36.4008 70.6662 35.3969 70.6662 34.2367C70.6662 33.0765 71.0846 32.069 71.9033 31.247C72.7257 30.4251 73.7299 30.0104 74.8907 30.0104C76.0405 30.0104 77.0484 30.4251 77.878 31.247C78.2891 31.658 78.6021 32.1199 78.8022 32.6146C79.006 33.1092 79.1115 33.6548 79.1151 34.2331C79.1151 35.4079 78.7003 36.4117 77.878 37.2228C77.0484 38.0447 76.0405 38.4594 74.8907 38.4594Z"
          fill="#E7251E"
        />
        <path
          d="M74.8872 32.1227C74.5928 32.1227 74.3123 32.1781 74.0596 32.2888C73.8033 32.3996 73.5783 32.5519 73.3913 32.7423C73.2043 32.9327 73.0519 33.1577 72.9411 33.4173C72.8338 33.67 72.7784 33.9435 72.7784 34.2343C72.7784 34.5216 72.8338 34.7985 72.9411 35.0512C73.0519 35.3108 73.2043 35.5358 73.3913 35.7228C73.5817 35.9166 73.7999 36.0655 74.0596 36.1797C74.5651 36.4012 75.2023 36.4047 75.7078 36.1797C75.964 36.0689 76.1926 35.9131 76.383 35.7228C76.5735 35.5324 76.7258 35.3039 76.8366 35.0477C76.9474 34.795 77.0028 34.5216 77.0028 34.2343C77.0028 33.9435 76.9474 33.6769 76.8366 33.4208C76.7258 33.1646 76.5735 32.9362 76.383 32.7458C76.1926 32.5519 75.964 32.3996 75.7078 32.2854C75.455 32.1781 75.178 32.1227 74.8872 32.1227Z"
          fill="#1F0F08"
        />
        <path
          d="M87.0806 38.4566C86.7968 38.4566 86.5567 38.3584 86.3675 38.1656C86.291 38.0855 86.2292 37.9982 86.1819 37.9036C85.9272 38.0637 85.6652 38.1874 85.3924 38.2747C84.5373 38.5476 83.4749 38.5294 82.5944 38.1437C82.1178 37.9364 81.6957 37.6489 81.3391 37.2924C80.9826 36.9358 80.6951 36.5138 80.4877 36.0372C80.2767 35.557 80.1712 35.0403 80.1712 34.5019V31.0055C80.1712 30.7326 80.2694 30.4925 80.4659 30.2997C80.848 29.9177 81.4956 29.9104 81.8849 30.2997C82.0814 30.4961 82.1796 30.7326 82.1796 31.0055V34.5019C82.1796 34.7711 82.2306 35.0258 82.3324 35.2623C82.4343 35.5024 82.5726 35.7061 82.7509 35.8844C82.9255 36.059 83.1365 36.2009 83.373 36.3028C83.8351 36.5029 84.41 36.5065 84.883 36.3028C85.1231 36.2009 85.3269 36.0627 85.5088 35.8808C85.6871 35.7025 85.829 35.4951 85.9308 35.2586C86.0327 35.0258 86.0837 34.7711 86.0837 34.5055V31.0091C86.0837 30.7326 86.1819 30.4962 86.3784 30.3033C86.7604 29.9213 87.408 29.914 87.7974 30.3033C87.9938 30.4998 88.0921 30.7363 88.0921 31.0091V37.4634C88.0921 37.7399 87.9938 37.9764 87.7974 38.1692C87.6009 38.3584 87.3607 38.4566 87.0806 38.4566Z"
          fill="#E7251E"
        />
        <path
          d="M90.1278 38.4594C89.8555 38.4594 89.6221 38.3611 89.4347 38.1647C89.2437 37.9719 89.1483 37.7354 89.1483 37.459V31.0054C89.1483 30.7326 89.2437 30.4925 89.4347 30.2997C89.806 29.9177 90.4354 29.9104 90.8138 30.2997C90.9198 30.4088 90.9976 30.5361 91.0471 30.6744C91.3088 30.4997 91.5846 30.3579 91.8781 30.2524C92.4899 30.0232 93.1264 29.9577 93.9397 30.0523C94.1907 30.085 94.4241 30.1396 94.6363 30.2196C94.8661 30.3069 95.0571 30.427 95.2127 30.5761C95.393 30.7544 95.4849 30.9872 95.4849 31.2637C95.4849 31.4019 95.4567 31.5365 95.4036 31.6602C95.3506 31.7803 95.2834 31.8858 95.1985 31.9767C95.1101 32.0713 95.004 32.144 94.8873 32.195C94.6681 32.2896 94.417 32.315 94.1023 32.1731C93.6108 31.9694 92.9496 31.9585 92.4227 32.1913C92.161 32.3077 91.9276 32.4678 91.7367 32.6679C91.5422 32.868 91.3866 33.1044 91.2735 33.3736C91.1638 33.6392 91.1073 33.9302 91.1073 34.2394V37.4662C91.1073 37.7427 91.0118 37.9792 90.8208 38.172C90.6263 38.3611 90.3965 38.4594 90.1278 38.4594Z"
          fill="#E7251E"
        />
        <path
          d="M107.166 38.4584C106.89 38.4584 106.644 38.3567 106.456 38.1641C106.267 37.9716 106.17 37.7317 106.17 37.452V33.4332C106.17 33.2407 106.133 33.0663 106.061 32.8955C105.988 32.7247 105.887 32.5721 105.76 32.4413C105.633 32.3105 105.485 32.2124 105.311 32.1361C104.974 31.9871 104.554 31.9835 104.217 32.1361C104.043 32.2124 103.891 32.3177 103.764 32.4413C103.634 32.5685 103.532 32.7174 103.46 32.8882C103.387 33.059 103.351 33.237 103.351 33.4332V37.4556C103.351 37.7354 103.253 37.9752 103.058 38.1678C102.67 38.5602 102.025 38.5529 101.645 38.1678C101.453 37.9752 101.352 37.7354 101.352 37.4556V33.4332C101.352 33.237 101.315 33.059 101.239 32.8882C101.163 32.7174 101.062 32.5648 100.935 32.4377C100.808 32.3105 100.66 32.2087 100.486 32.1324C100.149 31.9835 99.7397 31.9835 99.3991 32.1288C99.2289 32.2015 99.0767 32.3032 98.95 32.434C98.8195 32.5648 98.7181 32.7138 98.6457 32.8846C98.5732 33.0554 98.537 33.2334 98.537 33.4296V37.452C98.537 37.7317 98.4392 37.9716 98.2436 38.1641C97.856 38.5566 97.2112 38.5493 96.8308 38.1641C96.6388 37.9716 96.541 37.7317 96.541 37.452V31.0133C96.541 30.7371 96.6388 30.501 96.8308 30.3084C97.1786 29.9596 97.7364 29.9232 98.124 30.203C98.2074 30.2612 98.2762 30.3338 98.3341 30.4101C99.2325 29.9232 100.417 29.8869 101.373 30.3157C101.736 30.4792 102.065 30.7117 102.355 31.0097C103.022 30.3484 103.826 30.0104 104.753 30.0104C105.217 30.0104 105.659 30.1013 106.075 30.283C106.485 30.461 106.847 30.7081 107.155 31.0169C107.463 31.3258 107.709 31.6891 107.887 32.1034C108.068 32.5212 108.158 32.9681 108.158 33.4296V37.452C108.158 37.7317 108.06 37.9716 107.865 38.1641C107.676 38.3603 107.437 38.4584 107.166 38.4584Z"
          fill="#E7251E"
        />
        <path
          d="M113.439 38.4594C112.278 38.4594 111.27 38.0411 110.451 37.2228C109.629 36.4008 109.214 35.3969 109.214 34.2367C109.214 33.0765 109.629 32.069 110.451 31.247C111.274 30.4251 112.278 30.0104 113.439 30.0104C114.592 30.0104 115.597 30.4251 116.426 31.247C116.834 31.6508 117.147 32.1127 117.354 32.6109C117.561 33.1092 117.663 33.6548 117.663 34.2367C117.663 34.524 117.565 34.7641 117.365 34.9532C117.168 35.1423 116.932 35.2369 116.655 35.2369H111.445C111.496 35.3388 111.554 35.437 111.623 35.5279C111.765 35.7206 111.929 35.888 112.114 36.0225C112.3 36.1607 112.511 36.2662 112.737 36.3426C113.224 36.5063 113.781 36.4881 114.207 36.3317C114.436 36.248 114.65 36.1207 114.847 35.9534C115.036 35.797 115.255 35.7134 115.498 35.7061C115.789 35.7061 116.03 35.8007 116.219 35.9934C116.408 36.1862 116.506 36.4262 116.506 36.7063C116.506 36.9827 116.412 37.2228 116.219 37.4192C115.429 38.1138 114.498 38.4594 113.439 38.4594ZM111.441 33.2329H115.436C115.385 33.131 115.324 33.0328 115.258 32.9419C115.113 32.7455 114.952 32.5855 114.763 32.4473C114.578 32.3127 114.367 32.2036 114.141 32.1272C113.694 31.9781 113.191 31.9745 112.74 32.1272C112.511 32.2036 112.3 32.3091 112.111 32.4473C111.922 32.5855 111.754 32.7492 111.619 32.9419C111.55 33.0365 111.492 33.131 111.441 33.2329Z"
          fill="#E7251E"
        />
        <path
          d="M122.99 38.4594C120.897 38.4594 119.792 37.3895 119.792 35.3708V32.1394H119.722C119.449 32.1394 119.21 32.0431 119.014 31.8505C118.819 31.6615 118.719 31.4261 118.719 31.1515C118.719 30.8804 118.819 30.6486 119.014 30.4596C119.213 30.2706 119.453 30.1707 119.722 30.1707H119.792V27.8203C119.792 27.5528 119.891 27.3174 120.087 27.1284C120.481 26.7503 121.126 26.7432 121.527 27.1284C121.726 27.321 121.826 27.5528 121.826 27.8239V30.1743H122.456C122.736 30.1743 122.976 30.2706 123.171 30.4631C123.366 30.6557 123.462 30.8876 123.462 31.1551C123.462 31.4261 123.362 31.6615 123.171 31.8541C122.976 32.0467 122.736 32.143 122.456 32.143H121.826V35.3743C121.826 36.1519 122.187 36.4978 122.994 36.4978C123.27 36.4978 123.517 36.5977 123.709 36.7867C123.901 36.9722 124 37.2111 124 37.4786C124 37.7533 123.901 37.9851 123.709 38.1777C123.514 38.3596 123.267 38.4594 122.99 38.4594Z"
          fill="#E7251E"
        />
      </svg>
    );
  }
}

export default Logo;
