import React from 'react';
import { MultiSelect as PrimeReactMultiSelect } from 'primereact/multiselect';
import PropTypes from 'prop-types';

const MultiSelect = (props: Object) => {
  const {
    id,
    name,
    className,
    placeholder,
    options,
    value,
    onChange,
    disabled,
    filter,
  } = props;
  return (
    <PrimeReactMultiSelect
      id={id}
      name={name}
      className={className}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      filter={filter}
    />
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  filter: PropTypes.bool,
};

MultiSelect.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
  filter: false,
};

export default MultiSelect;
