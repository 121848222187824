import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import firebase from 'firebase/app';
import Button from '../../../components/primereact/buttons';
import ProgressSpinner from '../../../components/primereact/misc';
import { fetchStoreInformationRequestAction } from '../store/restaurant.request';
import { restaurantSelector } from '../store/restaurant.selector';
import { IMAGE_URL } from '../../../config/config';
import noImage from '../../../assets/images/noimage.png';
import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
};

class RestaurantPrintQRCodePage extends PureComponent<*, State> {
  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchRestaurant(uid);
    }
  }

  handleDownloadQRCodeApp = () => {
    domtoimage
      .toBlob(document.getElementById('qr-code-download-app'))
      .then(blob => {
        saveAs(blob, 'download-wg-app.png');
      });
  };

  handleDownloadQRCodeStoreDiscount = () => {
    domtoimage
      .toBlob(document.getElementById('qr-code-store-discount'))
      .then(blob => {
        saveAs(blob, 'store-discout.png');
      });
  };

  render() {
    const { t } = this.props;

    return this.props.loading ? (
      <div className="restaurant-spinner-container">
        <ProgressSpinner
          loadingText={t('loading')}
          className="restaurant-spinner"
        />
      </div>
    ) : (
      <Fragment>
        <div className="restaurant-title">{t('printQRCode')}</div>
        <div className="restaurant-main">
          <div className="restaurant-main-inner">
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('restaurantName')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.props.data && this.props.data.name
                    ? this.props.data.name
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label restaurant-form-group__label--download">
                  {t('downloadApp')}
                  <Button
                    label={t('downloadImage')}
                    type="button"
                    className="restaurant-button restaurant-button--link"
                    onClick={this.handleDownloadQRCodeApp}
                  />
                </div>
                <div className="restaurant-form-group__data">
                  <div
                    id="qr-code-download-app"
                    className={`qr-code-wrapper ${t('downloadAppImage')}`}
                  >
                    <QRCode
                      x="118"
                      y="345"
                      renderAs="svg"
                      size={200}
                      value="http://onelink.to/kp22x6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('walletAddress')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.props.data && this.props.data.walletId
                    ? this.props.data.walletId
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label restaurant-form-group__label--download">
                  {t('QRCode')}
                  <Button
                    label={t('downloadImage')}
                    type="button"
                    className="restaurant-button restaurant-button--link"
                    onClick={this.handleDownloadQRCodeStoreDiscount}
                  />
                </div>
                <div className="restaurant-form-group__data">
                  <div
                    id="qr-code-store-discount"
                    className={`qr-code-wrapper ${t('storeDiscountImage')}`}
                  >
                    {this.props.data && this.props.data.image ? (
                      <img
                        src={
                          this.props.data
                            ? `${IMAGE_URL}${this.props.data.image}`
                            : { noImage }
                        }
                        alt={this.props.data.image}
                      />
                    ) : (
                      <img src={noImage} alt="..." />
                    )}
                    <QRCode
                      x="118"
                      y="345"
                      renderAs="svg"
                      size={200}
                      value={this.props.data ? this.props.data.id : ''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => restaurantSelector(state);

const mapDispatchToProps = (dispatch: Function) => ({
  fetchRestaurant: (restaurantId: string) =>
    dispatch(fetchStoreInformationRequestAction(restaurantId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantPrintQRCodePage),
);
