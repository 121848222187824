// TODO: remove console.log(error, response)
import {
  NETWORK_ERROR_MSG,
  REQUEST_TIMEOUT_MSG,
  ERROR_WITH_STATUS,
  SOMETHING_WENT_WRONG_MSG,
} from './api.constants';

/* eslint-disable no-underscore-dangle */
type Response = {
  message: string,
  status: number | null,
  data: Object,
};

const transformResponse: Object = (response: Response) => {
  const { status, message, data } = response;
  return {
    httpStatusCode: status,
    message,
    data,
  };
};
class ResponseError extends Error {
  response: Response;

  name: string;

  fullErrorStack: Object;

  constructor(result: Response, error: Object) {
    super(result.message);
    this.fullErrorStack = error;
    this.name = 'ResponseError';
    const { data, message, status } = result;
    const response = {
      data,
      message: message || SOMETHING_WENT_WRONG_MSG,
      status,
    };
    this.response = transformResponse(response);
  }
}

class ResponseSuccess {
  response: Response;

  constructor(response: Response) {
    this.response = transformResponse(response);
  }
}

export const successResponseInterceptor = (response: Object) => {
  console.log({ response });
  const result: Response = response.data || response;
  return new ResponseSuccess(result);
};

export const errorResponseInterceptor = (error: Object) => {
  console.log({ error });
  let response;
  if (error.response) {
    // transform all non 2xx errors
    const responseObj = error.response.data;
    if (responseObj && responseObj instanceof Object)
      response = { ...responseObj };
    else
      response = {
        data: null,
        message: `${ERROR_WITH_STATUS}: ${error.response.status}`,
        status: null,
      };
  } else if (error.request) {
    // The request was made but no response was received
    if (error.request._timedOut)
      response = { data: null, message: REQUEST_TIMEOUT_MSG, status: null };
    else
      response = { data: null, message: error.request._response, status: null };
  } else {
    // catch errors before a response
    response = {
      data: null,
      message: error.message || NETWORK_ERROR_MSG,
      status: null,
    };
  }
  return Promise.reject(new ResponseError(response, error));
};
