import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class AddRestaurantIcon extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3751 9.32317V8.31559C17.3751 7.76645 16.9332 7.32123 16.3882 7.32123H2.08117C1.53628 7.32123 1.09442 7.76645 1.09442 8.31559V9.32342C1.09742 9.32342 1.10041 9.32317 1.1034 9.32317H17.3751Z"
          fill="#1F0F08"
        />
        <path
          d="M1.70551 14.7106C2.51512 14.7106 3.24987 14.2698 3.6558 13.6266C3.97964 14.2698 4.65802 14.7106 5.46762 14.7106C6.27735 14.7106 6.9939 14.2698 7.37375 13.6266C7.72367 14.2698 8.42001 14.7106 9.22961 14.7106C10.0392 14.7106 10.7379 14.2698 11.0915 13.6266C11.4677 14.2698 12.1821 14.7106 12.9917 14.7106C13.8013 14.7106 14.482 14.2698 14.8093 13.6266C15.2117 14.2698 15.9441 14.7106 16.7537 14.7106C18.0641 14.7106 18.4704 13.7713 18.4704 13.5245C18.4695 13.4507 18.4573 13.3765 18.4338 13.3047L17.3837 10.524H1.10336L0.0375192 13.3117C0.00770473 13.4012 -0.00427095 13.4946 0.00134268 13.5862C0.00134268 13.9565 0.613725 14.7106 1.70551 14.7106Z"
          fill="#1F0F08"
        />
        <path
          d="M17.375 23.9999V15.854C17.1734 15.8919 16.9657 15.9118 16.7538 15.9118C16.0747 15.9118 15.4137 15.7066 14.8531 15.3417C14.3212 15.7068 13.6763 15.9118 12.9918 15.9118C12.3108 15.9118 11.6554 15.7066 11.1047 15.3413C10.563 15.7067 9.91241 15.9118 9.22979 15.9118C8.5468 15.9118 7.89712 15.7067 7.35646 15.3416C6.80458 15.7066 6.14854 15.9118 5.4678 15.9118C4.78306 15.9118 4.13874 15.7071 3.60807 15.3418C3.04633 15.7066 2.38467 15.9118 1.70568 15.9118C1.49722 15.9118 1.29301 15.8926 1.09467 15.8558V23.9999H2.78V17.9795C2.78 17.6487 3.04795 17.3807 3.37878 17.3807H7.40374C7.73445 17.3807 8.00253 17.6487 8.00253 17.9795V23.9999H17.375ZM9.81847 17.9795C9.81847 17.6487 10.0867 17.3807 10.4173 17.3807H15.0908C15.4215 17.3807 15.6896 17.6487 15.6896 17.9795V21.8542C15.6896 22.1848 15.4215 22.453 15.0908 22.453H10.4173C10.0867 22.453 9.81847 22.1848 9.81847 21.8542V17.9795Z"
          fill="#1F0F08"
        />
        <path d="M3.97748 18.5784H6.80461V24H3.97748V18.5784Z" fill="#1F0F08" />
        <path
          d="M11.0161 18.5784H14.492V21.2556H11.0161V18.5784Z"
          fill="#1F0F08"
        />
        <rect
          x="1.08142"
          y="8.98627"
          width="16.3509"
          height="2.33584"
          fill="#1F0F08"
        />
        <circle
          className={this.props.className}
          cx="15.2028"
          cy="8.91754"
          r="7.51754"
          fill="#1F0F08"
          stroke="#FCAE17"
          strokeWidth="1.2"
        />
        <path
          className={this.props.className}
          d="M14.6028 5.19269V12.6423H15.8028V5.19269H14.6028Z"
          fill="#FCAE17"
        />
        <path
          className={this.props.className}
          d="M18.9276 8.31748L11.478 8.31748L11.478 9.51748L18.9276 9.51748L18.9276 8.31748Z"
          fill="#FCAE17"
        />
      </svg>
    );
  }
}

export default AddRestaurantIcon;
