export type ActionReducer = {
  type: string,
  payload?: Object,
  route: string,
  startReducerAction: Function,
  resultReducerAction: Function,
  method: Function,
};

// eslint-disable-next-line import/prefer-default-export
export const uploadImageRequestAction = (
  payload: Object,
  apiMethod: Function,
  apiRoute: string,
  reducer: Function,
) => {
  return {
    type: 'REQUEST',
    method: apiMethod,
    route: apiRoute,
    resultReducerAction: reducer,
    payload,
  };
};

export const searchRequestAction = (
  apiMethod: Function,
  apiRoute: string,
  reducer: Function,
) => {
  return {
    type: 'SEARCH_REQUEST',
    method: apiMethod,
    route: apiRoute,
    resultReducerAction: reducer,
  };
};
