import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class Logout extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path
          className={this.props.className ? this.props.className : ''}
          d="M10.1539 19.25H2.53845C2.07136 19.25 1.69231 18.858 1.69231 18.3751V2.62503C1.69231 2.14202 2.0714 1.75004 2.53845 1.75004H10.1539C10.6218 1.75004 11 1.35893 11 0.875063C11 0.391194 10.6218 0 10.1539 0H2.53845C1.13891 0 0 1.17778 0 2.62503V18.375C0 19.8223 1.13891 21 2.53845 21H10.1539C10.6218 21 11 20.6089 11 20.125C11 19.6411 10.6218 19.25 10.1539 19.25Z"
          fill="#272C32"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          d="M20.7406 10.3897L15.447 5.24677C15.1057 4.91419 14.5537 4.9185 14.2159 5.25536C13.8781 5.59221 13.8816 6.13478 14.2246 6.46736L18.0076 10.1428H7.87063C7.38917 10.1428 7 10.5259 7 10.9999C7 11.4739 7.38917 11.8571 7.87063 11.8571H18.0076L14.2246 15.5326C13.8816 15.8652 13.879 16.4077 14.2159 16.7446C14.3865 16.9143 14.6112 17 14.8358 17C15.057 17 15.2781 16.9177 15.447 16.7531L20.7406 11.6102C20.906 11.4491 21 11.2296 21 10.9999C21 10.7703 20.9068 10.5517 20.7406 10.3897Z"
          fill="#272C32"
        />
      </svg>
    );
  }
}

export default Logout;
