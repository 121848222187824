import React, { PureComponent } from 'react';

type Props = {};

class BackArrow extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 7.875H4.3089L10.5978 1.5861L9 0L0 9L9 18L10.5861 16.4139L4.3089 10.125H18V7.875Z"
          fill="#E7251E"
        />
      </svg>
    );
  }
}

export default BackArrow;
