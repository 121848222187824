/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputText, Checkbox } from '../../../components/primereact/inputs';
import './styles.scss';

const RestaurantInputSchedule = (props: Object) => {
  const { day, onChange, onBlur, value, loading } = props;

  const { t } = useTranslation();

  const onCloseChange = e => {
    onChange(e);
  };

  return (
    <Fragment>
      <div className="restaurant-operation-hours__day">{t(`${day}`)}</div>
      <div className="restaurant-operation-hours__time restaurant-operation-hours__time--edit">
        <InputText
          id={`operationHours[${day}].from`}
          name={`operationHours[${day}].from`}
          type="time"
          className="restaurant-form-input"
          value={value[day].from}
          onChange={onChange}
          onBlur={onBlur}
          disabled={value[day].closed}
        />
        <InputText
          id={`operationHours[${day}].to`}
          name={`operationHours[${day}].to`}
          type="time"
          className="restaurant-form-input"
          value={value[day].to}
          onChange={onChange}
          onBlur={onBlur}
          disabled={value[day].closed}
        />
        <label className="restaurant-checkbox-wrapper">
          <Checkbox
            id={`close-${day}`}
            name={`operationHours[${day}].closed`}
            className="restaurant-checkbox"
            onChange={onCloseChange}
            checked={value[day].closed || false}
            disabled={loading}
          />
          {t('closed')}
        </label>
      </div>
    </Fragment>
  );
};

RestaurantInputSchedule.propTypes = {
  day: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object.isRequired,
};

export default RestaurantInputSchedule;
