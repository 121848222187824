import React, { PureComponent } from 'react';

type Props = {};

class DeleteIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.3 14.2262C1.3 15.2019 2.07992 16 3.03335 16H9.96669C10.9201 16 11.7 15.2019 11.7 14.2262V4H1.3V14.2262ZM13 1.33333H9.75L8.66186 0H4.33818L3.25 1.33333H0V2.66667H13V1.33333Z"
          fill="#DE0A0A"
        />
      </svg>
    );
  }
}

export default DeleteIcon;
