import React, { PureComponent } from 'react';

type Props = {};

class Information extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.58432 21.67H15.8213C16.5664 21.67 17.1712 21.0652 17.1712 20.3201V2.76617V1.34991C17.1712 0.604781 16.5664 0 15.8213 0H8.58432H6.17795V0.446568C6.18303 0.492501 6.18561 0.540985 6.18561 0.58947V3.53171V4.28705C6.18561 5.33585 5.3333 6.18816 4.2845 6.18816H3.52917H0.586918C0.548641 6.18816 0.507812 6.1856 0.469535 6.18305H0V20.3201C0 21.0652 0.604781 21.67 1.34991 21.67H2.82997H8.58432ZM8.85736 17.0819H4.13905C3.7869 17.0819 3.5011 16.796 3.5011 16.4439C3.5011 16.0917 3.7869 15.806 4.13905 15.806H8.85992C9.21207 15.806 9.49787 16.0917 9.49787 16.4439C9.49787 16.796 9.20952 17.0819 8.85736 17.0819ZM13.5782 13.8258H4.13905C3.7869 13.8258 3.5011 13.5399 3.5011 13.1878C3.5011 12.8356 3.7869 12.5499 4.13905 12.5499H13.5782C13.9304 12.5499 14.2162 12.8356 14.2162 13.1878C14.2162 13.5399 13.9304 13.8258 13.5782 13.8258ZM4.13905 9.09212H13.5782C13.9304 9.09212 14.2162 9.37793 14.2162 9.73008C14.2162 10.0822 13.9304 10.368 13.5782 10.368H4.13905C3.7869 10.368 3.5011 10.0822 3.5011 9.73008C3.5011 9.37793 3.7869 9.09212 4.13905 9.09212Z"
          fill="white"
        />
        <path
          d="M0.586142 5.42203H4.28372C4.28627 5.42203 4.29137 5.42203 4.29392 5.42203C4.91146 5.41693 5.41162 4.91677 5.41672 4.29923C5.41672 4.29668 5.41672 4.29158 5.41672 4.28903V0.588892C5.41672 0.246948 5.13602 0.0198364 4.84514 0.0198364C4.70479 0.0198364 4.56444 0.0708728 4.44706 0.188256L0.182955 4.45234C-0.174298 4.8096 0.078331 5.42203 0.586142 5.42203Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default Information;
