import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import i18n from '../../../utils/i18n';

const testTelephoneNumber = value => {
  try {
    if (value !== undefined) {
      const phoneNumber = parsePhoneNumberFromString(value);
      if (phoneNumber) {
        return phoneNumber.isValid() === true;
      }
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export default yup.object().shape({
  nickname: yup.string().required(i18n.t('required')),
  sex: yup.string(),
  mobileNumber: yup
    .string()
    .required(i18n.t('required'))
    .matches(/^[^A-Za-z]+$/, i18n.t('invalidPhoneNumber'))
    .test('is-phoneNumber', i18n.t('invalidPhoneNumber'), testTelephoneNumber),
  birthday: yup.string(),
  prefecture: yup.string(),
});
