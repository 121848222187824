/* eslint-disable no-underscore-dangle */
import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'qs';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import 'react-table/react-table.css';
import './styles.scss';
import Button from '../../../components/primereact/buttons';
import { Table } from '../../../components/common/table';
import { Paginator } from '../../../components/common/paginator';
import {
  fetchMenusRequestAction,
  tableRequestAction,
} from '../store/restaurant.request';
import { tableQuerySelector } from '../store/restaurant.selector';
import { currencyFormatter } from '../../../utils/data.util';

type State = {
  query: Object,
  currFilter: string,
  currFilterValue: string,
  isSortAsc: boolean,
  sortField: string | boolean,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class RestaurantMenusPage extends PureComponent<*, State> {
  state = {
    query: {},
    currFilter: '',
    currFilterValue: '',
    sortField: '',
    isSortAsc: false,
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchQueriedMenus(
        `/restaurants/${uid}/menu?_limit=10&_page=1`,
      );
    }
  }

  componentDidUpdate(props: Object, nextState: Object) {
    /* eslint-disable-next-line react/no-did-update-set-state */
    if (nextState.isErrorModalOpen) this.setState({ isErrorModalOpen: false });
  }

  onClickToRedirect(destination: string, params?: Object) {
    this.props.history.push(destination, params);
  }

  getPaginationQuery = (pageNum: number) => {
    this.restaurantMenuQueryGenerator({ _page: pageNum });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  restaurantMenuQueryGenerator = (queryData?: Object, field?: string) => {
    let uid = '';
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (
        !key.includes('_') &&
        key !== field &&
        field !== undefined &&
        !key.includes(field)
      )
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }

    const user = firebase.auth().currentUser;
    if (user) {
      /* eslint-disable-next-line prefer-destructuring */
      uid = user.uid;
      this.props.fetchMenus(uid);
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchQueriedMenus(
      `/restaurants/${uid}/menu?${queryString.stringify(this.state.query)}`,
    );
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.restaurantMenuQueryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string | Object, value: string) => {
    let filterQuery;
    const { sortField } = this.state;

    if (typeof field === 'object') {
      filterQuery = {
        [`${field.target.id}FromTo`]: field.target.value
          ? `${moment(field.target.value).format('YYYY/MM/DD')}_${moment(
              field.target.value,
            ).format('YYYY/MM/DD')}`
          : undefined,
      };

      const currSortField = sortField === field.target.id ? sortField : '';
      this.setState({
        currFilterValue: field.target.value,
        currFilter: field.target.id,
        sortField: currSortField,
      });

      this.restaurantMenuQueryGenerator(filterQuery, field.target.id);
    } else {
      filterQuery = {
        [field]: value,
      };

      const currSortField = sortField === field ? sortField : '';
      this.setState({
        currFilterValue: filterQuery[field],
        currFilter: field,
        sortField: currSortField,
      });

      this.restaurantMenuQueryGenerator(filterQuery, field);
    }
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.restaurantMenuQueryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.restaurantMenuQueryGenerator({ _page: this.state.currentPage + 1 });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.restaurantMenuQueryGenerator({ _page: this.state.currentPage - 1 });
  };

  reactTable: any;

  render() {
    const { t } = this.props;

    const menusCols = [
      {
        field: '',
        className: 'actions-width',
        header: `${t('actions')}`,
        filterElement: [''],
        body: (rowData: Object) => {
          return (
            <div className="notificationPageLabeledButtonContainer">
              <Button
                onClick={() => this.onClickToRedirect('edit', rowData)}
                label={t('edit')}
                className="restaurant-button restaurant-button--edit"
              />
              <Button
                onClick={() => this.onClickToRedirect('details', rowData)}
                label={t('details')}
                className="restaurant-button restaurant-button--details"
              />
            </div>
          );
        },
      },
      {
        field: 'name',
        header: `${t('name')}`,
        className: 'name-width',
        filterElement: ['name', ''],
        sortable: true,
        filter: true,
        body: (rowData: Object) => {
          return (
            <button
              type="button"
              onClick={() => this.onClickToRedirect('details', rowData)}
              className="notificationPageLinkCells"
            >
              {rowData.name}
            </button>
          );
        },
      },
      {
        field: 'price',
        header: `${t('price')}`,
        className: 'price-width',
        filterElement: ['price', ''],
        sortable: true,
        filter: true,
        body: (rowData: Object) => {
          return (
            <div className="menu-price">
              {currencyFormatter(
                `${t('locale')}`,
                'currency',
                `${t('currency')}`,
              ).format(rowData.price)}
            </div>
          );
        },
      },
      {
        field: 'createdAt',
        header: `${t('dateCreated')}`,
        className: 'date-created-width',
        filterElement: ['createdAt', ''],
        type: 'calendarTime',
        sortable: true,
        filter: true,
        body: (rowData: Object) => {
          return rowData.createdAt
            ? `${moment(rowData.createdAt).format('YYYY/MM/DD HH:mm')}`
            : '';
        },
      },
    ];

    return (
      <Fragment>
        <div className="restaurant-title">
          {t('menus')}
          <div className="pull-right">
            <Button
              label={t('addMenu')}
              type="button"
              className="restaurant-button restaurant-button--save"
              onClick={() => this.onClickToRedirect('add')}
            />
          </div>
        </div>
        <div className="restaurant-main">
          <div className="table-container menus-page">
            <Table
              data={this.props.data}
              columns={menusCols}
              sortOrder={this.state.isSortAsc}
              currFilterValue={this.state.currFilterValue}
              currFilter={this.state.currFilter}
              sortField={this.state.sortField}
              onFilter={this.getFilterQuery}
              onSort={this.getSortQuery}
              loading={this.props.loading}
              emptyMessage={t('noMenuRecords')}
            />
            <Paginator
              currentPage={this.state.currentPage}
              goToFirstPage={this.goToFirstPage}
              goToNextPage={this.goToNextPage}
              goToPreviousPage={this.goToPreviousPage}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => ({ ...tableQuerySelector(state) });
const mapDispatchToProps = (dispatch: Function) => ({
  fetchMenus: (restaurantId: string) =>
    dispatch(fetchMenusRequestAction(restaurantId)),
  fetchQueriedMenus: (route: string) => dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantMenusPage),
);
