import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import moment from 'moment';
import {
  hiringDeleteSelector,
  hiringInfoSelector,
} from '../store/restaurant.selector';
import {
  deleteHiringRequestAction,
  fetchHiringInfo,
} from '../store/restaurant.request';
import ProgressSpinner from '../../../components/primereact/misc';
import { IMAGE_URL } from '../../../config/config';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';

type State = {
  isSidebarOpen: boolean,
  isModalOpen: boolean,
  data: Object,
};

class AdminHiringDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state } };
    return { data: { ...state } };
  };

  state = {
    isSidebarOpen: false,
    isModalOpen: false,
    data: {},
  };

  componentDidMount() {
    const { id } = this.props.location.state;
    const user = firebase.auth().currentUser;
    if (user) {
      this.props.fetchHiringInfo(user.uid, id);
    }
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onClickToRedirect = (destination: string) => {
    this.props.history.push(destination, this.state.data);
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    const user = firebase.auth().currentUser;
    const restaurantId = user ? user.uid : '';
    const hiringId =
      this.props.hiringInfo &&
      this.props.hiringInfo.data &&
      this.props.hiringInfo.data.id;

    if (isDeleting) this.props.deleteHiring(restaurantId, hiringId);
  };

  render() {
    const { t } = this.props;
    return this.props.hiringInfo.loading ? (
      <div className="restaurant-spinner-container">
        <ProgressSpinner
          loadingText={t('loading')}
          className="restaurant-spinner"
        />
      </div>
    ) : (
      <Fragment>
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <div className="restaurant-title">
          <Button
            icon="pi pi-arrow-left"
            type="button"
            className="restaurant-button restaurant-button--icon"
            onClick={() => this.props.history.goBack()}
          />
          {t('viewHiring')}
          <div className="pull-right">
            <Button
              label={t('edit')}
              type="button"
              className="restaurant-button restaurant-button--edit"
              onClick={() => this.onClickToRedirect('edit')}
            />
            <Button
              label={t('delete')}
              type="button"
              className="restaurant-button restaurant-button--delete"
              onClick={this.onDeleteClick}
            />
          </div>
        </div>
        <div className="hiringDetailsFormContainer">
          <div className="hiringDetails">
            <div className="hiringDetailsChildrenContainer">
              <div className="hiringDetailsLeft">
                <div className="hiringDetailsLabel">{t('title')}</div>
                <div className="hiringDetailsInput">
                  {this.props.hiringInfo &&
                    this.props.hiringInfo.data &&
                    this.props.hiringInfo.data.title}
                </div>
                <div className="hiringDetailsLabel">{t('description')}</div>
                <div className="hiringDetailsInput">
                  {this.props.hiringInfo &&
                    this.props.hiringInfo.data &&
                    this.props.hiringInfo.data.description}
                </div>
              </div>
            </div>
            <div className="hiringDetailsChildrenContainer">
              <div className="hiringDetailsRight">
                <div className="hiringDetailsLabel">{t('dateCreated')}</div>
                <div className="hiringDetailsInput">
                  {this.props.hiringInfo && this.props.hiringInfo.data
                    ? moment(this.props.hiringInfo.data.deliveryDate).format(
                        'YYYY/MM/DD HH:MM',
                      )
                    : ''}
                </div>
                {this.props.hiringInfo &&
                  this.props.hiringInfo.data &&
                  this.props.hiringInfo.data.image && (
                    <Fragment>
                      <div className="hiringDetailsLabel">
                        {t('photoOptional')}
                      </div>
                      <div className="hiringDetailsLogoContainer">
                        <div className="hiringDetailsImageBtn">
                          <img
                            className="hiringDetailsLogo"
                            src={`${IMAGE_URL}${this.props.hiringInfo.data.image}`}
                            alt="..."
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  hiringInfo: hiringInfoSelector(state),
  hiringDeleteInfo: hiringDeleteSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  deleteHiring: (restaurantId: string, hiringId: string) =>
    dispatch(deleteHiringRequestAction(restaurantId, hiringId)),
  fetchHiringInfo: (restaurantId: string, hiringId: string) =>
    dispatch(fetchHiringInfo(restaurantId, hiringId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminHiringDetails),
);
