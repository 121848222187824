import React, { PureComponent } from 'react';

type Props = {
  ascendingClassName?: string,
  descendingClassName?: string,
};

class Restaurant extends PureComponent<Props> {
  static defaultProps = {
    descendingClassName: '',
    ascendingClassName: '',
  };

  render() {
    const { ascendingClassName, descendingClassName } = this.props;

    return (
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={ascendingClassName ? `${ascendingClassName}` : ''}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.61538 0L0 5H9.23077L4.61538 0Z"
          fill="#777777"
        />
        <path
          className={descendingClassName ? `${descendingClassName}` : ''}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.61538 12L9.23077 7H0L4.61538 12Z"
          fill="#777777"
        />
      </svg>
    );
  }
}

export default Restaurant;
