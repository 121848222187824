import React, { PureComponent } from 'react';

type Props = {};

class HidePass extends PureComponent<Props> {
  render() {
    return (
      <svg width="24" height="19" viewBox="0 0 24 19" fill="none">
        <path
          d="M11.6713 11.72C12.0404 11.6999 12.4009 11.6007 12.7284 11.4291C13.0558 11.2575 13.3426 11.0176 13.5692 10.7255C13.7959 10.4334 13.9571 10.0961 14.042 9.73626C14.1269 9.37644 14.1334 9.00259 14.0613 8.64001L11.4813 11.71L11.6713 11.72Z"
          fill="#1F0F08"
        />
        <path
          d="M8.33125 13.9L13.1213 8.2L13.6213 7.61L15.0713 5.87L16.9813 3.6L19.1813 0.98L18.0113 0L15.5113 2.98L13.8813 4.92L12.4013 6.68L9.26125 10.42L3.50125 17.27L4.67125 18.25L7.25125 15.18L8.33125 13.9Z"
          fill="#1F0F08"
        />
        <path
          d="M7.19125 11.32C6.75631 10.4737 6.58102 9.51784 6.68738 8.5723C6.79373 7.62676 7.177 6.73369 7.78908 6.00518C8.40115 5.27668 9.21477 4.74518 10.1278 4.4774C11.0409 4.20962 12.0127 4.21749 12.9213 4.50001L14.4713 2.65001C13.4917 2.39397 12.4837 2.26293 11.4713 2.26001C7.11125 2.27001 3.12125 4.67001 0.181251 8.58001C0.0635853 8.73735 0 8.92854 0 9.12501C0 9.32148 0.0635853 9.51267 0.181251 9.67001C1.4729 11.3968 3.07738 12.8656 4.91125 14L7.19125 11.32Z"
          fill="#1F0F08"
        />
        <path
          d="M22.8213 8.57999C21.4891 6.77105 19.8139 5.24199 17.8913 4.07999L15.6813 6.69999C16.1945 7.55944 16.4281 8.55713 16.3496 9.5551C16.2711 10.5531 15.8845 11.502 15.2432 12.2707C14.602 13.0393 13.7377 13.5898 12.7699 13.8458C11.8022 14.1019 10.7788 14.0509 9.84125 13.7L8.27125 15.53C9.32149 15.833 10.4082 15.9912 11.5013 16C15.8913 16 19.8813 13.6 22.8213 9.68999C22.9437 9.53088 23.0101 9.33575 23.0101 9.13499C23.0101 8.93422 22.9437 8.73909 22.8213 8.57999Z"
          fill="#1F0F08"
        />
        <path
          d="M8.90125 9C8.8946 9.10656 8.8946 9.21343 8.90125 9.32L11.1913 6.59C10.5889 6.66292 10.0308 6.94341 9.61291 7.38323C9.19498 7.82306 8.94334 8.39474 8.90125 9Z"
          fill="#1F0F08"
        />
      </svg>
    );
  }
}

export default HidePass;
