import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../auth/AuthContextProvider';
import './styles.scss';

const ErrorMessage: Function = React.memo(() => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const error = auth.error ? (
    <div className="errorMessageContainer">
      <h5 className="errorMessage">{t('authError')}</h5>
    </div>
  ) : (
    ''
  );
  return error;
});

export default ErrorMessage;
