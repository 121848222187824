/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import firebase from 'firebase/app';
import queryString from 'qs';
import { withTranslation } from 'react-i18next';
import Button from '../../../components/primereact/buttons';
import { Table } from '../../../components/common/table';
import { CommonModal } from '../../../components/common/modal';
import { Paginator } from '../../../components/common/paginator';
import './styles.scss';
import { tableQuerySelector } from '../../admin/store/admin.selector';
import { tableRequestAction } from '../../admin/store/admin.request';

type State = {
  isSidebarOpen: boolean,
  query: Object,
  currFilterValue: string,
  currFilter: string,
  sortField: string,
  isSortAsc: boolean,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class RestaurantWhatToEatTodayPage extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    query: {},
    currFilterValue: '',
    currFilter: '',
    sortField: '',
    isSortAsc: false,
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    const id = user ? user.uid : '';

    this.props.fetchWhatToEat(
      `/restaurants/${id}/whatToEats?_limit=10&_page=1`,
    );
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  getPaginationQuery = (pageNum: number) => {
    this.whatToEatQueryGenerator({ _page: pageNum });
  };

  whatToEatQueryGenerator = (queryData?: Object, field?: string) => {
    const user = firebase.auth().currentUser;
    const id = user ? user.uid : '';

    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
        restaurantId: id,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (
        !key.includes('_') &&
        key !== field &&
        field !== undefined &&
        !key.includes(field)
      )
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchWhatToEat(
      `/restaurants/${id}/whatToEats?${queryString.stringify(
        this.state.query,
      )}`,
    );
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.whatToEatQueryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.whatToEatQueryGenerator({ _page: this.state.currentPage + 1 });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.whatToEatQueryGenerator({ _page: this.state.currentPage - 1 });
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.whatToEatQueryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string, value: string) => {
    let filterQuery;
    const { sortField } = this.state;

    if (typeof field === 'object') {
      filterQuery = {
        [`${field.target.id}FromTo`]: field.target.value
          ? `${moment(field.target.value).format('YYYY/MM/DD')}_${moment(
              field.target.value,
            ).format('YYYY/MM/DD')}`
          : undefined,
      };

      const currSortField = sortField === field.target.id ? sortField : '';
      this.setState({
        currFilterValue: field.target.value,
        currFilter: field.target.id,
        sortField: currSortField,
      });

      this.whatToEatQueryGenerator(filterQuery, field.target.id);
    } else {
      filterQuery = {
        [field]: value,
      };

      const currSortField = sortField === field ? sortField : '';

      this.setState({
        currFilterValue: filterQuery[field],
        currFilter: field,
        sortField: currSortField,
      });

      this.whatToEatQueryGenerator(filterQuery, field);
    }
  };

  onClickToRedirect = (destination: string, params?: Object) => {
    this.props.history.push(destination, params);
  };

  reactTable: any;

  render() {
    const { t } = this.props;

    const whatToEatCols = [
      {
        field: '',
        body: (rowData: Object) => {
          return (
            <div className="hiringPageLabeledButtonContainer">
              <Button
                onClick={() => this.props.history.push('edit', rowData)}
                label={t('edit')}
                className="restaurant-button restaurant-button--edit"
              />
              <Button
                onClick={() => this.props.history.push('details', rowData)}
                label={t('details')}
                className="restaurant-button restaurant-button--details"
              />
            </div>
          );
        },
        header: `${t('actions')}`,
        className: 'actions-width',
        filterElement: [''],
      },
      {
        field: 'title',
        filter: true,
        sortable: true,
        header: `${t('title')}`,
        className: 'title-width',
        filterElement: ['title', ''],
        body: rowData => {
          return (
            <a
              href="https://world-gourmet.web.app/"
              className="notificationPageLinkCells"
            >
              {rowData.title}
            </a>
          );
        },
      },
      {
        field: 'updatedAt',
        filter: true,
        sortable: true,
        header: `${t('updateDate')}`,
        className: 'points-used-width',
        type: 'calendarTime',
        filterElement: ['updatedAt', ''],
        body: rowData => {
          return (
            <div>{moment(rowData.updatedAt).format('YYYY/MM/DD HH:MM')}</div>
          );
        },
      },
      {
        field: 'numberOfRecipients',
        filter: true,
        sortable: true,
        header: `${t('numberOfRecipients')}`,
        className: 'points-used-width',
        filterElement: ['numberOfRecipients', 'int'],
        body: () => {
          return 1000;
        },
      },
    ];

    return (
      <>
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <div className="campaignPageTitle">
          {t('whatToEatToday')}
          <div className="pull-right">
            <Button
              label={t('addUserCampaign')}
              type="button"
              className="restaurant-button restaurant-button--save"
              onClick={() => this.onClickToRedirect('add')}
            />
          </div>
        </div>
        <div className="restaurant-main">
          <div className="table-container restaurant-what-to-eat-today">
            <Table
              currFilterValue={this.state.currFilterValue}
              currFilter={this.state.currFilter}
              data={this.props.data}
              columns={whatToEatCols}
              onFilter={this.getFilterQuery}
              onSort={this.getSortQuery}
              sortOrder={this.state.isSortAsc}
              loading={this.props.loading}
              sortField={this.state.sortField}
              pageName="whatToEat"
              emptyMessage={t('noWhatToEatRecords')}
            />
            <Paginator
              currentPage={this.state.currentPage}
              goToFirstPage={this.goToFirstPage}
              goToNextPage={this.goToNextPage}
              goToPreviousPage={this.goToPreviousPage}
              isTableLoading={this.props.loading}
              isTableEmpty={this.props.data.length === 0}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => tableQuerySelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  fetchWhatToEat: (route: string) => dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantWhatToEatTodayPage),
);
