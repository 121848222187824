import * as yup from 'yup';

const FILE_SIZE = 512 * 1024;

const testFileSize = value => {
  try {
    if (value) return value.file.size <= FILE_SIZE;
    return true;
  } catch (err) {
    return false;
  }
};

export const addMenuSchema = yup.object().shape({
  name: yup.string().required('required'),
  description: yup.string().required('required'),
  price: yup
    .number()
    .required('required')
    .nullable(),
  file: yup
    .mixed()
    .required('required')
    .test('fileSize', 'imageErrorSize', testFileSize),
  photo: yup.string(),
});

export const updateMenuSchema = yup.object().shape({
  name: yup.string().required('required'),
  description: yup.string().required('required'),
  price: yup
    .number()
    .required('required')
    .nullable(),
  file: yup
    .mixed()
    .notRequired()
    .test('fileSize', 'imageErrorSize', testFileSize),
  photo: yup.string(),
});
