import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const FILE_SIZE = 512 * 1024;

const testFileSize = value => {
  try {
    if (value) return value.file.size <= FILE_SIZE;
    return true;
  } catch (err) {
    return false;
  }
};

const testTelephoneNumber = value => {
  try {
    if (value !== undefined) {
      const phoneNumber = parsePhoneNumberFromString(value);
      if (phoneNumber) {
        return phoneNumber.isValid() === true;
      }
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const dailySchema = yup.object().shape({
  from: yup.string().when('closed', {
    is: false,
    then: yup.string().required('Opening time is required'),
    otherwise: yup.string().notRequired(),
  }),
  to: yup.string().when('closed', {
    is: false,
    then: yup.string().required('Closing time is required'),
    otherwise: yup.string().notRequired(),
  }),
  closed: yup.boolean().required('required'),
});

export default yup.object().shape({
  name: yup
    .string()
    .max(25, 'restaurantNameLength')
    .required('required'),
  description: yup.string().required('required'),
  cuisine: yup
    .array()
    .min(1, 'Pick at least 1 cuisine')
    .required('required'),
  usageRate: yup
    .number()
    .max(100, 'maximumUsageRate')
    .required('required'),
  budget: yup.string().required('required'),
  location: yup.object().shape({
    _latitude: yup.number().required('required'),
    _longitude: yup.number().required('required'),
  }),
  address: yup.string().required('required'),
  telephoneNumber: yup
    .string()
    .required('required')
    .matches(/^[^A-Za-z]+$/, 'invalidPhoneNumber')
    .test('is-phoneNumber', 'invalidPhoneNumber', testTelephoneNumber),
  homepageUrl: yup.string().url('invalidURL'),
  googleMapsUrl: yup.string().url('invalidURL'),
  file: yup
    .mixed()
    .notRequired()
    .test('fileSize', 'imageErrorSize', testFileSize),
  image: yup.string(),
  operationHours: yup.object().shape({
    monday: dailySchema,
    tuesday: dailySchema,
    wednesday: dailySchema,
    thursday: dailySchema,
    friday: dailySchema,
    saturday: dailySchema,
    sunday: dailySchema,
  }),
  password: yup.string().min(8, 'passwordLength'),
});
