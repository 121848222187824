import React, { PureComponent } from 'react';

import AccordionSection from './AccordionSection';

type Props = {
  allowMultipleOpen?: boolean,
  children: any,
};

type State = {
  openSections: Object,
};

class Accordion extends PureComponent<Props, State> {
  static defaultProps = {
    allowMultipleOpen: false,
  };

  constructor(props: Object) {
    super(props);

    const openSections = {};

    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });

    this.state = { openSections };
  }

  onClick = (label: string) => {
    const { allowMultipleOpen } = this.props;
    const { openSections } = this.state;

    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen,
        },
      });
    }
  };

  render() {
    const { children } = this.props;
    const { openSections } = this.state;

    return (
      <div>
        {children.map(child => (
          <AccordionSection
            key={child.props.label}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            icon={child.props.icon}
            onClick={this.onClick}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </div>
    );
  }
}

export default Accordion;
