import React from 'react';
import { Checkbox as PrimeReactCheckbox } from 'primereact/checkbox';
import PropTypes from 'prop-types';

const Checkbox = (props: Object) => {
  const { id, name, className, onChange, disabled, checked } = props;

  return (
    <PrimeReactCheckbox
      id={id}
      name={name}
      className={className}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: '',
  disabled: false,
  checked: false,
};

export default Checkbox;
