import * as yup from 'yup';

const FILE_SIZE = 1024 * 1024 * 2;

export default yup.object().shape({
  title: yup.string().required('required'),
  description: yup.string().required('required'),
  deliveryDate: yup.date().required('required'),
  file: yup.mixed().test('fileSize', 'fileLarge', value => {
    if (value) return value.file.size <= FILE_SIZE;
    return true;
  }),
  image: yup.string(),
});
