import React, { PureComponent } from 'react';

type Props = {};

class SaveButtonIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6429 0H2.375C1.06756 0 0 1.06875 0 2.375V16.625C0 17.9312 1.06756 19 2.375 19H16.625C17.9324 19 19 17.9312 19 16.625V3.71331L15.6429 0ZM14.25 7.125C14.25 7.77694 13.7156 8.3125 13.0625 8.3125H5.9375C5.28438 8.3125 4.75 7.77694 4.75 7.125V1.1875H14.25V7.125ZM13.0625 2.375H10.6875V7.125H13.0625V2.375Z"
          fill="#1F0F08"
        />
      </svg>
    );
  }
}

export default SaveButtonIcon;
