import React, { PureComponent } from 'react';

type Props = {
  fill?: string,
};

class Close extends PureComponent<Props> {
  static defaultProps = {
    fill: '#FFF',
  };

  render() {
    return (
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.80758 5.49952L10.7295 1.57761C11.0905 1.2166 11.0905 0.631275 10.7295 0.27031C10.3685 -0.090706 9.78321 -0.090706 9.42219 0.27031L5.50023 4.19227L1.57827 0.270259C1.21725 -0.0907576 0.631984 -0.0907576 0.270968 0.270259C-0.089997 0.631275 -0.089997 1.2166 0.270968 1.57756L4.19293 5.49947L0.270968 9.42143C-0.089997 9.78245 -0.089997 10.3678 0.270968 10.7287C0.631984 11.0898 1.21725 11.0898 1.57827 10.7287L5.50023 6.80677L9.42219 10.7287C9.78315 11.0898 10.3685 11.0898 10.7295 10.7287C11.0905 10.3677 11.0905 9.78245 10.7295 9.42143L6.80758 5.49952Z"
          fill={this.props.fill}
        />
      </svg>
    );
  }
}

export default Close;
