import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { HidePass, ShowPass } from '../../../../assets/svg';
import './styles.scss';

type Props = {
  label?: string,
  placeholder?: string,
  className?: string,
  containerClassName?: string,
  onChange?: Function,
  onBlur?: Function,
  name?: string,
  type?: string,
  error?: string,
  value: any,
};

type State = {
  isPassShown: boolean,
};

class InputField extends PureComponent<Props, State> {
  state = {
    isPassShown: false,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    className: '',
    containerClassName: '',
    onChange: () => {},
    onBlur: () => {},
    name: '',
    type: '',
    error: '',
  };

  onButtonClick = () => {
    this.setState(prevState => ({
      isPassShown: !prevState.isPassShown,
    }));
  };

  getToggelableType = () => {
    const { isPassShown } = this.state;

    if (isPassShown) {
      return 'text';
    }
    return 'password';
  };

  render() {
    const {
      label,
      placeholder,
      containerClassName,
      className,
      onChange,
      onBlur,
      name,
      type,
      error,
      value,
    } = this.props;
    const { isPassShown } = this.state;

    const classname = classNames(
      'input-field',
      className,
      type === 'password' ? 'password-field' : '',
    );

    const containerclassname = classNames(
      'input-field-container',
      containerClassName,
      type === 'password' ? 'password-container' : '',
    );

    return (
      <>
        <div className="input-field-label">{label}</div>
        <div className={containerclassname}>
          <input
            className={classname}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            type={type === 'password' ? this.getToggelableType() : type}
            name={name}
            value={value}
          />
          {type === 'password' && (
            <button
              onClick={this.onButtonClick}
              type="button"
              className="reveal-hide-button"
            >
              {isPassShown ? <ShowPass /> : <HidePass />}
            </button>
          )}
        </div>
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            marginTop: '5px',
            position: 'absolute',
          }}
        >
          {error || ''}
        </div>
      </>
    );
  }
}

export default InputField;
