import React, { PureComponent } from 'react';
import { ArrowHidden, ArrowExpanded } from '../../../assets/svg';

type Props = {
  children: any,
  isOpen: boolean,
  label: string,
  icon: string,
  onClick: Function,
};

class AccordionSection extends PureComponent<Props, *> {
  onClick = () => {
    const { onClick, label } = this.props;

    onClick(label);
  };

  render() {
    const { isOpen, label, icon, children } = this.props;

    return (
      <div>
        <button type="button" className="sidebar-button" onClick={this.onClick}>
          <div
            className={
              isOpen
                ? 'dropdown-indicator dropdown-toggle'
                : 'dropdown-indicator'
            }
          >
            {isOpen ? <ArrowExpanded /> : <ArrowHidden />}
          </div>
          <div className="dropdown-indicator">{icon}</div>
          <div className="dropdown-indicator">{label}</div>
        </button>
        {isOpen && <div className="sidebar-submenu">{children}</div>}
      </div>
    );
  }
}

export default AccordionSection;
