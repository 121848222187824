import React, { PureComponent } from 'react';

type Props = {};

class CalendarIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2664 3.75C10.7821 3.75 11.1999 3.20208 11.1999 2.51993V1.23251C11.1999 0.549748 10.7826 0 10.2664 0C9.75012 0 9.33325 0.549138 9.33325 1.23251V2.52054C9.33371 3.20208 9.75058 3.75 10.2664 3.75Z"
          fill="#9B9B9B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0018 1.90107H13.9849L14 14.9969L13.0007 15H1.0008H0V1.875L2.00111 1.90107V2.42601C2.00111 3.2929 2.67319 3.99827 3.50085 3.99827C4.32851 3.99827 5.00108 3.29239 5.00108 2.42601V1.90107H9.00282V2.42601C9.00282 3.29239 9.67295 3.99827 10.5021 3.99827C11.3302 3.99827 12.0018 3.29239 12.0018 2.42601V1.90107ZM1.0008 13.9547H12.9997H13.0007V5.57002H1.0008V13.9547Z"
          fill="#9B9B9B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.73269 3.75C4.24846 3.75 4.66672 3.20208 4.66672 2.51993V1.23251C4.66672 0.549138 4.24846 0 3.73269 0C3.21692 0 2.80005 0.549138 2.80005 1.23251V2.52054C2.80005 3.20208 3.21646 3.75 3.73269 3.75Z"
          fill="#9B9B9B"
        />
        <rect
          x="5.6001"
          y="7.5"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="2.80005"
          y="7.5"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="8.3999"
          y="7.5"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="10.2666"
          y="7.5"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="5.6001"
          y="10.3125"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="8.3999"
          y="10.3125"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="10.2666"
          y="10.3125"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="5.6001"
          y="12.1875"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="2.80005"
          y="10.3125"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="2.80005"
          y="12.1875"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="8.3999"
          y="12.1875"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
        <rect
          x="10.2666"
          y="12.1875"
          width="0.933333"
          height="0.9375"
          fill="#9B9B9B"
        />
      </svg>
    );
  }
}

export default CalendarIcon;
