import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Rating } from 'primereact/rating';
import { Calendar } from '../../primereact/inputs';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles.scss';

type Props = {
  data: Array<Object>,
  columns: Array<Object>,
  onSort: Function,
  onFilter: Function,
  sortOrder: boolean,
  currFilter: string,
  currFilterValue: string,
  loading: boolean,
  emptyMessage: string,
  sortField: string | boolean,
};

class Table extends PureComponent<Props> {
  filter = (id: number, type: string, length?: number) => {
    const { onFilter, currFilter, currFilterValue } = this.props;
    return (
      <InputText
        keyfilter={type}
        id={id}
        maxLength={length}
        value={currFilter !== id ? '' : currFilterValue}
        className="p-column-filter"
        onChange={ev => onFilter(id, ev.currentTarget.value)}
        autoComplete="off"
      />
    );
  };

  ratingFilter = (id: string) => {
    const { onFilter, currFilter, currFilterValue } = this.props;

    return (
      <div className="rating-container">
        <Rating
          id={id}
          onChange={onFilter}
          value={currFilter !== id ? Number('0') : Number(currFilterValue)}
          stars={5}
        />
      </div>
    );
  };

  dropdownFilter = (id: number, options: Array<Object>) => {
    const { onFilter, currFilter, currFilterValue } = this.props;
    return (
      <Dropdown
        id={id}
        onChange={onFilter}
        options={options}
        value={currFilter !== id ? '' : currFilterValue}
        appendTo={document.body}
        className="ui-column-filter"
        emptyMessage=" "
      />
    );
  };

  calendarFilter = (id: number, type: string) => {
    const { onFilter, currFilter, currFilterValue } = this.props;
    return (
      <Calendar
        id={id}
        name={id}
        // disabled // TODO: remove once API has date filtering
        appendTo={document.body}
        onChange={onFilter}
        value={currFilter !== id ? '' : currFilterValue}
        onBlur={() => {}}
        dateFormat="yy/mm/dd"
        hourFormat="24"
        showTime={type === 'calendarTime'}
      />
    );
  };

  filteringComponents = (
    type: string,
    option?: Array<Object>,
    filterElement: Object,
  ) => {
    switch (type) {
      case 'dropdown':
        return this.dropdownFilter(filterElement[0], option || []);
      case 'rating':
        return this.ratingFilter(filterElement[0]);
      case 'calendar':
      case 'calendarTime':
        return this.calendarFilter(filterElement[0], type);
      default:
        return this.filter(...filterElement);
    }
  };

  renderColumns = (columns: any) =>
    columns.map(column => (
      <Column
        fixed={column.field === 'action'}
        key={column.field}
        body={column.body}
        field={column.field}
        header={column.header}
        sortable={column.sortable}
        className={column.className}
        filter={column.filter}
        filterElement={this.filteringComponents(
          column.type,
          column.options,
          column.filterElement,
        )}
      />
    ));

  render() {
    const {
      data,
      columns,
      onSort,
      sortOrder,
      loading,
      emptyMessage,
      sortField,
    } = this.props;
    return (
      <DataTable
        rows={10}
        scrollable
        value={data}
        onSort={onSort}
        sortBy={sortField}
        sortOrder={sortOrder ? 1 : -1}
        emptyMessage={emptyMessage}
        sortField={sortField}
        className={loading ? 'loading-container' : ''}
        loading={loading}
      >
        {this.renderColumns(columns)}
      </DataTable>
    );
  }
}

export default withTranslation()(Table);
