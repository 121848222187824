import {
  fork,
  call,
  put,
  take,
  delay,
  all,
  debounce,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { channel } from 'redux-saga';
import firebase from 'firebase/app';
import 'firebase/auth';
import type { ActionReducer } from './request.action';
import api from '../../service/api/Api.service';
import { extractToken } from '../../service/api';

export function* setBearerTokenHeader(): Generator<void, void, void> {
  const Api = api.getApi();
  const user = yield firebase.auth().currentUser;
  if (user) {
    // eslint-disable-next-line no-param-reassign
    Api.apiInst.defaults.headers.common.Authorization = yield call(
      extractToken,
      user,
    );
  }
}

function* setTokenIfExpired(message: string) {
  if (message === 'Token has already expired.')
    yield call(setBearerTokenHeader);
}

function* retry(count, msDelay, method, route, payload) {
  let error;
  for (let i = 0; i < count; i += 1) {
    try {
      const apiResponse = yield call(method, route, payload);
      return apiResponse;
    } catch (err) {
      const { message } = err.response;
      yield call(setTokenIfExpired, message);
      if (i < count - 1) {
        yield delay(msDelay);
      }
      error = err;
    }
  }
  // Throw the final catched error so
  // reducers that rely on error responses
  // can received the response data
  throw error;
}

export function* returnErrorResponseAction(
  err: Object,
  action: Function,
): Generator<*, *, *> {
  const { response } = err;
  if (response) {
    yield put(
      action({
        loading: false,
        error: true,
        errorMsg: '',
        response,
      }),
    );
  } else {
    yield put(
      action({
        loading: false,
        response: null,
        error: true,
        errorMsg: err.message || '',
      }),
    );
  }
}
function* resolveError(err, resultReducerAction) {
  const { response } = err;
  if (response && response.httpStatusCode === 503) {
    yield put(push('/maintenance'));
  } else {
    yield call(returnErrorResponseAction, err, resultReducerAction);
  }
}

function* handler(action: Object) {
  const { resultReducerAction } = action;
  const { method, payload, route } = action;
  try {
    let result;
    if (payload) result = yield retry(2, 4000, method, route, payload);
    else result = yield retry(2, 4000, method, route);
    const { response } = result;
    yield put(
      resultReducerAction({
        loading: false,
        error: false,
        errorMsg: '',
        response,
      }),
    );
  } catch (err) {
    yield call(resolveError, err, resultReducerAction);
  }
}

function* requestHandler(chan: Object) {
  while (true) {
    const apiAction: ActionReducer = yield take(chan);
    yield call(handler, apiAction);
  }
}

function* requestFlow() {
  // create 5 workers
  // to handle 5 at most request
  const chan = yield call(channel);
  for (let i = 0; i < 5; i += 1) {
    yield fork(requestHandler, chan);
  }
  while (true) {
    const requestAction: ActionReducer = yield take([
      'REQUEST',
      'SEARCH_REQUEST',
    ]);
    const { resultReducerAction } = requestAction;
    yield put(
      resultReducerAction({
        loading: true,
        error: false,
        errorMsg: '',
        response: null,
      }),
    );
    if (requestAction.type === 'REQUEST') yield put(chan, requestAction);
  }
}

function* searchRequest() {
  yield debounce(1000, 'SEARCH_REQUEST', handler);
}

function* root(): Generator<void, void, void> {
  yield all([fork(requestFlow), fork(searchRequest)]);
}

export default root;
