import firebase from 'firebase/app';
import 'firebase/auth';

const persistence =
  process.env.NODE_ENV === 'test'
    ? firebase.auth.Auth.Persistence.NONE
    : firebase.auth.Auth.Persistence.SESSION;

export const firebaseSignin = async (email: string, password: string): Object =>
  firebase
    .auth()
    .setPersistence(persistence)
    .then(async () => {
      try {
        const result = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        const token = await result.user.getIdTokenResult();
        const { admin } = token.claims;
        return { isAdmin: !!admin, error: false };
      } catch (err) {
        return { error: true, isAdmin: false };
      }
    })
    .catch(error => {
      throw error;
    });

export const firebaseSignOut = async (): Promise<boolean> => {
  try {
    await firebase.auth().signOut();
    return true;
  } catch (err) {
    return false;
  }
};
