import React, { PureComponent } from 'react';

type Props = {};

class SidebarIndicator extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="3" rx="1.5" fill="#1F0F08" />
        <rect y="6" width="20" height="3" rx="1.5" fill="#1F0F08" />
        <rect y="12" width="20" height="3" rx="1.5" fill="#1F0F08" />
      </svg>
    );
  }
}

export default SidebarIndicator;
