import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import { CommonModal } from '../../../components/common/modal';
import { couponSelector } from '../store/admin.selector';
import { fetchCouponsRequestAction } from '../store/admin.request';
import ProgressSpinner from '../../../components/primereact/misc';
import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isErrorModalOpen: boolean,
  data: Object,
};

export const defaultInitialValues = {
  totalIssuedCouponsAmount: 0,
  totalInvalidCouponsAmount: 0,
  totalExpiredCouponsAmountThisMonth: 0,
  totalSettledCouponsAmount: 0,
  initialCouponAmount: 0,
};

class AdminCouponPage extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    if (nextProps.data !== null) {
      const { data } = nextProps;
      return { data };
    }
    return {};
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isErrorModalOpen: false,
    data: { ...defaultInitialValues },
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });
    this.props.fetchCouponStats();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  onClickToRedirect(destination: string) {
    this.props.history.push(destination, this.state.data);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  render() {
    const { t } = this.props;
    return (
      <div className="restaurant-container">
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="coupon"
          userType="admin"
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'couponPageContainerUnscrollable'
              : 'couponPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          {this.props.loading ? (
            <div className="restaurant-spinner-container">
              <ProgressSpinner
                loadingText={t('loading')}
                className="restaurant-spinner"
              />
            </div>
          ) : (
            <Fragment>
              <div className="restaurant-title">{t('viewCoupon')}</div>
              <div className="restaurant-main">
                <div className="restaurant-main-inner">
                  <div className="restaurant-column">
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('totalIssuedCoupon')}
                      </div>
                      <div className="restaurant-form-group__data">
                        {this.props.data.totalIssuedCouponsAmount}
                      </div>
                    </div>
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('validCoupon')}
                      </div>
                      <div className="restaurant-form-group__data">
                        {this.props.data.totalInvalidCouponsAmount}
                      </div>
                    </div>
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('expiredAmount')}
                      </div>
                      <div className="restaurant-form-group__data">
                        {this.props.data.totalExpiredCouponsAmountThisMonth}
                      </div>
                    </div>
                  </div>
                  <div className="restaurant-column">
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('totalExpired')}
                      </div>
                      <div className="restaurant-form-group__data">
                        {this.props.data.totalSettledCouponsAmount}
                      </div>
                    </div>
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('initialAmount')}
                      </div>
                      <div className="restaurant-form-group__data">
                        {this.props.data.initialCouponAmount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  ...couponSelector(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchCouponStats: () => dispatch(fetchCouponsRequestAction()),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminCouponPage),
);
