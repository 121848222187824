import { createDeepEqualSelector } from '../../../utils/selector.util';

const selectorListResponse = (state: Object) => {
  let data = [];
  let status = '';
  let message = '';
  const { response, loading } = state;

  if (response) {
    // eslint-disable-next-line prefer-destructuring
    data = response.data || [];
    const { httpStatusCode } = response;
    status = httpStatusCode;
    message = response.message || '';
  }
  return {
    status,
    data,
    loading,
    error: state.error,
    errorMsg: state.errorMsg || message,
  };
};

const selectorResponse = (state: Object) => {
  let data = null;
  let status = '';
  const { response, loading } = state;

  if (response) {
    // eslint-disable-next-line prefer-destructuring
    data = response.data;
    const { httpStatusCode } = response;
    status = httpStatusCode;
  }
  return {
    status,
    data,
    loading,
    error: state.error,
    errorMsg: state.errorMsg,
  };
};

export const restaurantListSelector = createDeepEqualSelector(
  ['adminStore.restaurants/list/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const restaurantSelector = createDeepEqualSelector(
  ['adminStore.restaurants/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const restaurantAddSelector = createDeepEqualSelector(
  ['adminStore.restaurants/add'],
  (state: Object) => selectorListResponse(state),
);

export const restaurantUpdateSelector = createDeepEqualSelector(
  ['adminStore.restaurants/update'],
  (state: Object) => selectorListResponse(state),
);

export const usersDeleteSelector = createDeepEqualSelector(
  ['adminStore.users/delete'],
  (state: Object) => selectorListResponse(state),
);
export const restaurantDeleteSelector = createDeepEqualSelector(
  ['adminStore.restaurants/delete'],
  (state: Object) => selectorListResponse(state),
);

export const campaignSelector = createDeepEqualSelector(
  ['adminStore.campaign/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const campaignItemSelector = createDeepEqualSelector(
  ['adminStore.campaign/item/fetch'],
  (state: Object) => selectorResponse(state),
);

export const campaignUpdateSelector = createDeepEqualSelector(
  ['adminStore.campaign/update'],
  (state: Object) => selectorListResponse(state),
);

export const campaignDeleteSelector = createDeepEqualSelector(
  ['adminStore.campaign/delete'],
  (state: Object) => selectorListResponse(state),
);

export const hiringSelector = createDeepEqualSelector(
  ['adminStore.hirings/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const menuSelector = createDeepEqualSelector(
  ['adminStore.menu/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const userSelector = createDeepEqualSelector(
  ['adminStore.users/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const userUpdateSelector = createDeepEqualSelector(
  ['adminStore.users/update'],
  (state: Object) => selectorListResponse(state),
);

export const userDeleteSelector = createDeepEqualSelector(
  ['adminStore.users/delete'],
  (state: Object) => selectorListResponse(state),
);

export const tableQuerySelector = createDeepEqualSelector(
  ['adminStore.admin/query'],
  (state: Object) => selectorListResponse(state),
);

export const couponSelector = createDeepEqualSelector(
  ['adminStore.coupon/stats'],
  (state: Object) => selectorListResponse(state),
);

export const cuisinesSelector = createDeepEqualSelector(
  ['adminStore.cuisines/fetch'],
  (state: Object) => selectorResponse(state),
);

export const fetchAppNotificationInfoSelector = createDeepEqualSelector(
  ['adminStore.appNotification/info/fetch'],
  (state: Object) => selectorResponse(state),
);

export const deleteAppNotificationSelector = createDeepEqualSelector(
  ['adminStore.appNotification/delete'],
  (state: Object) => selectorResponse(state),
);

export const updateAppNotificationSelector = createDeepEqualSelector(
  ['adminStore.appNotification/update'],
  (state: Object) => selectorResponse(state),
);
