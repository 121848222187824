/* eslint-disable no-return-assign */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { AppContainer } from './containers/appContainer';
import firebaseApp from './service/firebase/firebase.service';
import { AuthContextProvider } from './containers/auth/AuthContextProvider';
import setupStore from './store/index';
import setUpApi from './service/api';
import './utils/i18n';

firebaseApp();
setUpApi();
const wrapper = document.getElementById('root');
const history = createBrowserHistory();
const store = setupStore(history);

if (wrapper) {
  ReactDOM.render(
    <AuthContextProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppContainer />
        </ConnectedRouter>
      </Provider>
    </AuthContextProvider>,
    wrapper,
  );
}
