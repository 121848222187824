import React, { PureComponent } from 'react';

type Props = {};

class DetailsIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3333 0H1.66667C0.746208 0 0 0.746167 0 1.66667V14.3333C0 15.2538 0.746208 16 1.66667 16H14.3333C15.2538 16 16 15.2538 16 14.3333V1.66667C16 0.746167 15.2538 0 14.3333 0ZM10 12.6667H3.33333V10.6667H10V12.6667ZM12.6667 9H3.33333V7H12.6667V9ZM12.6667 5.33333H3.33333V3.33333H12.6667V5.33333Z"
          fill="#513E3D"
        />
      </svg>
    );
  }
}

export default DetailsIcon;
