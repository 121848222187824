import React from 'react';
import { Calendar as PrimeReactCalendar } from 'primereact/calendar';
import PropTypes from 'prop-types';

const Calendar = (props: Object) => {
  const {
    id,
    name,
    className,
    placeholder,
    value,
    showTime,
    hourFormat,
    onChange,
    onBlur,
    disabled,
    readOnlyInput,
    appendTo,
    dateFormat,
  } = props;
  return (
    <PrimeReactCalendar
      id={id}
      name={name}
      className={className}
      placeholder={placeholder}
      value={value}
      showTime={showTime}
      showSeconds={false}
      hourFormat={hourFormat}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      readOnlyInput={readOnlyInput}
      appendTo={appendTo}
      dateFormat={dateFormat}
    />
  );
};

Calendar.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  showTime: PropTypes.bool,
  hourFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnlyInput: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  appendTo: PropTypes.any,
  dateFormat: PropTypes.string,
};

Calendar.defaultProps = {
  className: '',
  placeholder: '',
  hourFormat: '12',
  showTime: false,
  disabled: false,
  readOnlyInput: false,
  appendTo: null,
  dateFormat: 'mm/dd/yy',
};

export default Calendar;
