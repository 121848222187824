import React, { PureComponent } from 'react';

type Props = {};

class Image404 extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="546"
        height="302"
        viewBox="0 0 546 302"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M493.374 275.222C491.646 275.222 490.206 274.646 489.054 273.494C488.094 272.342 487.614 270.998 487.614 269.462V89.4617L490.782 91.7657L408.414 202.646L407.55 199.478H521.022C522.558 199.478 523.902 200.054 525.054 201.206C526.206 202.166 526.782 203.414 526.782 204.95C526.782 206.486 526.206 207.83 525.054 208.982C523.902 209.942 522.558 210.422 521.022 210.422H399.774C398.046 210.422 396.606 209.75 395.454 208.406C394.494 207.062 394.014 205.718 394.014 204.374C394.014 202.838 394.494 201.59 395.454 200.63L488.478 75.9257C489.438 74.7737 490.398 74.1017 491.358 73.9097C492.51 73.7177 493.182 73.6217 493.374 73.6217C495.102 73.6217 496.446 74.1977 497.406 75.3497C498.558 76.5017 499.134 77.8457 499.134 79.3817V269.462C499.134 270.998 498.558 272.342 497.406 273.494C496.254 274.646 494.91 275.222 493.374 275.222Z"
          fill="white"
        />
        <path
          d="M408.907 214.271C407.865 217.95 403.779 221.015 396.256 223.041C388.841 225.037 378.587 225.878 366.173 225.528C341.367 224.828 308.282 219.389 272.821 209.347C237.36 199.306 206.335 186.591 184.846 174.181C174.091 167.971 165.8 161.879 160.532 156.291C155.188 150.621 153.316 145.869 154.358 142.19C155.399 138.512 159.486 135.446 167.008 133.42C174.424 131.424 184.678 130.583 197.092 130.933C221.898 131.633 254.983 137.073 290.444 147.114C325.905 157.156 356.93 169.87 378.419 182.28C389.174 188.491 397.465 194.582 402.732 200.17C408.076 205.84 409.949 210.592 408.907 214.271Z"
          stroke="#FCAE17"
          strokeWidth="3"
        />
        <circle cx="281.694" cy="174.222" r="98" fill="url(#paint0_linear)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.059 132.361C182.518 132.283 173.819 133.14 167.398 134.869C159.969 136.869 156.611 139.737 155.801 142.599C154.99 145.46 156.347 149.663 161.624 155.262C166.748 160.698 174.898 166.705 185.596 172.882C206.95 185.214 237.852 197.886 273.23 207.904C308.607 217.922 341.566 223.333 366.215 224.028C378.563 224.377 388.652 223.535 395.866 221.592C403.295 219.592 406.654 216.724 407.464 213.862C408.274 211.001 406.918 206.798 401.641 201.199C396.758 196.019 389.129 190.322 379.167 184.452C379.281 183.355 379.376 182.253 379.454 181.145C400.9 193.576 412.885 205.728 410.35 214.68C405.258 232.662 343.501 230.921 272.412 210.79C201.323 190.66 147.822 159.764 152.914 141.781C155.371 133.106 171.015 129.022 194.533 129.377C194.026 130.362 193.534 131.357 193.059 132.361Z"
          fill="#FCAE17"
        />
        <circle cx="296.194" cy="214.722" r="21.5" fill="url(#paint1_linear)" />
        <path
          opacity="0.3"
          d="M129.374 275.222C127.646 275.222 126.206 274.646 125.054 273.494C124.094 272.342 123.614 270.998 123.614 269.462V89.4617L126.782 91.7657L44.4143 202.646L43.5503 199.478H157.022C158.558 199.478 159.902 200.054 161.054 201.206C162.206 202.166 162.782 203.414 162.782 204.95C162.782 206.486 162.206 207.83 161.054 208.982C159.902 209.942 158.558 210.422 157.022 210.422H35.7743C34.0463 210.422 32.6063 209.75 31.4543 208.406C30.4943 207.062 30.0143 205.718 30.0143 204.374C30.0143 202.838 30.4943 201.59 31.4543 200.63L124.478 75.9257C125.438 74.7737 126.398 74.1017 127.358 73.9097C128.51 73.7177 129.182 73.6217 129.374 73.6217C131.102 73.6217 132.446 74.1977 133.406 75.3497C134.558 76.5017 135.134 77.8457 135.134 79.3817V269.462C135.134 270.998 134.558 272.342 133.406 273.494C132.254 274.646 130.91 275.222 129.374 275.222Z"
          fill="white"
        />
        <path
          opacity="0.9"
          d="M18.1943 76.2217L19.8782 81.4041H25.3273L20.9189 84.6069L22.6027 89.7893L18.1943 86.5864L13.7859 89.7893L15.4698 84.6069L11.0614 81.4041H16.5105L18.1943 76.2217Z"
          fill="#FCAE17"
        />
        <path
          opacity="0.9"
          d="M176.194 247.222L177.878 252.404H183.327L178.919 255.607L180.603 260.789L176.194 257.586L171.786 260.789L173.47 255.607L169.061 252.404H174.51L176.194 247.222Z"
          fill="#FCAE17"
        />
        <path
          opacity="0.9"
          d="M106.194 152.222L107.878 157.404H113.327L108.919 160.607L110.603 165.789L106.194 162.586L101.786 165.789L103.47 160.607L99.0614 157.404H104.51L106.194 152.222Z"
          fill="#FCAE17"
        />
        <path
          opacity="0.9"
          d="M420.694 109.222L422.939 116.132H430.205L424.327 120.402L426.572 127.312L420.694 123.041L414.816 127.312L417.062 120.402L411.184 116.132H418.449L420.694 109.222Z"
          fill="#FCAE17"
        />
        <path
          opacity="0.9"
          d="M280.694 21.2217L282.939 28.1315H290.205L284.327 32.402L286.572 39.3118L280.694 35.0413L274.816 39.3118L277.062 32.402L271.184 28.1315H278.449L280.694 21.2217Z"
          fill="#FCAE17"
        />
        <path
          d="M434.331 288.272C434.603 288.272 434.83 288.362 435.011 288.544C435.193 288.714 435.283 288.935 435.283 289.207C435.283 289.479 435.193 289.705 435.011 289.887C434.83 290.057 434.603 290.142 434.331 290.142H431.951V292.369C431.951 292.686 431.844 292.947 431.628 293.151C431.424 293.355 431.164 293.457 430.846 293.457C430.529 293.457 430.263 293.355 430.047 293.151C429.843 292.947 429.741 292.686 429.741 292.369V290.142H427.412C427.14 290.142 426.914 290.051 426.732 289.87C426.551 289.688 426.46 289.462 426.46 289.19C426.46 288.918 426.551 288.697 426.732 288.527C426.914 288.357 427.14 288.272 427.412 288.272H429.741V285.739C429.741 285.421 429.843 285.161 430.047 284.957C430.263 284.753 430.535 284.651 430.863 284.651C431.181 284.651 431.441 284.753 431.645 284.957C431.849 285.161 431.951 285.421 431.951 285.739V288.272H434.331Z"
          fill="#EDA5A3"
        />
        <path
          d="M187.331 61.2717C187.603 61.2717 187.83 61.3623 188.011 61.5437C188.193 61.7137 188.283 61.9347 188.283 62.2067C188.283 62.4787 188.193 62.7053 188.011 62.8867C187.83 63.0567 187.603 63.1417 187.331 63.1417H184.951V65.3687C184.951 65.686 184.844 65.9467 184.628 66.1507C184.424 66.3547 184.164 66.4567 183.846 66.4567C183.529 66.4567 183.263 66.3547 183.047 66.1507C182.843 65.9467 182.741 65.686 182.741 65.3687V63.1417H180.412C180.14 63.1417 179.914 63.051 179.732 62.8697C179.551 62.6883 179.46 62.4617 179.46 62.1897C179.46 61.9177 179.551 61.6967 179.732 61.5267C179.914 61.3567 180.14 61.2717 180.412 61.2717H182.741V58.7387C182.741 58.4213 182.843 58.1607 183.047 57.9567C183.263 57.7527 183.535 57.6507 183.863 57.6507C184.181 57.6507 184.441 57.7527 184.645 57.9567C184.849 58.1607 184.951 58.4213 184.951 58.7387V61.2717H187.331Z"
          fill="#EDA5A3"
        />
        <path
          d="M544.331 160.272C544.603 160.272 544.83 160.362 545.011 160.544C545.193 160.714 545.283 160.935 545.283 161.207C545.283 161.479 545.193 161.705 545.011 161.887C544.83 162.057 544.603 162.142 544.331 162.142H541.951V164.369C541.951 164.686 541.844 164.947 541.628 165.151C541.424 165.355 541.164 165.457 540.846 165.457C540.529 165.457 540.263 165.355 540.047 165.151C539.843 164.947 539.741 164.686 539.741 164.369V162.142H537.412C537.14 162.142 536.914 162.051 536.732 161.87C536.551 161.688 536.46 161.462 536.46 161.19C536.46 160.918 536.551 160.697 536.732 160.527C536.914 160.357 537.14 160.272 537.412 160.272H539.741V157.739C539.741 157.421 539.843 157.161 540.047 156.957C540.263 156.753 540.535 156.651 540.863 156.651C541.181 156.651 541.441 156.753 541.645 156.957C541.849 157.161 541.951 157.421 541.951 157.739V160.272H544.331Z"
          fill="#EDA5A3"
        />
        <path
          d="M377.829 62.8217C378.213 62.8217 378.533 62.9497 378.789 63.2057C379.045 63.4457 379.173 63.7577 379.173 64.1417C379.173 64.5257 379.045 64.8457 378.789 65.1017C378.533 65.3417 378.213 65.4617 377.829 65.4617H374.469V68.6057C374.469 69.0537 374.317 69.4217 374.013 69.7097C373.725 69.9977 373.357 70.1417 372.909 70.1417C372.461 70.1417 372.085 69.9977 371.781 69.7097C371.493 69.4217 371.349 69.0537 371.349 68.6057V65.4617H368.061C367.677 65.4617 367.357 65.3337 367.101 65.0777C366.845 64.8217 366.717 64.5017 366.717 64.1177C366.717 63.7337 366.845 63.4217 367.101 63.1817C367.357 62.9417 367.677 62.8217 368.061 62.8217H371.349V59.2457C371.349 58.7977 371.493 58.4297 371.781 58.1417C372.085 57.8537 372.469 57.7097 372.933 57.7097C373.381 57.7097 373.749 57.8537 374.037 58.1417C374.325 58.4297 374.469 58.7977 374.469 59.2457V62.8217H377.829Z"
          fill="#EDA5A3"
        />
        <path
          d="M58.8288 259.822C59.2128 259.822 59.5328 259.95 59.7888 260.206C60.0448 260.446 60.1728 260.758 60.1728 261.142C60.1728 261.526 60.0448 261.846 59.7888 262.102C59.5328 262.342 59.2128 262.462 58.8288 262.462H55.4688V265.606C55.4688 266.054 55.3168 266.422 55.0128 266.71C54.7248 266.998 54.3568 267.142 53.9088 267.142C53.4608 267.142 53.0848 266.998 52.7808 266.71C52.4928 266.422 52.3488 266.054 52.3488 265.606V262.462H49.0608C48.6768 262.462 48.3568 262.334 48.1008 262.078C47.8448 261.822 47.7168 261.502 47.7168 261.118C47.7168 260.734 47.8448 260.422 48.1008 260.182C48.3568 259.942 48.6768 259.822 49.0608 259.822H52.3488V256.246C52.3488 255.798 52.4928 255.43 52.7808 255.142C53.0848 254.854 53.4688 254.71 53.9328 254.71C54.3808 254.71 54.7488 254.854 55.0368 255.142C55.3248 255.43 55.4688 255.798 55.4688 256.246V259.822H58.8288Z"
          fill="#FFEFCF"
        />
        <path
          d="M454.164 47.1022L454.037 35.1361L464.464 41.0093L454.164 47.1022Z"
          stroke="#FFEFCF"
        />
        <path
          d="M353.65 275.686L359.323 265.15L365.612 275.331L353.65 275.686Z"
          stroke="#FFEFCF"
        />
        <path
          d="M3.92598 168.287L14.3536 162.416L14.2242 174.382L3.92598 168.287Z"
          stroke="#FFEFCF"
        />
        <path
          d="M101.344 18.942L116.506 4.72292L121.239 24.9628L101.344 18.942Z"
          stroke="#FFEFCF"
        />
        <circle cx="526.5" cy="54.5" r="9" stroke="#03CD90" />
        <circle cx="82.5" cy="293.5" r="6" stroke="#03CD90" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="211.694"
            y1="118.722"
            x2="348.694"
            y2="233.222"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EA5E59" />
            <stop offset="1" stopColor="#E7251E" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="278.694"
            y1="201.722"
            x2="309.694"
            y2="229.722"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E39600" />
            <stop offset="1" stopColor="#FFD076" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default Image404;
