import React, { PureComponent } from 'react';

type Props = {};

class ArrowExpanded extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1236 0.862355C10.7238 0.479475 10.076 0.479475 9.67475 0.862355L6.05424 4.32359L2.43373 0.862355C2.034 0.479475 1.38617 0.479475 0.986441 0.862355C0.586714 1.24524 0.586714 1.86397 0.986441 2.24685L5.33136 6.40033C5.73109 6.78015 6.37892 6.78015 6.77865 6.40033L11.1236 2.24685C11.5233 1.86397 11.5233 1.24524 11.1236 0.862355Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default ArrowExpanded;
