import React, {
  useReducer,
  createContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

export const AuthContext: Object = createContext({});

const initialState = {
  fetching: true,
  firebaseAuth: false,
  restaurantAuth: true,
  isAdmin: false,
  error: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'disableFetch':
      return {
        ...state,
        fetching: false,
      };
    case 'enableFetch':
      return {
        ...state,
        fetching: true,
      };
    case 'firebaseAuth':
      return {
        ...state,
        firebaseAuth: action.payload.firebaseAuth,
        isAdmin: action.payload.isAdmin,
      };
    case 'restaurantAuth':
      return {
        ...state,
        restaurantAuth: action.payload.restaurantAuth,
      };
    case 'logout':
      return {
        ...state,
        restaurantAuth: false,
        firebaseAuth: false,
        isAdmin: false,
        fetching: false,
        error: null,
      };
    case 'error':
      return { ...state, error: action.payload };
    default:
      throw new Error();
  }
};

const AuthContextProvider = (props: Object) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [propsState, setProps] = useState(null);
  const dispatchAuthentication = useCallback(
    async user => {
      if (user) {
        const tokenResult = await user.getIdTokenResult();
        const { claims } = tokenResult;
        if (claims)
          dispatch({
            type: 'firebaseAuth',
            payload: {
              firebaseAuth: true,
              isAdmin: claims.admin ? claims.admin : false,
            },
          });
      } else {
        dispatch({
          type: 'firebaseAuth',
          payload: {
            firebaseAuth: false,
            isAdmin: false,
          },
        });
      }
      dispatch({ type: 'disableFetch' });
    },
    [dispatch],
  );

  useEffect(() => {
    try {
      firebase.auth().onIdTokenChanged(dispatchAuthentication);
    } catch (err) {
      dispatch({
        type: 'firebaseAuth',
        payload: {
          firebaseAuth: false,
          isAdmin: false,
        },
      });
      dispatch({ type: 'restaurantAuth', payload: { restaurantAuth: false } });
      dispatch({ type: 'error', payload: { error: err.message } });
      dispatch({ type: 'disableFetch' });
    }
  }, [state.isAdmin, dispatchAuthentication]);

  useEffect(() => {
    setProps(props.children);
  }, [props.children]);

  const { isAdmin, error, fetching } = state;
  const isAuthenticated = state.firebaseAuth && state.restaurantAuth;

  return (
    <AuthContext.Provider
      value={{ isAdmin, isAuthenticated, dispatch, error, fetching }}
    >
      {propsState}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };
