import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Map } from '../../../components/common/map';
import Button from '../../../components/primereact/buttons';
import ProgressSpinner from '../../../components/primereact/misc';
import { fetchStoreInformationRequestAction } from '../store/restaurant.request';
import { restaurantSelector } from '../store/restaurant.selector';
import { MAP_API_KEY } from '../../constants';
import { IMAGE_URL } from '../../../config/config';
import noImage from '../../../assets/images/noimage.png';
import './styles.scss';

type State = {
  data: Object,
};

export const defaultInitialValues = {
  name: '',
  description: '',
  cuisine: [],
  usageRate: 0,
  budget: '',
  location: {
    _longitude: 0,
    _latitude: 0,
  },
  address: '',
  telephoneNumber: '',
  googleMapsUrl: '',
  homepageUrl: '',
  file: null,
  image: '',
  operationHours: {
    monday: { to: '', from: '', closed: true },
    tuesday: { to: '', from: '', closed: true },
    wednesday: { to: '', from: '', closed: true },
    thursday: { to: '', from: '', closed: true },
    friday: { to: '', from: '', closed: true },
    saturday: { to: '', from: '', closed: true },
    sunday: { to: '', from: '', closed: true },
  },
};

class RestaurantInformationDetails extends PureComponent<*, State> {
  static buildEditableData = (data: Object) => {
    return data;
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    if (nextProps.data !== null) {
      const { data } = nextProps;
      return { data: this.buildEditableData(data) };
    }
    return {};
  };

  state = {
    data: { ...defaultInitialValues },
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchRestaurant(uid);
    }
  }

  onClickToRedirect(destination: string) {
    this.props.history.push(destination, this.state.data);
  }

  renderYenSign = (repeatCount: any) => '¥'.repeat(repeatCount);

  render() {
    const { t, i18n } = this.props;

    return this.props.loading ? (
      <div className="restaurant-spinner-container">
        <ProgressSpinner
          loadingText={t('loading')}
          className="restaurant-spinner"
        />
      </div>
    ) : (
      <Fragment>
        <div className="restaurant-title">
          {t('viewStoreInfo')}
          <div className="pull-right">
            <Button
              label={t('edit')}
              type="button"
              className="restaurant-button restaurant-button--edit"
              onClick={() => this.onClickToRedirect('edit')}
            />
          </div>
        </div>
        <div className="restaurant-main">
          <div className="restaurant-main-inner">
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">{t('name')}</div>
                <div className="restaurant-form-group__data">
                  {this.state.data && this.state.data.name
                    ? this.state.data.name
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('description')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.state.data && this.state.data.description
                    ? this.state.data.description
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('cuisine')}
                </div>
                <div className="restaurant-form-group__data restaurant-form-group__data--cuisine">
                  {this.state.data && this.state.data.cuisine
                    ? this.state.data.cuisine
                        .map(cuisine =>
                          i18n.language === 'ja' ? cuisine.ja : cuisine.en,
                        )
                        .join(', ')
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('usageRate')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.state.data && this.state.data.usageRate
                    ? `${this.state.data.usageRate}%`
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('budget')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.state.data && this.state.data.budget
                    ? this.renderYenSign(this.state.data.budget)
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('location')}
                </div>
                <div className="restaurant-form-group__data">
                  <div className="restaurant-map-wrapper">
                    <div className="restaurant-map">
                      <Map
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        center={{
                          /* eslint-disable  no-underscore-dangle */
                          lat:
                            this.state.data.location._latitude === 0
                              ? 35.680167363485616
                              : this.state.data.location._latitude,
                          lng:
                            this.state.data.location._longitude === 0
                              ? 139.76943969726562
                              : this.state.data.location._longitude,
                        }}
                        zoom={15}
                        hasMarker
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('address')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.state.data && this.state.data.address
                    ? this.state.data.address
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('telephoneNum')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.state.data && this.state.data.telephoneNumber
                    ? this.state.data.telephoneNumber
                    : ''}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('homepageURL')}
                </div>
                <div className="restaurant-form-group__data">
                  <a
                    href={
                      this.state.data && this.state.data.homepageUrl
                        ? this.state.data.homepageUrl
                        : '#'
                    }
                    className="restaurant-form-group__link"
                  >
                    {this.state.data && this.state.data.homepageUrl
                      ? this.state.data.homepageUrl
                      : ''}
                  </a>
                </div>
              </div>
            </div>
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('googleMapURL')}
                </div>
                <div className="restaurant-form-group__data">
                  <a
                    href={
                      this.state.data && this.state.data.googleMapsUrl
                        ? this.state.data.googleMapsUrl
                        : '#'
                    }
                    className="restaurant-form-group__link"
                  >
                    {this.state.data && this.state.data.googleMapsUrl
                      ? this.state.data.googleMapsUrl
                      : ''}
                  </a>
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">{t('logo')}</div>
                <div className="restaurant-form-group__data">
                  <div className="restaurant-image-wrapper">
                    {this.state.data && this.state.data.image ? (
                      <img
                        src={
                          this.state.data
                            ? `${IMAGE_URL}${this.state.data.image}`
                            : { noImage }
                        }
                        alt="..."
                      />
                    ) : (
                      <img src={noImage} alt="..." />
                    )}
                  </div>
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('operationHours')}
                </div>
                <div className="restaurant-form-group__data">
                  <div className="restaurant-operation-hours">
                    {this.state.data && this.state.data.operationHours
                      ? [
                          'monday',
                          'tuesday',
                          'wednesday',
                          'thursday',
                          'friday',
                          'saturday',
                          'sunday',
                        ].map(dayOfWeek => (
                          <div
                            key={dayOfWeek}
                            className="restaurant-operation-hours__row"
                          >
                            <div className="restaurant-operation-hours__day">
                              {t(`${dayOfWeek}`)}
                            </div>
                            <div
                              className={
                                this.state.data &&
                                this.state.data.operationHours[dayOfWeek]
                                  .closed !== 'Closed'
                                  ? 'restaurant-operation-hours__time'
                                  : 'restaurant-operation-hours__closed'
                              }
                            >
                              {this.state.data &&
                              this.state.data.operationHours[dayOfWeek].closed
                                ? `${t('closed')}`
                                : `${moment(
                                    this.state.data &&
                                      this.state.data.operationHours[dayOfWeek]
                                        .from,
                                    'HH:mm',
                                  ).format('hh:mm A') || ''} - ${moment(
                                    this.state.data &&
                                      this.state.data.operationHours[dayOfWeek]
                                        .to,
                                    'HH:mm',
                                  ).format('hh:mm A') || ''}`}
                            </div>
                          </div>
                        ))
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => restaurantSelector(state);

const mapDispatchToProps = (dispatch: Function) => ({
  fetchRestaurant: (restaurantId: string) =>
    dispatch(fetchStoreInformationRequestAction(restaurantId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantInformationDetails),
);
