import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Button from '../../../components/primereact/buttons';
import { Image404 } from '../../../assets/svg';
import './styles.scss';

type State = {
  destination: string,
};

class Error404 extends PureComponent<*, State> {
  render() {
    const { t } = this.props;
    return (
      <div className="errorContainer">
        <Image404 />
        <h1 className="errorHeader">{t('404title')}</h1>
        <div className="messageContainer">{t('404message')}</div>
        <Button
          onClick={() => this.props.history.push('/')}
          label={t('backToMainPage')}
          className="restaurant-button restaurant-button--save"
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(Error404));
