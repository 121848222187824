import React, { PureComponent, Fragment } from 'react';
import firebase from 'firebase/app';
import { withTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Growl } from 'primereact/growl';
import ProgressSpinner from '../../../components/primereact/misc';
import { CommonModal } from '../../../components/common/modal';
import {
  addStoreHiring,
  updateStoreHiring,
  fetchStoreBalanceRequestAction,
} from '../store/restaurant.request';
import {
  hiringUpdateSelector,
  storeBalanceSelector,
} from '../store/restaurant.selector';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
  Calendar,
} from '../../../components/primereact/inputs';
import noImage from '../../../assets/images/noimage.png';
import hiringSchema from './restaurantHiringEdit.validation';
import { IMAGE_URL } from '../../../config/config';
import { FileInput } from '../../../components/common/input';
import { CalendarIcon } from '../../../assets/svg';

type State = {
  isSidebarOpen: boolean,
  isDimensionAccepted: boolean,
  initialValues: Object,
  hiringId: string,
  isModalOpen: boolean,
  isShowMessage: boolean,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
};

export const defaultInitialValues = {
  title: '',
  image: '',
  file: null,
  description: '',
  deliveryDate: '',
};

class AdminCampaignEdit extends PureComponent<*, State> {
  static defaultProps = {
    isAdd: false,
  };

  static buildEditableData = (data: Object) => {
    const editData = { ...data };
    const { image } = editData;

    return {
      image: image ? `${IMAGE_URL}${image}` : noImage,
      title: editData.title,
      description: editData.description,
      deliveryDate: new Date(editData.deliveryDate),
    };
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    let data;
    let hiringId;
    Object.keys(nextProps).forEach((key: string) => {
      if (key === 'location') {
        const { state } = nextProps.location;
        if (state) {
          data = this.buildEditableData(state);
          hiringId = state.id;
        }
      }
    });
    if (data) return { initialValues: { ...data }, hiringId };
    return { initialValues: { ...defaultInitialValues } };
  };

  state = {
    isSidebarOpen: false,
    isDimensionAccepted: true,
    initialValues: null,
    hiringId: '',
    isModalOpen: false,
    isShowMessage: false,
    isDisconnected: false,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.status === 201 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      const user = firebase.auth().currentUser;
      if (user) {
        const { uid } = user;
        this.props.fetchStoreBalance(uid);
      }
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      this.props.data.code === 'NOT_ENOUGH_COUPONS' &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInsufficientCoupons();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  checkDimensionValidity = (status: boolean) => {
    this.setState({
      isDimensionAccepted: status,
    });
  };

  submitForm = (data: Object) => {
    const user = firebase.auth().currentUser;
    const { hiringId } = this.state;

    if (user) {
      const restaurantId = user.uid;
      if (this.props.isAdd) {
        Object.assign(data, { restaurantId });
        this.props.addHiring({ data, restaurantId }, restaurantId);
      } else {
        this.props.updateHiring(
          { data, restaurantId, hiringId },
          hiringId,
          restaurantId,
        );
      }
    }

    this.setState({
      isShowMessage: true,
    });
  };

  validState = (props: Object) => {
    if (props.isValid && this.state.isDimensionAccepted) return false;
    return true;
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: `${t('toastSuccessSummmary')}`,
      detail: `${t('toastSuccessDetail')}`,
    });
  };

  showError = () => {
    const { t } = this.props;

    this.growl.show({
      severity: 'error',
      summary: `${t('toastErrorSummary')}`,
      detail: `${t('toastErrorDetail')}`,
    });
  };

  showInsufficientCoupons = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'error',
      summary: `${t('toastInsufficientCouponsSummary')}`,
      detail: `${t('toastInsufficientCouponsDetail')}`,
    });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  onConfirmationClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onSubmitGetValue = (isSubmitting: boolean) => {
    if (isSubmitting) this.formik.submitForm();
  };

  growl: any;

  formik: any;

  fileInput: any;

  render() {
    const { t } = this.props;
    const currentBalance =
      this.props.storeBalance &&
      this.props.storeBalance.data &&
      this.props.storeBalance.data.active
        ? this.props.storeBalance.data.active
        : 0;

    return (
      <Fragment>
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <CommonModal
          isOpen={this.state.isModalOpen}
          type="confirmation"
          message={t('modalConfirmationMessage', {
            advertType: 'hiring',
            availableActiveCoupons: currentBalance,
          })}
          onClick={this.onConfirmationClick}
          onSubmitGetValue={this.onSubmitGetValue}
        />
        <Formik
          ref={ref => {
            this.formik = ref;
          }}
          initialValues={this.state.initialValues}
          onSubmit={this.submitForm}
          validationSchema={hiringSchema}
          render={props => (
            <Form>
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {t(this.props.isAdd ? 'addHiring' : 'editHiring')}
                <div className="pull-right">
                  <Button
                    label={t('save')}
                    type={this.props.isAdd ? 'button' : 'submit'}
                    onClick={this.props.isAdd ? this.onConfirmationClick : null}
                    className={
                      this.props.loading
                        ? 'restaurant-button restaurant-button--save transparent-text'
                        : 'restaurant-button restaurant-button--save'
                    }
                    disabled={this.validState(props) || this.props.loading}
                  />
                  {this.props.loading && (
                    <ProgressSpinner
                      loadingText=""
                      className="restaurant-spinner restaurant-spinner--button"
                    />
                  )}
                </div>
              </div>
              {this.props.isAdd && (
                <div className="campaignEditBanner">
                  {t('hiringMessage', {
                    availableActiveCoupons: currentBalance,
                  })}
                </div>
              )}
              <fieldset
                className="restaurant-fieldset"
                disabled={this.props.loading}
              >
                <div className="restaurant-main">
                  <div className="restaurant-main-inner">
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('title')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="title"
                            name="title"
                            type="text"
                            className="restaurant-form-input"
                            value={props.values.title}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="title">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('description')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputTextarea
                              id="description"
                              name="description"
                              className="restaurant-form-input"
                              value={props.values.description}
                              rows={5}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <ErrorMessage name="description">
                              {msg => (
                                <div className="restaurant-form-error">
                                  {t(`${msg}`)}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('deliveryDate')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <div
                            className="calendar-container"
                            style={
                              this.props.loading
                                ? { opacity: '0.5' }
                                : { opacity: 'initial' }
                            }
                          >
                            <Calendar
                              id="deliveryDate"
                              name="deliveryDate"
                              value={props.values.deliveryDate}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              showTime
                              readOnlyInput
                            />
                            <span className="restaurantCalendarIcon">
                              <CalendarIcon />
                            </span>
                          </div>
                          <ErrorMessage name="deliveryDate">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('photoOptional')}
                        </div>
                        <div className="restaurant-form-group__data restaurant-form-group__data--fileupload">
                          <div
                            style={
                              this.props.loading
                                ? { opacity: '0.5' }
                                : { opacity: 'initial' }
                            }
                          >
                            <Field
                              id="image"
                              name="image"
                              component={FileInput}
                              checkDimensionValidity={
                                this.checkDimensionValidity
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  ...hiringUpdateSelector(state),
  storeBalance: storeBalanceSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  addHiring: (payload: Object) => dispatch(addStoreHiring(payload)),
  updateHiring: (payload: Object, hiringId: string) =>
    dispatch(updateStoreHiring(payload, hiringId)),
  fetchStoreBalance: (restaurantId: string) =>
    dispatch(fetchStoreBalanceRequestAction(restaurantId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminCampaignEdit),
);
