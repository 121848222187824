import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  refreshUserAfterDeleteAction,
  fetchUserRequestAction,
  updateRestaurantAction,
  updateRestaurantRequestAction,
  deleteRestaurantAction,
  addCampaignRequestAction,
  updateCampaignRequestAction,
  addCampaign,
  updateCampaign,
  deleteCampaign,
  deleteAppNotification,
  addAppNotification,
  updateAppNotification,
  addAppNotificationRequestAction,
  updateAppNotificationRequestAction,
} from './admin.request';
import {
  deleteUserReducerAction,
  updateRestaurantReducerAction,
  deleteRestaurantReducerAction,
  updateCampaignReducerAction,
  deleteCampaignReducerAction,
  updateAppNotificationReducerAction,
  deleteAppNotificationReducerAction,
} from './admin.reducer';
import ApiService from '../../../service/api/Api.service';
import { returnErrorResponseAction } from '../../../store/request/request.saga';
import { removeEmptyValues } from '../../../utils/data.util';

function* refreshUserList(action: Object) {
  const { payload } = action;
  const { error, loading } = payload;

  if (!error && !loading) {
    yield put(deleteUserReducerAction(payload));
    yield put(fetchUserRequestAction());
  }
  return yield put(deleteUserReducerAction(payload));
}

function* updateRestaurantInfo(
  data: Object,
  selectedId: string,
  image: string,
) {
  /* eslint-disable no-param-reassign */
  if (image) data.image = image;
  else delete data.image;
  const cleanedData = removeEmptyValues(data);
  yield put(updateRestaurantRequestAction(cleanedData, selectedId));
  /* eslint-enable no-param-reassign */
}

function* updateRestaurantFlow(action: Object) {
  try {
    // upload file
    // wait for success
    // if success update info
    let result = null;

    const { data, selectedId } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(updateRestaurantReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      // delete file property
      // update only needs image property
      // with a filename as a values
      delete data.file;
    }

    if (result) {
      yield call(
        updateRestaurantInfo,
        data,
        selectedId,
        result.response.data.filename,
      );
    } else {
      yield call(updateRestaurantInfo, data, selectedId);
    }
  } catch (error) {
    yield call(returnErrorResponseAction, error, updateRestaurantReducerAction);
  }
}

function* updateCampaignInfo(
  data: Object,
  campaignId?: string,
  image?: string,
) {
  /* eslint-disable no-param-reassign */
  if (image) data.image = image;
  else delete data.image;
  if (campaignId) {
    yield put(updateCampaignRequestAction(data, campaignId));
  } else {
    delete data.file;
    yield put(addCampaignRequestAction(data));
  }
  /* eslint-enable no-param-reassign */
}

function* updateCampaignFlow(action: Object) {
  try {
    let result = null;

    const { data, campaignId } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(updateCampaignReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      delete data.file;
    }

    if (campaignId) {
      if (result)
        yield call(
          updateCampaignInfo,
          data,
          campaignId,
          result.response.data.filename,
        );
      else {
        yield call(updateCampaignInfo, data, campaignId);
      }
    }

    if (result)
      yield call(updateCampaignInfo, data, null, result.response.data.filename);
    else {
      yield call(updateCampaignInfo, data);
    }
  } catch (error) {
    yield call(returnErrorResponseAction, error);
  }
}

function* updateAppNotif(
  data: Object,
  notificationId?: string,
  image?: string,
) {
  /* eslint-disable no-param-reassign */
  if (image) data.image = image;
  else delete data.image;
  if (notificationId) {
    yield put(updateAppNotificationRequestAction(data, notificationId));
  } else {
    delete data.file;
    yield put(addAppNotificationRequestAction(data));
  }
  /* eslint-enable no-param-reassign */
}

function* updateAppNotificationFlow(action: Object) {
  try {
    let result = null;

    const { data, notificationId } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(updateAppNotificationReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      delete data.file;
    }

    if (notificationId) {
      if (result)
        yield call(
          updateAppNotif,
          data,
          notificationId,
          result.response.data.filename,
        );
      else {
        yield call(updateAppNotif, data, notificationId);
      }
    }

    if (result)
      yield call(updateAppNotif, data, null, result.response.data.filename);
    else {
      yield call(updateAppNotif, data);
    }
  } catch (error) {
    yield call(updateAppNotification, error);
  }
}

function* redirectRestaurantOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(push('/admin/restaurant/list'));
  }
  yield put(deleteRestaurantReducerAction(action.payload));
}

function* redirectCampaignOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(push('/admin/campaign/list'));
  }
  yield put(deleteCampaignReducerAction(action.payload));
}

function* redirectAppNotififcationOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(push('/admin/notification/list'));
  }
  yield put(deleteAppNotificationReducerAction(action.payload));
}

export default function* root(): Generator<*, *, *> {
  yield takeEvery(refreshUserAfterDeleteAction, refreshUserList);
  yield takeEvery(updateRestaurantAction, updateRestaurantFlow);
  yield takeEvery(deleteRestaurantAction, redirectRestaurantOnDeleteSuccess);
  yield takeEvery(addCampaign, updateCampaignFlow);
  yield takeEvery(updateCampaign, updateCampaignFlow);
  yield takeEvery(deleteCampaign, redirectCampaignOnDeleteSuccess);
  yield takeEvery(
    deleteAppNotification,
    redirectAppNotififcationOnDeleteSuccess,
  );
  yield takeEvery(addAppNotification, updateAppNotificationFlow);
  yield takeEvery(updateAppNotification, updateAppNotificationFlow);
}
