import React, { PureComponent } from 'react';

type Props = {};

class EditButtonIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.081 3.79668L15.2014 7.91705C15.2847 8.00068 15.3315 8.11391 15.3315 8.23194C15.3315 8.34998 15.2847 8.46321 15.2014 8.54683L5.22557 18.5227L0.986636 18.9969C0.854279 19.011 0.720455 18.9952 0.594964 18.9509C0.469474 18.9065 0.355497 18.8346 0.26138 18.7405C0.167263 18.6464 0.0953912 18.5324 0.0510282 18.4069C0.00666513 18.2814 -0.00906522 18.1476 0.00498895 18.0153L0.475415 13.77L10.45 3.79541C10.5336 3.71211 10.6468 3.66534 10.7649 3.66534C10.8829 3.66534 10.9961 3.71211 11.0798 3.79541L11.081 3.79668ZM18.4753 2.75129L16.2519 0.52155C15.9173 0.187573 15.4638 0 14.991 0C14.5183 0 14.0648 0.187573 13.7302 0.52155L12.1137 2.13936C12.0304 2.22298 11.9836 2.33621 11.9836 2.45425C11.9836 2.57228 12.0304 2.68551 12.1137 2.76914L16.234 6.88568C16.3177 6.96898 16.4309 7.01575 16.5489 7.01575C16.667 7.01575 16.7802 6.96898 16.8638 6.88568L18.4804 5.26915C18.8132 4.93469 19 4.48206 19 4.01022C19 3.53839 18.8132 3.08575 18.4804 2.75129H18.4753Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default EditButtonIcon;
