import * as yup from 'yup';

const FILE_SIZE = 512 * 1024;

const testFileSize = value => {
  try {
    if (value) return value.file.size <= FILE_SIZE;
    return true;
  } catch (err) {
    return false;
  }
};

export default yup.object().shape({
  title: yup.string().required('required'),
  description: yup.string().required('required'),
  deliveryDate: yup.date().required('required'),
  file: yup
    .mixed()
    .notRequired()
    .test('fileSize', 'imageErrorSize', testFileSize),
  image: yup.string(),
});
