import React, { PureComponent } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from 'react-google-maps';

type Props = {
  center?: Object,
  hasMarker?: boolean,
  zoom?: number,
  isMarkerDraggable?: boolean,
  form: Object,
};

class Map extends PureComponent<Props> {
  static defaultProps = {
    center: {},
    hasMarker: false,
    zoom: 4,
    isMarkerDraggable: false,
  };

  onMarkerDragEnd = (e: Object) => {
    this.props.form.setFieldValue('location', {
      _longitude: e.latLng.lng(),
      _latitude: e.latLng.lat(),
    });
  };

  render() {
    return (
      <GoogleMap defaultZoom={this.props.zoom} center={this.props.center}>
        {this.props.hasMarker && (
          <Marker
            position={this.props.center}
            onDragEnd={this.onMarkerDragEnd}
            draggable={this.props.isMarkerDraggable}
          />
        )}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(Map));
