/* eslint-disable no-underscore-dangle */
import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import queryString from 'qs';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import { Rating } from 'primereact/rating';
import { CommonModal } from '../../../components/common/modal';
import { Table } from '../../../components/common/table';
import { Paginator } from '../../../components/common/paginator';
import {
  fetchEvaluationsRequestAction,
  tableRequestAction,
} from '../store/restaurant.request';
import { tableQuerySelector } from '../store/restaurant.selector';

type State = {
  query: Object,
  currFilter: string,
  currFilterValue: string,
  isSortAsc: boolean,
  sortField: string | boolean,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class RestaurantEvaluationsPage extends PureComponent<*, State> {
  state = {
    query: {},
    currFilter: '',
    currFilterValue: '',
    sortField: '',
    isSortAsc: false,
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchQueriedEvals(
        `/restaurants/${uid}/evaluations?_limit=10&_page=1`,
      );
    }
  }

  componentDidUpdate(props: Object, nextState: Object) {
    /* eslint-disable-next-line react/no-did-update-set-state */
    if (nextState.isErrorModalOpen) this.setState({ isErrorModalOpen: false });
  }

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  getPaginationQuery = (pageNum: number) => {
    this.restaurantEvaluationsGenerator({ _page: pageNum });
  };

  restaurantEvaluationsGenerator = (queryData?: Object, field?: string) => {
    let uid = '';
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (
        !key.includes('_') &&
        key !== field &&
        field !== undefined &&
        !key.includes(field)
      )
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }

    const user = firebase.auth().currentUser;
    if (user) {
      /* eslint-disable-next-line prefer-destructuring */
      uid = user.uid;
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchQueriedEvals(
      `/restaurants/${uid}/evaluations?${queryString.stringify(
        this.state.query,
      )}`,
    );
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.restaurantEvaluationsGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string | Object, value?: any) => {
    let filterQuery;
    const { sortField } = this.state;

    if (typeof field === 'object') {
      if (field.target.id === 'createdAt') {
        filterQuery = {
          [`${field.target.id}FromTo`]: field.target.value
            ? `${moment(field.target.value).format('YYYY/MM/DD')}_${moment(
                field.target.value,
              ).format('YYYY/MM/DD')}`
            : undefined,
        };
      } else {
        filterQuery = {
          [`${field.target.id}`]: field.target.value,
        };
      }

      const currSortField = sortField === field.target.id ? sortField : '';
      this.setState({
        currFilterValue: field.target.value,
        currFilter: field.target.id,
        sortField: currSortField,
      });

      this.restaurantEvaluationsGenerator(filterQuery, field.target.id);
    } else {
      filterQuery = {
        [field]: value,
      };

      const currSortField = sortField === field ? sortField : '';
      this.setState({
        currFilterValue: filterQuery[field],
        currFilter: field,
        sortField: currSortField,
      });

      this.restaurantEvaluationsGenerator(filterQuery, field);
    }
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.restaurantEvaluationsGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.restaurantEvaluationsGenerator({ _page: this.state.currentPage + 1 });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.restaurantEvaluationsGenerator({ _page: this.state.currentPage - 1 });
  };

  reactTable: any;

  render() {
    const { t } = this.props;

    const evaluationCols = [
      {
        field: 'nickname',
        header: `${t('nickname')}`,
        className: 'nickname-width',
        sortable: true,
        filter: true,
        filterElement: ['nickname', ''],
      },
      {
        field: 'rating',
        header: `${t('rating')}`,
        className: 'rating-width',
        sortable: true,
        filter: true,
        filterElement: ['rating', ''],
        type: 'rating',
        body: (rowData: Object) => {
          return (
            <Rating
              readonly
              stars={5}
              value={Number(rowData.rating)}
              cancel={false}
            />
          );
        },
      },
      {
        field: 'createdAt',
        header: `${t('dateEvaluated')}`,
        className: 'date-eval-width',
        filterElement: ['createdAt', ''],
        sortable: true,
        filter: true,
        type: 'calendarTime',
        body: (rowData: Object) => {
          return (
            <div>{moment(rowData.createdAt).format('YYYY/MM/DD HH:mm')}</div>
          );
        },
      },
    ];
    return (
      <Fragment>
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <div className="restaurant-title">{t('evaluation')}</div>
        <div className="restaurant-main">
          <div className="table-container">
            <Table
              data={this.props.data}
              columns={evaluationCols}
              sortOrder={this.state.isSortAsc}
              currFilterValue={this.state.currFilterValue}
              currFilter={this.state.currFilter}
              onSort={this.getSortQuery}
              onFilter={this.getFilterQuery}
              loading={this.props.loading}
              sortField={this.state.sortField}
              emptyMessage={t('noEvaluationRecords')}
            />
            <Paginator
              currentPage={this.state.currentPage}
              goToFirstPage={this.goToFirstPage}
              goToNextPage={this.goToNextPage}
              goToPreviousPage={this.goToPreviousPage}
              isTableLoading={this.props.loading}
              isTableEmpty={this.props.data.length === 0}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => tableQuerySelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  fetchEvaluations: (restaurantId: string) =>
    dispatch(fetchEvaluationsRequestAction(restaurantId)),
  fetchQueriedEvals: (route: string) => dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantEvaluationsPage),
);
