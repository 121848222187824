import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
// import moment from 'moment';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import ProgressSpinner from '../../../components/primereact/misc';
import { IMAGE_URL } from '../../../config/config';
import './styles.scss';
import {
  fetchWhatToEatSelector,
  deleteWhatToEatSelector,
} from '../store/restaurant.selector';
import {
  fetchWhatToEatRequestAction,
  deleteWhatToEatRequestAction,
} from '../store/restaurant.request';

type State = {
  isSidebarOpen: boolean,
  isModalOpen: boolean,
  data: Object,
};

class RestaurantWhatToEatTodayDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state } };
    return { data: { ...state } };
  };

  state = {
    isSidebarOpen: false,
    isModalOpen: false,
    data: {},
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    const storeId = user ? user.uid : '';
    const { id } = this.props.location.state;

    this.props.fetchWhatToEat(storeId, id);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onClickToRedirect = (destination: string) => {
    this.props.history.push(destination, this.state.data);
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    const user = firebase.auth().currentUser;
    const storeId = user ? user.uid : '';

    const whatToEatId =
      this.props.whatToEatInfo &&
      this.props.whatToEatInfo.data &&
      this.props.whatToEatInfo.data.id;

    if (isDeleting) this.props.deleteWhatToEat(storeId, whatToEatId);
  };

  fileInput: any;

  render() {
    const { t } = this.props;

    return this.props.whatToEatInfo.loading ? (
      <div className="restaurant-spinner-container">
        <ProgressSpinner
          loadingText={t('loading')}
          className="restaurant-spinner"
        />
      </div>
    ) : (
      <>
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <div className="restaurant-title">
          <Button
            icon="pi pi-arrow-left"
            type="button"
            className="restaurant-button restaurant-button--icon"
            onClick={() => this.props.history.goBack()}
          />
          {t('viewWhatToEatToday')}
          <div className="pull-right">
            <Button
              label={t('edit')}
              type="button"
              className="restaurant-button restaurant-button--edit"
              onClick={() => this.onClickToRedirect('edit')}
            />
            <Button
              label={t('delete')}
              type="button"
              className="restaurant-button restaurant-button--delete"
              onClick={this.onDeleteClick}
            />
          </div>
        </div>
        <div className="restaurant-main">
          <div className="restaurant-main-inner">
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">{t('title')}</div>
                <div className="restaurant-form-group__data">
                  {this.props.whatToEatInfo &&
                    this.props.whatToEatInfo.data &&
                    this.props.whatToEatInfo.data.title}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('description')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.props.whatToEatInfo &&
                    this.props.whatToEatInfo.data &&
                    this.props.whatToEatInfo.data.description}
                </div>
              </div>
            </div>
            <div className="restaurant-column">
              <div className="restaurant-form-group restaurant-form-group--timer">
                <div>
                  <div className="restaurant-form-group__label">
                    {t('after')}
                  </div>
                  <div className="restaurant-form-group__data">
                    {this.props.whatToEatInfo &&
                      this.props.whatToEatInfo.data &&
                      this.props.whatToEatInfo.data.deliveryDelay}
                  </div>
                </div>
                <span>{t('daysAt')}</span>
                <div>
                  <div className="restaurant-form-group__label">
                    {t('time')}
                  </div>
                  <div className="restaurant-form-group__data">
                    {/* {moment(
                      this.props.whatToEatInfo &&
                        this.props.whatToEatInfo.data &&
                        this.props.whatToEatInfo.data.updatedAt,
                    ).format('HH:MM')} */}
                  </div>
                </div>
              </div>
              {this.props.whatToEatInfo &&
                this.props.whatToEatInfo.data &&
                this.props.whatToEatInfo.data.image && (
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('menuOptional')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <img
                        className="notificationDetailsLogo"
                        src={`${IMAGE_URL}${this.props.whatToEatInfo.data.image}`}
                        alt="..."
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  whatToEatInfo: fetchWhatToEatSelector(state),
  deleteWhatToEat: deleteWhatToEatSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  fetchWhatToEat: (storeId: string, whatToEatId: string) =>
    dispatch(fetchWhatToEatRequestAction(storeId, whatToEatId)),
  deleteWhatToEat: (storeId: string, whatToEatId: string) =>
    dispatch(deleteWhatToEatRequestAction(storeId, whatToEatId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantWhatToEatTodayDetails),
);
