import { call, all, spawn } from 'redux-saga/effects';
import requestSaga from './request/request.saga';
import adminSaga from '../containers/admin/store/admin.saga';
import restaurantSaga from '../containers/restaurant/store/restaurant.saga';

export default function* rootSaga(): Generator<void, void, void> {
  const sagas = [requestSaga, adminSaga, restaurantSaga];
  yield all(
    sagas.map(saga =>
      // eslint-disable-next-line func-names
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      }),
    ),
  );
}
