import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Map } from '../../../components/common/map';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import ProgressSpinner from '../../../components/primereact/misc';
import { defaultInitialValues } from '../adminRestaurantEdit/AdminRestaurantEdit';
import {
  deleteRestaurantRequestAction,
  fetchRestaurantRequestAction,
} from '../store/admin.request';
import {
  restaurantDeleteSelector,
  restaurantSelector,
} from '../store/admin.selector';
import { MAP_API_KEY } from '../../constants';
import { IMAGE_URL } from '../../../config/config';
import noImage from '../../../assets/images/noimage.png';
import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isModalOpen: boolean,
  data: Object,
  selectedId: number | null,
};

class AdminRestaurantDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state }, selectedId: state.id };
    return { data: { ...defaultInitialValues } };
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isModalOpen: false,
    data: null,
    selectedId: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.getWindowWidth);
    this.props.fetchRestaurant(this.props.location.state.id);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  }

  onClickToRedirect(destination: string) {
    this.props.history.push(destination, this.state.data);
  }

  getWindowWidth = () => this.setState({ currentWidth: window.innerWidth });

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    if (isDeleting) {
      this.props.deleteRestaurant(this.state.selectedId);
    }
  };

  renderYenSign = repeatCount => '¥'.repeat(repeatCount);

  render() {
    const { t, i18n } = this.props;

    return (
      <div className="restaurant-container">
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="restaurants"
          userType="admin"
        />
        <div
          className={
            this.state.isSidebarOpen || this.state.isModalOpen
              ? 'restaurantEditPageContainerUnscrollable'
              : 'restaurantEditPageContainer'
          }
        >
          <div>
            <Header onMenuClick={this.onMenuClick} />
            {this.props.fetchedRestaurant.loading ? (
              <div className="restaurant-spinner-container">
                <ProgressSpinner
                  loadingText={t('loading')}
                  className="restaurant-spinner"
                />
              </div>
            ) : (
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {t('viewStoreInfo')}
                <div className="pull-right">
                  <Button
                    label={t('edit')}
                    type="button"
                    className="restaurant-button restaurant-button--edit"
                    onClick={() => this.onClickToRedirect('edit')}
                  />
                  <Button
                    label={t('delete')}
                    type="button"
                    className="restaurant-button restaurant-button--delete"
                    onClick={this.onDeleteClick}
                  />
                </div>
              </div>
            )}
          </div>
          {!this.props.fetchedRestaurant.loading && (
            <div className="restaurant-main">
              <div className="restaurant-main-inner">
                <div className="restaurant-column">
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('name')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchedRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.name
                        ? this.props.fetchedRestaurant.data.name
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('description')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchedRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.description
                        ? this.props.fetchedRestaurant.data.description
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('cuisine')}
                    </div>
                    <div className="restaurant-form-group__data restaurant-form-group__data--cuisine">
                      {this.props.fetchedRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.cuisine
                        ? this.props.fetchedRestaurant.data.cuisine
                            .map(cuisine =>
                              i18n.language === 'ja' ? cuisine.ja : cuisine.en,
                            )
                            .join(', ')
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('usageRate')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchedRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.usageRate
                        ? `${this.props.fetchedRestaurant.data.usageRate}%`
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('budget')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.budget
                        ? this.renderYenSign(
                            this.props.fetchedRestaurant.data.budget,
                          )
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('location')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <div className="restaurant-map-wrapper">
                        <div className="restaurant-map">
                          <Map
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={
                              <div style={{ height: `100%` }} />
                            }
                            mapElement={<div style={{ height: `100%` }} />}
                            center={{
                              /* eslint-disable  no-underscore-dangle */
                              lat:
                                (this.props.fetchedRestaurant &&
                                  this.props.fetchedRestaurant.data &&
                                  this.props.fetchedRestaurant.data.location &&
                                  this.props.fetchedRestaurant.data.location
                                    ._latitude) ||
                                35.680167363485616,
                              lng:
                                (this.props.fetchedRestaurant &&
                                  this.props.fetchedRestaurant.data &&
                                  this.props.fetchedRestaurant.data.location &&
                                  this.props.fetchedRestaurant.data.location
                                    ._longitude) ||
                                139.76943969726562,
                              /* eslint-enable no-underscore-dangle */
                            }}
                            zoom={15}
                            hasMarker
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('address')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchedRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.address
                        ? this.props.fetchedRestaurant.data.address
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('telephoneNum')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchedRestaurant &&
                      this.props.fetchedRestaurant.data &&
                      this.props.fetchedRestaurant.data.telephoneNumber
                        ? this.props.fetchedRestaurant.data.telephoneNumber
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('homepageURL')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <a
                        href={
                          this.props.fetchedRestaurant &&
                          this.props.fetchedRestaurant.data &&
                          this.props.fetchedRestaurant.data.homepageUrl
                            ? this.props.fetchedRestaurant.data.homepageUrl
                            : '#'
                        }
                        className="restaurant-form-group__link"
                      >
                        {this.props.fetchedRestaurant &&
                        this.props.fetchedRestaurant.data &&
                        this.props.fetchedRestaurant.data.homepageUrl
                          ? this.props.fetchedRestaurant.data.homepageUrl
                          : ''}
                      </a>
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('email')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <span className="restaurant-form-group__link">
                        {this.props.fetchedRestaurant &&
                        this.props.fetchedRestaurant.data &&
                        this.props.fetchedRestaurant.data.email
                          ? this.props.fetchedRestaurant.data.email
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="restaurant-column">
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('googleMapURL')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <a
                        href={
                          this.props.fetchedRestaurant &&
                          this.props.fetchedRestaurant.data &&
                          this.props.fetchedRestaurant.data.googleMapsUrl
                            ? this.props.fetchedRestaurant.data.googleMapsUrl
                            : '#'
                        }
                        className="restaurant-form-group__link"
                      >
                        {this.props.fetchedRestaurant &&
                        this.props.fetchedRestaurant.data &&
                        this.props.fetchedRestaurant.data.googleMapsUrl
                          ? this.props.fetchedRestaurant.data.googleMapsUrl
                          : ''}
                      </a>
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('logo')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <div className="restaurant-image-wrapper">
                        {this.props.fetchedRestaurant &&
                        this.props.fetchedRestaurant.data &&
                        this.props.fetchedRestaurant.data.image ? (
                          <img
                            src={
                              this.state.data
                                ? `${IMAGE_URL}${this.props.fetchedRestaurant.data.image}`
                                : { noImage }
                            }
                            alt="..."
                          />
                        ) : (
                          <img src={noImage} alt="..." />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('operationHours')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <div className="restaurant-operation-hours">
                        {this.props.fetchedRestaurant &&
                        this.props.fetchedRestaurant.data &&
                        this.props.fetchedRestaurant.data.operationHours &&
                        this.props.fetchedRestaurant.data.operationHours
                          ? [
                              'monday',
                              'tuesday',
                              'wednesday',
                              'thursday',
                              'friday',
                              'saturday',
                              'sunday',
                            ].map(dayOfWeek => (
                              <div
                                key={dayOfWeek}
                                className="restaurant-operation-hours__row"
                              >
                                <div className="restaurant-operation-hours__day">
                                  {t(`${dayOfWeek}`)}
                                </div>
                                <div
                                  className={
                                    this.props.fetchedRestaurant &&
                                    this.props.fetchedRestaurant.data &&
                                    this.props.fetchedRestaurant.data
                                      .operationHours &&
                                    this.props.fetchedRestaurant.data
                                      .operationHours[dayOfWeek].closed !==
                                      'Closed'
                                      ? 'restaurant-operation-hours__time'
                                      : 'restaurant-operation-hours__closed'
                                  }
                                >
                                  {this.props.fetchedRestaurant.data
                                    .operationHours[dayOfWeek].closed
                                    ? `${t('closed')}`
                                    : `${moment(
                                        this.props.fetchedRestaurant &&
                                          this.props.fetchedRestaurant.data &&
                                          this.props.fetchedRestaurant.data
                                            .operationHours &&
                                          this.props.fetchedRestaurant.data
                                            .operationHours[dayOfWeek].from,
                                        'HH:mm',
                                      ).format('hh:mm A')} - ${moment(
                                        this.props.fetchedRestaurant &&
                                          this.props.fetchedRestaurant.data &&
                                          this.props.fetchedRestaurant.data
                                            .operationHours &&
                                          this.props.fetchedRestaurant.data
                                            .operationHours[dayOfWeek].to,
                                        'HH:mm',
                                      ).format('hh:mm A')}`}
                                </div>
                              </div>
                            ))
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Object) => ({
  deleteRestaurant: (id: string) => dispatch(deleteRestaurantRequestAction(id)),
  fetchRestaurant: (id: string) => dispatch(fetchRestaurantRequestAction(id)),
});

const mapStateToProps = (state: Object) => ({
  deleted: restaurantDeleteSelector(state),
  fetchedRestaurant: restaurantSelector(state),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminRestaurantDetails),
);
