import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { CommonModal } from '../../../components/common/modal';
import Button from '../../../components/primereact/buttons';
import noImage from '../../../assets/images/noimage.png';
import ProgressSpinner from '../../../components/primereact/misc';
import { defaultInitialValues } from '../restaurantMenusEdit/RestaurantMenusEdit';
import {
  deleteMenuRequestAction,
  fetchMenuItemAction,
} from '../store/restaurant.request';
import {
  menuDeleteSelector,
  menuItemSelector,
} from '../store/restaurant.selector';
import { IMAGE_URL } from '../../../config/config';
import { currencyFormatter } from '../../../utils/data.util';

type State = {
  isModalOpen: boolean,
  data: Object,
  menuId: string | null,
};

class RestaurantMenusDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state }, menuId: state.id };
    return { data: { ...defaultInitialValues } };
  };

  state = {
    isModalOpen: false,
    data: null,
    menuId: null,
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchSpecificMenu(uid, this.state.menuId);
    }
  }

  onClickToRedirect(destination: string) {
    this.props.history.push(destination, this.state.data);
  }

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      if (isDeleting) {
        this.props.deleteMenu(uid, this.state.menuId);
      }
    }
  };

  render() {
    const { t } = this.props;

    return this.props.specificMenuSelector.loading ? (
      <div className="restaurant-spinner-container">
        <ProgressSpinner
          loadingText={t('loading')}
          className="restaurant-spinner"
        />
      </div>
    ) : (
      <Fragment>
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <div className="restaurant-title">
          <Button
            icon="pi pi-arrow-left"
            type="button"
            className="restaurant-button restaurant-button--icon"
            onClick={() => this.props.history.goBack()}
          />
          {t('viewMenu')}
          <div className="pull-right">
            <Button
              label={t('edit')}
              type="button"
              className="restaurant-button restaurant-button--edit"
              onClick={() => this.onClickToRedirect('edit')}
            />
            <Button
              label={t('delete')}
              type="button"
              className="restaurant-button restaurant-button--delete"
              onClick={this.onDeleteClick}
            />
          </div>
        </div>
        <div className="restaurant-main">
          <div className="restaurant-main-inner">
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">{t('name')}</div>
                <div className="restaurant-form-group__data">
                  {this.props.specificMenuSelector.data &&
                    this.props.specificMenuSelector.data[this.state.menuId] &&
                    this.props.specificMenuSelector.data[this.state.menuId]
                      .name}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">
                  {t('description')}
                </div>
                <div className="restaurant-form-group__data">
                  {this.props.specificMenuSelector.data &&
                    this.props.specificMenuSelector.data[this.state.menuId] &&
                    this.props.specificMenuSelector.data[this.state.menuId]
                      .description}
                </div>
              </div>
            </div>
            <div className="restaurant-column">
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">{t('price')}</div>
                <div className="restaurant-form-group__data">
                  {this.props.specificMenuSelector.data &&
                    this.props.specificMenuSelector.data[this.state.menuId] &&
                    currencyFormatter(
                      `${t('locale')}`,
                      'currency',
                      `${t('currency')}`,
                    ).format(
                      this.props.specificMenuSelector.data[this.state.menuId]
                        .price,
                    )}
                </div>
              </div>
              <div className="restaurant-form-group">
                <div className="restaurant-form-group__label">{t('photo')}</div>
                <div className="restaurant-form-group__data">
                  <div className="restaurant-image-wrapper">
                    {this.props.specificMenuSelector.data &&
                    this.props.specificMenuSelector.data[this.state.menuId] &&
                    this.props.specificMenuSelector.data[this.state.menuId]
                      .photo !== '' ? (
                      // eslint-disable-next-line react/jsx-indent
                      <img
                        src={
                          this.state.data
                            ? `${IMAGE_URL}${this.props.specificMenuSelector.data[this.state.menuId].photo}`
                            : ''
                        }
                        alt={noImage}
                      />
                    ) : (
                      <img src={noImage} alt="..." />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  deleteSelector: menuDeleteSelector(state),
  specificMenuSelector: menuItemSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  deleteMenu: (restaurantId: string, menuId: string) =>
    dispatch(deleteMenuRequestAction(restaurantId, menuId)),
  fetchSpecificMenu: (restaurantId: string, menuId: string) =>
    dispatch(fetchMenuItemAction(restaurantId, menuId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantMenusDetails),
);
