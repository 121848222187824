import React from 'react';
import { Formik } from 'formik';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../common/input';
import { Button } from '../../common/button';
import { Logo } from '../../../assets/svg';
import LoginFormSchema from './LoginForm.validation';
import { ErrorMessage } from '../../../containers/errors';

const LoginForm: Function = React.memo(({ handleSubmit }: Object) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginFormSchema}
      onSubmit={handleSubmit}
      render={formikProps => (
        <form className="login">
          <div className="login-title">
            <div className="login-logo-container">
              <Logo />
            </div>
            {t('loginToDashboard')}
          </div>
          <div className="login-form-container">
            <div className="login-form">
              <div className="login-field-container">
                <InputField
                  label={t('emailAddress')}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  type="email"
                  name="email"
                  value={formikProps.values.email}
                  error={
                    formikProps.errors &&
                    t(formikProps.errors.email) &&
                    formikProps.touched.email &&
                    t(formikProps.errors.email)
                  }
                />
              </div>
              <div className="login-field-container">
                <InputField
                  label={t('password')}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.password}
                  type="password"
                  name="password"
                  error={
                    formikProps.errors &&
                    t(formikProps.errors.password) &&
                    formikProps.touched.password &&
                    t(formikProps.errors.password)
                  }
                />
              </div>
              <ErrorMessage />
              <Button
                type="submit"
                disabled={!formikProps.isValid}
                onClick={formikProps.handleSubmit}
                className="login-button"
                label={t('login')}
              />
              {/*
                TODO: Removed, for the new changes in admin.
                <span className="login-link">{t('forgotPass')}</span>
              */}
            </div>
          </div>
        </form>
      )}
    />
  );
});

export default LoginForm;
