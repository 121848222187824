import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import { RestaurantInformationDetails } from '../restaurantInformationDetails';
import { RestaurantInformationEdit } from '../restaurantInformationEdit';
import { RestaurantEvaluationsPage } from '../restaurantEvaluationsPage';
import { RestaurantMenusPage } from '../restaurantMenusPage';
import { RestaurantMenusDetails } from '../restaurantMenusDetails';
import { RestaurantMenusEdit } from '../restaurantMenusEdit';
import { RestaurantUsageHistoriesPage } from '../restaurantUsageHistoriesPage';
import { RestaurantPrintQRCodePage } from '../restaurantPrintQRCodePage';
import { RestaurantCampaignPage } from '../restaurantCampaignPage';
import { RestaurantCampaignDetails } from '../restaurantCampaignDetails';
import { RestaurantCampaignEdit } from '../restaurantCampaignEdit';
import { RestaurantWhatToEatTodayPage } from '../restaurantWhatToEatTodayPage';
import { RestaurantWhatToEatTodayDetails } from '../restaurantWhatToEatTodayDetails';
import { RestaurantWhatToEatTodayEdit } from '../restaurantWhatToEatTodayEdit';
import { RestaurantHiringPage } from '../restaurantHiringPage';
import { RestaurantHiringDetails } from '../restaurantHiringDetails';
import { RestaurantHiringEdit } from '../restaurantHiringEdit';
import { RestaurantContactWorldGourmetPage } from '../restaurantContactWorldGourmetPage';
import {
  fetchStoreNameRequestAction,
  fetchStoreBalanceRequestAction,
} from '../store/restaurant.request';
import {
  storeNameSelector,
  storeBalanceSelector,
} from '../store/restaurant.selector';
import noImage from '../../../assets/images/noimage.png';
import { IMAGE_URL } from '../../../config/config';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  currentBalance: number,
};

class RestaurantContainer extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    currentBalance: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.getWindowWidth);

    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      if (uid) {
        this.props.fetchStoreName(uid);
        this.props.fetchStoreBalance(uid);
      }
    }
  }

  componentDidUpdate() {
    if (
      this.props.storeBalance &&
      this.props.storeBalance.data &&
      this.props.storeBalance.data.active
    ) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        currentBalance: this.props.storeBalance.data.active,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  getWindowWidth = () => this.setState({ currentWidth: window.innerWidth });

  render() {
    const { t } = this.props;

    return (
      <div className="restaurant-container">
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          userType="restaurant"
          availableActiveCoupons={
            this.props.storeBalance &&
            this.props.storeBalance.data &&
            this.props.storeBalance.data.active
              ? this.props.storeBalance.data.active
              : this.state.currentBalance
          }
          isInRestaurantSubMenu={
            (this.props.match &&
              this.props.location &&
              this.props.location.pathname.includes('/store/information/')) ||
            this.props.location.pathname.includes('/store/evaluation') ||
            this.props.location.pathname.includes('/store/menu/')
          }
          isInCouponsSubMenu={
            (this.props.match &&
              this.props.location &&
              this.props.location.pathname.includes(
                '/store/usage-histories',
              )) ||
            this.props.location.pathname.includes('/store/print-qr-code')
          }
          isInInformationSubMenu={
            (this.props.match &&
              this.props.location &&
              this.props.location.pathname.includes('/store/campaign/')) ||
            this.props.location.pathname.includes(
              '/store/what-to-eat-today/',
            ) ||
            this.props.location.pathname.includes('/store/hiring/') ||
            this.props.location.pathname.includes('/store/contact')
          }
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'restaurantEditPageContainerUnscrollable'
              : 'restaurantEditPageContainer'
          }
        >
          <Header
            onMenuClick={this.onMenuClick}
            storeName={
              this.props.storeName &&
              this.props.storeName.data &&
              this.props.storeName.data.name
                ? this.props.storeName.data.name
                : `${t('store')}`
            }
            storeImage={
              this.props.storeName &&
              this.props.storeName.data &&
              this.props.storeName.data.image ? (
                <img
                  src={
                    this.props.storeName.data
                      ? `${IMAGE_URL}${this.props.storeName.data.image}`
                      : { noImage }
                  }
                  alt={this.props.storeName.data.image}
                  className="image-container"
                />
              ) : (
                <img src={noImage} alt="..." className="image-container" />
              )
            }
          />
          <Switch>
            <Route
              exact
              path="/store/information/details"
              component={RestaurantInformationDetails}
            />
            <Route
              exact
              path="/store/information/edit"
              component={RestaurantInformationEdit}
            />
            <Route
              exact
              path="/store/evaluation/list"
              component={RestaurantEvaluationsPage}
            />
            <Route
              exact
              path="/store/menu/list"
              component={RestaurantMenusPage}
            />
            <Route
              exact
              path="/store/menu/details"
              component={RestaurantMenusDetails}
            />
            <Route
              exact
              path="/store/menu/edit"
              component={RestaurantMenusEdit}
            />
            <Route
              exact
              path="/store/menu/add"
              render={props => <RestaurantMenusEdit {...props} isAdd />}
            />
            <Route
              exact
              path="/store/usage-histories/list"
              render={props => (
                <RestaurantUsageHistoriesPage
                  {...props}
                  usageRate={
                    this.props.storeName &&
                    this.props.storeName.data &&
                    this.props.storeName.data.usageRate
                      ? this.props.storeName.data.usageRate
                      : 0
                  }
                />
              )}
            />
            <Route
              exact
              path="/store/print-qr-code/details"
              component={RestaurantPrintQRCodePage}
            />
            <Route
              exact
              path="/store/campaign/list"
              component={RestaurantCampaignPage}
            />
            <Route
              exact
              path="/store/campaign/details"
              component={RestaurantCampaignDetails}
            />
            <Route
              exact
              path="/store/campaign/edit"
              render={props => <RestaurantCampaignEdit {...props} />}
            />
            <Route
              exact
              path="/store/campaign/add"
              render={props => (
                <RestaurantCampaignEdit
                  {...props}
                  availableActiveCoupons={
                    this.props.storeBalance &&
                    this.props.storeBalance.data &&
                    this.props.storeBalance.data.active
                      ? this.props.storeBalance.data.active
                      : 0
                  }
                  isAdd
                />
              )}
            />
            <Route
              exact
              path="/store/what-to-eat-today/list"
              component={RestaurantWhatToEatTodayPage}
            />
            <Route
              exact
              path="/store/what-to-eat-today/details"
              component={RestaurantWhatToEatTodayDetails}
            />
            <Route
              exact
              path="/store/what-to-eat-today/edit"
              render={props => <RestaurantWhatToEatTodayEdit {...props} />}
            />
            <Route
              exact
              path="/store/what-to-eat-today/add"
              render={props => (
                <RestaurantWhatToEatTodayEdit {...props} isAdd />
              )}
            />
            <Route
              exact
              path="/store/hiring/list"
              component={RestaurantHiringPage}
            />
            <Route
              exact
              path="/store/hiring/details"
              component={RestaurantHiringDetails}
            />
            <Route
              exact
              path="/store/hiring/edit"
              render={props => <RestaurantHiringEdit {...props} />}
            />
            <Route
              exact
              path="/store/hiring/add"
              render={props => <RestaurantHiringEdit {...props} isAdd />}
            />
            <Route
              exact
              path="/store/contact"
              render={props => (
                <RestaurantContactWorldGourmetPage
                  {...props}
                  email={
                    this.props.storeName &&
                    this.props.storeName.data &&
                    this.props.storeName.data.email
                      ? this.props.storeName.data.email
                      : 0
                  }
                  isAdd
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  storeName: storeNameSelector(state),
  storeBalance: storeBalanceSelector(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchStoreName: (restaurantId: string) =>
    dispatch(fetchStoreNameRequestAction(restaurantId)),
  fetchStoreBalance: (restaurantId: string) =>
    dispatch(fetchStoreBalanceRequestAction(restaurantId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantContainer),
);
