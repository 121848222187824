import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class Coupon extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={this.props.className}
          d="M23.2969 0H7.08961V2.10938C7.08961 2.49802 6.77512 2.8125 6.38648 2.8125C5.99784 2.8125 5.68336 2.49802 5.68336 2.10938V0H0.703125C0.314484 0 0 0.314484 0 0.703125V4.92188C0 5.31052 0.314484 5.625 0.703125 5.625C1.86628 5.625 2.8125 6.57122 2.8125 7.73438C2.8125 8.89753 1.86628 9.84375 0.703125 9.84375C0.314484 9.84375 0 10.1582 0 10.5469V14.7656C0 15.1543 0.314484 15.4688 0.703125 15.4688H5.625V13.3594C5.625 12.9707 5.93948 12.6562 6.32812 12.6562C6.71677 12.6562 7.03125 12.9707 7.03125 13.3594V15.4688H23.2969C23.6855 15.4688 24 15.1543 24 14.7656V0.703125C24 0.314484 23.6855 0 23.2969 0ZM7.03125 10.5469C7.03125 10.9355 6.71677 11.25 6.32812 11.25C5.93948 11.25 5.625 10.9355 5.625 10.5469V9.14062C5.625 8.75198 5.93948 8.4375 6.32812 8.4375C6.71677 8.4375 7.03125 8.75198 7.03125 9.14062V10.5469ZM7.03125 6.32812C7.03125 6.71677 6.71677 7.03125 6.32812 7.03125C5.93948 7.03125 5.625 6.71677 5.625 6.32812V4.92188C5.625 4.53323 5.93948 4.21875 6.32812 4.21875C6.71677 4.21875 7.03125 4.53323 7.03125 4.92188V6.32812ZM11.3438 4.92188C11.3438 3.75872 12.29 2.8125 13.4531 2.8125C14.6163 2.8125 15.5625 3.75872 15.5625 4.92188C15.5625 6.08503 14.6163 7.03125 13.4531 7.03125C12.29 7.03125 11.3438 6.08503 11.3438 4.92188ZM13.0631 12.5381C12.7397 12.3225 12.6525 11.8865 12.8681 11.5631L18.4931 3.12558C18.708 2.80219 19.1441 2.71566 19.4682 2.93058C19.7916 3.1462 19.8788 3.58219 19.6632 3.90563L14.0382 12.3431C13.8261 12.6617 13.3917 12.7559 13.0631 12.5381ZM19.0781 12.6562C17.915 12.6562 16.9688 11.71 16.9688 10.5469C16.9688 9.38372 17.915 8.4375 19.0781 8.4375C20.2413 8.4375 21.1875 9.38372 21.1875 10.5469C21.1875 11.71 20.2413 12.6562 19.0781 12.6562Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default Coupon;
