import React, { PureComponent } from 'react';

class EmptyRecord extends PureComponent<*> {
  render() {
    return (
      <svg
        width="83"
        height="83"
        viewBox="0 0 83 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.5" width="83" height="16.9388" rx="2" fill="#FCAE17" />
        <rect
          opacity="0.5"
          y="25.4081"
          width="83"
          height="16.9388"
          rx="2"
          fill="#FCAE17"
        />
        <rect
          opacity="0.5"
          y="51.6633"
          width="83"
          height="16.0918"
          rx="2"
          fill="#FCAE17"
        />
        <path
          d="M77.3375 67.3662L77.3374 67.366L68.5445 58.5733C70.099 55.5232 70.978 52.0697 70.978 48.4147C70.978 36.0345 60.942 25.9985 48.562 25.9985C36.182 25.9985 26.146 36.0345 26.146 48.4145C26.146 60.7942 36.1818 70.8305 48.5618 70.8305C52.217 70.8305 55.6704 69.9514 58.7205 68.3969L67.5134 77.1899L67.5135 77.19C70.2264 79.9025 74.6243 79.9023 77.3371 77.1901L77.3375 77.1897C80.05 74.477 80.05 70.0789 77.3375 67.3662ZM48.562 58.0996C43.2128 58.0996 38.8767 53.7634 38.8767 48.4145C38.8767 43.0654 43.213 38.7292 48.562 38.7292C53.9107 38.7292 58.2471 43.0655 58.2471 48.4145C58.2471 53.7634 53.9108 58.0996 48.562 58.0996Z"
          fill="#E7251E"
          stroke="#F1F1F7"
          strokeWidth="6"
        />
        <circle cx="48.2021" cy="48.0546" r="14.085" fill="#E7251E" />
        <rect
          x="38.2598"
          y="44.7405"
          width="19.8846"
          height="6.62822"
          fill="white"
        />
      </svg>
    );
  }
}

export default EmptyRecord;
