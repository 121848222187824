import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from '../../../components/primereact/inputs';
import { fetchCuisinesRequestAction } from '../store/admin.request';
import { cuisinesSelector } from '../store/admin.selector';

type Props = {
  fetchCuisines: Function,
  data: Object,
  loading: boolean,
  value: Array<string>,
  t: Function,
  i18n: Function,
  onChange: Function,
  disabled: boolean,
};

class CuisineMultiSelect extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchCuisines();
  }

  render() {
    const { t, i18n } = this.props;

    const capitalizeFirstLetter = string =>
      string.charAt(0).toUpperCase() + string.slice(1);

    return (
      <Fragment>
        <MultiSelect
          id="cuisine"
          name="cuisine"
          className="restaurant-form-input restaurant-form-input--multiselect"
          placeholder={t('selectACuisine')}
          options={
            this.props.data
              ? this.props.data.cuisines.map(cuisine => ({
                  label:
                    i18n.language === 'ja'
                      ? cuisine.ja
                      : capitalizeFirstLetter(cuisine.en),
                  value: cuisine,
                }))
              : []
          }
          value={this.props.loading ? [] : this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          filter
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => cuisinesSelector(state);

const mapDispatchToProps = (dispatch: Function) => ({
  fetchCuisines: () => dispatch(fetchCuisinesRequestAction()),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CuisineMultiSelect),
);
