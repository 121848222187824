import React, { PureComponent } from 'react';
import type { ChildrenArray, Node } from 'react';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  disabled?: boolean,
  onClick?: Function,
  className?: string,
  label?: string,
  disabled?: boolean,
  type?: 'submit' | 'button',
  children?: ChildrenArray<Node>,
};

class Button extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    onClick: null,
    className: '',
    label: '',
    type: 'button',
    children: <div />,
  };

  render() {
    const { onClick, className, label, disabled, type, children } = this.props;
    const classname = classNames(
      'button',
      { 'button-active': !disabled },
      className,
    );
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={classname}
        type={type}
      >
        {label || children}
      </button>
    );
  }
}

export default Button;
