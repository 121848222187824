import React, { PureComponent } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Growl } from 'primereact/growl';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
  Calendar,
} from '../../../components/primereact/inputs';
import { CommonModal } from '../../../components/common/modal';
import { FileInput } from '../../../components/common/input';
import ProgressSpinner from '../../../components/primereact/misc';
import campaignSchema from './adminCampaign.validation';
import { IMAGE_URL } from '../../constants';
import { CalendarIcon } from '../../../assets/svg';
import noImage from '../../../assets/images/noimage.png';
import { updateCampaign } from '../store/admin.request';
import { campaignUpdateSelector } from '../store/admin.selector';
import './styles.scss';

type State = {
  initialValues: Object,
  isDimensionAccepted: boolean,
  campaignId: string | null,
  isShowMessage: boolean,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
  isSidebarOpen: boolean,
  currentWidth: number,
};

export const defaultInitialValues = {
  title: '',
  description: '',
  deliveryDate: '',
  file: null,
  image: '',
};

class AdminCampaignEdit extends PureComponent<*, State> {
  static buildEditableData = (data: Object) => {
    const editData = { ...data };
    return {
      title: editData.title,
      description: editData.description,
      deliveryDate: editData.deliveryDate
        ? new Date(editData.deliveryDate)
        : '',
      image: editData.image ? `${IMAGE_URL}${editData.image}` : noImage,
    };
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    let data;
    let campaignId;
    Object.keys(nextProps).forEach((key: string) => {
      if (key === 'location') {
        const { state } = nextProps.location;
        if (state) {
          data = this.buildEditableData(state);
          campaignId = state.id;
        }
      }
    });
    if (data) return { initialValues: { ...data }, campaignId };
    return { initialValues: { ...defaultInitialValues }, campaignId };
  };

  state = {
    isDimensionAccepted: true,
    isShowMessage: false,
    initialValues: null,
    isDisconnected: false,
    isErrorModalOpen: false,
    campaignId: null,
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});

    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  checkDimensionValidity = (status: boolean) => {
    this.setState({
      isDimensionAccepted: status,
    });
  };

  submitForm = (data: Object) => {
    this.props.updateStoreCampaign(
      {
        data,
        campaignId: this.state.campaignId,
      },
      this.state.campaignId,
    );

    this.setState({
      isShowMessage: true,
    });
  };

  validState = (props: Object) => {
    if (props.isValid && this.state.isDimensionAccepted) return false;
    return true;
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: `${t('toastSuccessSummmary')}`,
      detail: `${t('toastSuccessDetail')}`,
    });
  };

  showError = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'error',
      summary: `${t('toastErrorSummary')}`,
      detail: `${t('toastErrorDetail')}`,
    });
  };

  growl: any;

  render() {
    const { t } = this.props;

    return (
      <div className="restaurant-container">
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="campaign"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'restaurantEditPageContainerUnscrollable'
              : 'restaurantEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.submitForm}
            validationSchema={campaignSchema}
            render={props => (
              <Form>
                <div className="restaurant-title">
                  <Button
                    icon="pi pi-arrow-left"
                    type="button"
                    className="restaurant-button restaurant-button--icon"
                    onClick={() => this.props.history.goBack()}
                  />
                  {t('editCampaign')}
                  <div className="pull-right">
                    <Button
                      label={t('save')}
                      type="submit"
                      className={
                        this.props.loading
                          ? 'restaurant-button restaurant-button--save transparent-text'
                          : 'restaurant-button restaurant-button--save'
                      }
                      disabled={this.validState(props) || this.props.loading}
                    />
                    {this.props.loading && (
                      <ProgressSpinner
                        loadingText=""
                        className="restaurant-spinner restaurant-spinner--button"
                      />
                    )}
                  </div>
                </div>
                <fieldset
                  className="restaurant-fieldset"
                  disabled={this.props.loading}
                >
                  <div className="restaurant-main">
                    <div className="restaurant-main-inner">
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('title')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputText
                              id="title"
                              name="title"
                              type="text"
                              className="restaurant-form-input"
                              value={
                                props.values.title ? props.values.title : ''
                              }
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <ErrorMessage name="title">
                              {msg => (
                                <div className="restaurant-form-error">
                                  {t(`${msg}`)}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('description')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputTextarea
                              id="description"
                              name="description"
                              className="restaurant-form-input"
                              value={
                                props.values.description
                                  ? props.values.description
                                  : ''
                              }
                              rows={5}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <ErrorMessage name="description">
                              {msg => (
                                <div className="restaurant-form-error">
                                  {t(`${msg}`)}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('deliveryDate')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <div
                              className="calendar-container"
                              style={
                                this.props.loading
                                  ? { opacity: '0.5' }
                                  : { opacity: 'initial' }
                              }
                            >
                              <Calendar
                                id="deliveryDate"
                                name="deliveryDate"
                                value={
                                  props.values.deliveryDate
                                    ? props.values.deliveryDate
                                    : ''
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                showTime
                                readOnlyInput
                              />
                              <span className="restaurantCalendarIcon">
                                <CalendarIcon />
                              </span>
                            </div>
                            <ErrorMessage name="deliveryDate">
                              {msg => (
                                <div className="restaurant-form-error">
                                  {t(`${msg}`)}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('photoOptional')}
                          </div>
                          <div className="restaurant-form-group__data restaurant-form-group__data--fileupload">
                            <div
                              style={
                                this.props.loading
                                  ? { opacity: '0.5' }
                                  : { opacity: 'initial' }
                              }
                            >
                              <Field
                                id="image"
                                name="image"
                                component={FileInput}
                                checkDimensionValidity={
                                  this.checkDimensionValidity
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => campaignUpdateSelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  updateStoreCampaign: (payload: Object, campaignId: string) =>
    dispatch(updateCampaign(payload, campaignId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminCampaignEdit),
);
