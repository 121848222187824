import React from 'react';
import { InputTextarea as PrimeReactInputTextarea } from 'primereact/inputtextarea';
import PropTypes from 'prop-types';

const InputTextarea = (props: Object) => {
  const {
    id,
    name,
    className,
    placeholder,
    value,
    onChange,
    onBlur,
    rows,
    autoResize,
    readOnly,
    disabled,
  } = props;
  return (
    <PrimeReactInputTextarea
      id={id}
      name={name}
      className={className}
      placeholder={placeholder}
      value={value}
      rows={rows}
      onChange={onChange}
      onBlur={onBlur}
      autoResize={autoResize}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

InputTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  autoResize: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

InputTextarea.defaultProps = {
  className: '',
  placeholder: '',
  autoResize: false,
  readOnly: false,
  disabled: false,
};

export default InputTextarea;
