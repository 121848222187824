import React, { PureComponent } from 'react';

type Props = {};

class Info extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5251 5.47842C19.5414 3.7932 18.2071 2.4589 16.5218 1.47527C14.8363 0.491689 12.9962 0 11.0003 0C9.00462 0 7.16404 0.491689 5.47877 1.47527C3.7934 2.45875 2.4591 3.79305 1.47542 5.47842C0.491689 7.16389 0 9.00447 0 11C0 12.9957 0.49184 14.836 1.47527 16.5215C2.4589 18.2068 3.7932 19.5411 5.47857 20.5248C7.16404 21.5083 9.00447 22 11.0001 22C12.9958 22 14.8364 21.5083 16.5218 20.5248C18.2071 19.5414 19.5413 18.2068 20.5249 16.5215C21.5083 14.8361 22 12.9956 22 11C22 9.00427 21.5083 7.16363 20.5251 5.47842ZM9.16691 2.74982C9.16691 2.61607 9.20981 2.50636 9.29584 2.42047C9.38173 2.33463 9.49165 2.29154 9.62529 2.29154H12.3755C12.509 2.29154 12.6189 2.33463 12.7046 2.42047C12.7904 2.50636 12.8337 2.61607 12.8337 2.74982V5.04156C12.8337 5.1752 12.7904 5.28507 12.7046 5.37086C12.6189 5.45674 12.5091 5.49969 12.3755 5.49969H9.62529C9.49165 5.49969 9.38178 5.45674 9.29584 5.37086C9.20981 5.28512 9.16691 5.1752 9.16691 5.04156V2.74982ZM14.6669 17.875C14.6669 18.0086 14.6236 18.1185 14.5379 18.2042C14.4524 18.2902 14.3425 18.3331 14.2087 18.3331H7.79183C7.65803 18.3331 7.54832 18.2902 7.46238 18.2042C7.37649 18.1185 7.33355 18.0086 7.33355 17.8749V15.5832C7.33355 15.4492 7.37649 15.3397 7.46238 15.2537C7.54832 15.168 7.65818 15.1248 7.79183 15.1248H9.16691V10.5416H7.79183C7.65803 10.5416 7.54837 10.4987 7.46238 10.4128C7.37649 10.3269 7.33355 10.2171 7.33355 10.0833V7.79173C7.33355 7.65798 7.37649 7.54827 7.46238 7.46228C7.54842 7.37634 7.65828 7.33345 7.79183 7.33345H12.3755C12.509 7.33345 12.6189 7.37634 12.7046 7.46228C12.7904 7.54827 12.8337 7.65798 12.8337 7.79173V15.1251H14.2083C14.3422 15.1251 14.4521 15.168 14.5379 15.2539C14.6234 15.3397 14.6667 15.4496 14.6667 15.5833V17.8751H14.6669V17.875Z"
          fill="black"
        />
      </svg>
    );
  }
}

export default Info;
