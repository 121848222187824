import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import adminReducer from '../containers/admin/store/admin.reducer';
import restaurantReducer from '../containers/restaurant/store/restaurant.reducer';

export default (history: Object) => {
  const reducers = {
    adminStore: adminReducer,
    restaurantStore: restaurantReducer,
    router: connectRouter(history),
  };
  return combineReducers(reducers);
};
