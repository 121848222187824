import React, { PureComponent } from 'react';

type Props = {};

class AdminIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#E7251E"
        />
        <path
          d="M13.7503 17C13.48 17 13.2396 16.9167 13.0573 16.7591C12.9403 16.6646 12.8438 16.5417 12.7772 16.4014L12.7633 16.3679L11.5048 12.8191L10.2346 16.3882C10.1509 16.5691 10.0232 16.7175 9.85477 16.8272C9.6799 16.9411 9.47713 16.999 9.25397 16.999C8.98254 16.999 8.74866 16.9197 8.55984 16.7622C8.44289 16.6717 8.34419 16.5437 8.2766 16.3913L8.26695 16.3669L6.05579 10.1545C6.01824 10.0407 6 9.94309 6 9.85061C6 9.60569 6.11158 9.38923 6.32293 9.22561C6.5139 9.07622 6.74456 9.00102 7.00741 9.00102C7.27026 9.00102 7.50093 9.07622 7.69189 9.22561C7.80991 9.32215 7.89252 9.42683 7.94616 9.54268L7.95904 9.57419L9.25183 13.1992L10.5393 9.57927C10.5779 9.46037 10.6787 9.28252 10.9233 9.14533C11.0939 9.0498 11.2892 9.00102 11.5027 9.00102C11.6915 9.00102 11.8717 9.0437 12.0391 9.12805C12.2301 9.22561 12.3717 9.37297 12.4479 9.55589L12.4543 9.57317L13.7482 13.2012L15.0528 9.55691C15.1043 9.43191 15.1922 9.32012 15.3135 9.22459C15.5055 9.0752 15.7351 9 15.998 9C16.2619 9 16.4915 9.07622 16.6824 9.22561C16.8906 9.39024 17 9.60569 17 9.84959L16.9989 9.87093C16.9936 9.96341 16.9732 10.0589 16.9388 10.1565L14.7277 16.3862C14.644 16.5681 14.5153 16.7154 14.3468 16.8262C14.1752 16.9421 13.9735 17 13.7503 17Z"
          fill="white"
        />
        <path
          d="M12.0166 21C11.3635 21 10.706 20.9282 10.054 20.7834C7.70823 20.2642 5.70424 18.8622 4.41325 16.8353C3.12116 14.8085 2.69663 12.4007 3.21586 10.055C3.73509 7.70925 5.13712 5.70529 7.16396 4.41322C9.18971 3.12116 11.5986 2.69664 13.9444 3.21586C14.9197 3.43139 15.8428 3.80257 16.6875 4.31852C16.8138 4.39581 16.94 4.47745 17.0641 4.56126C17.1447 4.61569 17.2274 4.67447 17.308 4.73325C17.7303 5.04347 17.8207 5.63563 17.5115 6.05797C17.2013 6.48031 16.608 6.57066 16.1868 6.26152C16.1247 6.2158 16.0616 6.17226 15.9985 6.12872C15.9005 6.06123 15.8014 5.9981 15.7013 5.93714C15.034 5.53004 14.3058 5.23723 13.5351 5.06633C11.6835 4.65596 9.78296 4.99231 8.18282 6.01116C6.58377 7.03001 5.47674 8.61162 5.06745 10.4632C4.65708 12.3147 4.99234 14.2153 6.0123 15.8154C7.03225 17.4144 8.61279 18.5215 10.4644 18.9307C12.316 19.3411 14.2165 19.0058 15.8167 17.9859C17.4157 16.966 18.5228 15.3854 18.9331 13.5328C19.1715 12.4552 19.1607 11.3688 18.8983 10.3043C18.7731 9.79593 19.0834 9.28215 19.5917 9.15697C20.1001 9.03179 20.6138 9.34202 20.739 9.85035C21.071 11.2001 21.0863 12.576 20.7847 13.9421C20.2655 16.2878 18.8635 18.2918 16.8366 19.5838C15.3715 20.5189 13.7104 21 12.0166 21Z"
          fill="white"
        />
        <path
          d="M19.8607 11H16.1393C15.5101 11 15 10.5522 15 10C15 9.44776 15.5101 9 16.1393 9H19.8607C20.4899 9 21 9.44776 21 10C21 10.5522 20.4899 11 19.8607 11Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default AdminIcon;
