import React, { PureComponent } from 'react';

type Props = {};

class ArrowHidden extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.28716 0.29989C-0.0957205 0.699616 -0.0957204 1.34745 0.28716 1.74871L3.7484 5.36922L0.28716 8.98973C-0.0957201 9.38946 -0.09572 10.0373 0.28716 10.437C0.67004 10.8367 1.28877 10.8367 1.67165 10.437L5.82514 6.0921C6.20495 5.69237 6.20495 5.04454 5.82514 4.64481L1.67165 0.299889C1.28877 -0.0998374 0.67004 -0.0998373 0.28716 0.29989Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default ArrowHidden;
