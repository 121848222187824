import React, { PureComponent } from 'react';
import i18n from '../../../utils/i18n';
import { Logout, SidebarIndicator, AdminIcon } from '../../../assets/svg';
import LogoutBtn from '../../../containers/auth/Logout';
import './styles.scss';

type Props = {
  onMenuClick: Function,
  storeName?: string,
  storeImage?: any,
};

type State = {
  pressed: boolean,
};

class Header extends PureComponent<Props, State> {
  static defaultProps = {
    storeName: i18n.t('admin'),
    storeImage: <AdminIcon />,
  };

  render() {
    return (
      <div className="header-container">
        <div className="header">
          <button
            type="button"
            onClick={this.props.onMenuClick}
            className="header-button sidebar-indicator"
          >
            <SidebarIndicator />
          </button>
          <div className="user-information-container">
            {this.props.storeImage}
            <div className="name-container">{this.props.storeName}</div>
            <LogoutBtn className="header-button logout">
              <Logout />
            </LogoutBtn>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
