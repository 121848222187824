/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'qs';
import { withTranslation } from 'react-i18next';
import { Growl } from 'primereact/growl';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import { Paginator } from '../../../components/common/paginator';
import './styles.scss';
import { livingPrefecture, genderOptions } from '../../constants';
import { Table } from '../../../components/common/table';
import {
  tableQuerySelector,
  usersDeleteSelector,
} from '../store/admin.selector';
import {
  tableRequestAction,
  deleteUserRequestAction,
} from '../store/admin.request';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  query: Object,
  currFilter: string,
  currFilterValue: string,
  isSortAsc: boolean,
  isModalOpen: boolean,
  selectedId: string,
  currentPage: number,
  sortField: string,
  isShowMessage: boolean,
  isErrorModalOpen: boolean,
};

class AdminUsersPage extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    query: { _limit: 10, _page: 1 },
    currFilter: '',
    currFilterValue: '',
    isSortAsc: false,
    isModalOpen: false,
    selectedId: '',
    currentPage: 1,
    sortField: '',
    isShowMessage: false,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.getWindowWidth);
    this.props.fetchUser('/users?_limit=10&_page=1');
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.users.status === 204 &&
      !this.props.users.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();

      this.props.fetchUser(`/users?${queryString.stringify(this.state.query)}`);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  }

  getWindowWidth = () => this.setState({ currentWidth: window.innerWidth });

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = (id: string) => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      selectedId: id,
    }));
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  adminUsersQueryGenerator = (queryData?: Object, field?: string) => {
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (!key.includes('_') && key !== field && field !== undefined)
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }
    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchUser(`/users?${queryString.stringify(this.state.query)}`);
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.adminUsersQueryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.adminUsersQueryGenerator({ _page: this.state.currentPage + 1 });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.adminUsersQueryGenerator({ _page: this.state.currentPage - 1 });
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.adminUsersQueryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string | Object, value?: string) => {
    let filterQuery;
    const { sortField } = this.state;
    if (typeof field === 'object') {
      if (field.target.id === 'birthday') {
        filterQuery = {
          [`${field.target.id}`]: moment(field.target.value)
            .format('YYYY-MM-DD')
            .toString(),
        };
      } else {
        filterQuery = {
          [`${field.target.id}`]: field.target.value,
        };
      }
      const currSortField = sortField === field.target.id ? sortField : '';
      this.setState({
        currFilterValue: field.target.value,
        currFilter: field.target.id,
        sortField: currSortField,
      });

      this.adminUsersQueryGenerator(filterQuery, field.target.id);
    } else {
      filterQuery = {
        [field]: value,
      };
      const currSortField = sortField === field ? sortField : '';
      this.setState({
        currFilterValue: filterQuery[field],
        currFilter: field,
        sortField: currSortField,
      });

      this.adminUsersQueryGenerator(filterQuery, field);
    }
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    if (isDeleting) this.props.deleteUser(this.state.selectedId);
    this.setState({
      isShowMessage: true,
    });
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: t('toastSuccessSummmary'),
      detail: t('deleteSuccess'),
    });
  };

  growl: any;

  reactTable: any;

  render() {
    const { t } = this.props;
    const userCols = [
      {
        field: '',
        body: (rowData: Object) => {
          return (
            <div className="labelful-button">
              <Button
                onClick={() => this.props.history.push('edit', rowData)}
                label={t('edit')}
                className="restaurant-button restaurant-button--edit"
              />
              <Button
                onClick={() => this.onDeleteClick(rowData.id)}
                label={t('delete')}
                className="restaurant-button restaurant-button--delete"
              />
            </div>
          );
        },
        className: 'actions-width',
        header: `${t('actions')}`,
        filterElement: [''],
      },
      {
        field: 'nickname',
        header: `${t('nickname')}`,
        filterElement: ['nickname', '', 15],
        className: 'nickname-width',
        filter: true,
        sortable: true,
      },
      {
        field: 'birthday',
        type: 'calendar',
        header: `${t('birthday')}`,
        filterElement: ['birthday', ''],
        className: 'birthday-width',
        body: (rowData: Object) => {
          return rowData.birthday ? (
            <div>{moment(rowData.birthday).format('YYYY/MM/DD')}</div>
          ) : (
            ''
          );
        },
        filter: true,
        sortable: true,
      },
      {
        field: 'sex',
        header: `${t('sex')}`,
        type: 'dropdown',
        options: genderOptions,
        filterElement: ['sex', 'alpha', 6],
        className: 'sex-width',
        body: (rowData: Object) => {
          return rowData.sex && `${t(rowData.sex)}`;
        },
        filter: true,
      },
      {
        field: 'prefecture',
        header: `${t('livingPrefecture')}`,
        type: 'dropdown',
        options: livingPrefecture,
        filterElement: ['prefecture', 'alpha', 9],
        className: 'prefecture-width',
        body: (rowData: Object) => {
          return (
            rowData.prefecture &&
            `${t(
              rowData.prefecture.charAt(0).toLowerCase() +
                rowData.prefecture.slice(1),
            )}`
          );
        },
        filter: true,
        sortable: true,
      },
      {
        field: 'mobileNumber',
        header: `${t('mobileNumber')}`,
        filterElement: ['mobileNumber', '', 13],
        className: 'mobile-num-width',
        filter: true,
        sortable: false,
      },
    ];
    return (
      <>
        <CommonModal
          type="delete"
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        <CommonModal
          type="error"
          isOpen={
            this.props.table.error &&
            this.props.table.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.table.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="users"
          userType="admin"
        />
        <div
          className={
            this.state.isSidebarOpen || this.state.isModalOpen
              ? `admin-user-page-container unscrollable`
              : `admin-user-page-container`
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <div className="page-title">{t('users')}</div>
          <div className="page-container">
            <div className="table-container">
              <Table
                currFilterValue={this.state.currFilterValue}
                currFilter={this.state.currFilter}
                onSort={this.getSortQuery}
                onFilter={this.getFilterQuery}
                sortOrder={this.state.isSortAsc}
                data={this.props.users.loading ? {} : this.props.table.data}
                columns={userCols}
                sortField={this.state.sortField}
                emptyMessage={t('noUserRecords')}
                loading={this.props.table.loading || this.props.users.loading}
                pageName="user"
              />
              <Paginator
                currentPage={this.state.currentPage}
                goToFirstPage={this.goToFirstPage}
                goToNextPage={this.goToNextPage}
                goToPreviousPage={this.goToPreviousPage}
                isTableLoading={
                  this.props.table.loading || this.props.users.loading
                }
                isTableEmpty={this.props.table.data.length === 0}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  table: tableQuerySelector(state),
  users: usersDeleteSelector(state),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchUser: (route: string) => dispatch(tableRequestAction(route)),
  deleteUser: (id: string) => dispatch(deleteUserRequestAction(id)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminUsersPage),
);
