import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Growl } from 'primereact/growl';
import { connect } from 'react-redux';
import ProgressSpinner from '../../../components/primereact/misc';
import { CommonModal } from '../../../components/common/modal';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
  Calendar,
} from '../../../components/primereact/inputs';
import { FileInput } from '../../../components/common/input';
import { CalendarIcon } from '../../../assets/svg';
import hiringSchema from './adminHiringEdit.validation';
import noImage from '../../../assets/images/noimage.png';
import { IMAGE_URL } from '../../../config/config';
import { updateStoreHiring } from '../../restaurant/store/restaurant.request';
import { hiringUpdateSelector } from '../../restaurant/store/restaurant.selector';
import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isDimensionAccepted: boolean,
  initialValues: Object,
  hiringId: string,
  restaurantId: string,
  isShowMessage: boolean,
  isErrorModalOpen: boolean,
  isDisconnected: boolean,
};

export const defaultInitialValues = {
  title: '',
  image: '',
  file: null,
  description: '',
  deliveryDate: '',
};

class RestaurantHiringEdit extends PureComponent<*, State> {
  static defaultProps = {
    isAdd: false,
  };

  static buildEditableData = (data: Object) => {
    const editData = { ...data };
    const { image } = editData;

    return {
      image: image ? `${IMAGE_URL}${image}` : noImage,
      title: editData.title,
      description: editData.description,
      deliveryDate: new Date(editData.deliveryDate),
    };
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    let data;
    let hiringId;
    let restaurantId;

    Object.keys(nextProps).forEach((key: string) => {
      if (key === 'location') {
        const { state } = nextProps.location;
        if (state) {
          data = this.buildEditableData(state);
          // eslint-disable-next-line prefer-destructuring
          restaurantId = state.restaurantId;
          hiringId = state.id;
        }
      }
    });
    if (data) return { initialValues: { ...data }, restaurantId, hiringId };
    return { initialValues: { ...defaultInitialValues } };
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isDimensionAccepted: true,
    initialValues: null,
    isShowMessage: false,
    isErrorModalOpen: false,
    isDisconnected: false,
    hiringId: '',
    restaurantId: '',
  };

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  checkDimensionValidity = (status: boolean) => {
    this.setState({
      isDimensionAccepted: status,
    });
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  submitForm = (data: Object) => {
    const { hiringId, restaurantId } = this.state;
    this.props.updateHiring(
      { data, restaurantId, hiringId },
      restaurantId,
      hiringId,
    );

    this.setState({
      isShowMessage: true,
    });
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: `${t('toastSuccessSummmary')}`,
      detail: `${t('toastSuccessDetail')}`,
    });
  };

  showError = () => {
    const { t } = this.props;

    this.growl.show({
      severity: 'error',
      summary: `${t('toastErrorSummary')}`,
      detail: `${t('toastErrorDetail')}`,
    });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  validState = (props: Object) => {
    if (props.isValid && this.state.isDimensionAccepted) return false;
    return true;
  };

  growl: any;

  formik: any;

  fileInput: any;

  render() {
    const { t } = this.props;

    return (
      <>
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="hiring"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'hiringEditPageContainerUnscrollable'
              : 'hiringEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.submitForm}
            validationSchema={hiringSchema}
            render={props => (
              <Form>
                <Growl
                  position="topright"
                  ref={el => {
                    this.growl = el;
                  }}
                />
                {this.state.isDisconnected && (
                  <CommonModal
                    isOpen={this.state.isErrorModalOpen}
                    type="error"
                    message={t('checkInternetConnection')}
                    onCloseClick={() => this.onErroModalClose()}
                  />
                )}
                <div className="restaurant-title">
                  <Button
                    icon="pi pi-arrow-left"
                    type="button"
                    className="restaurant-button restaurant-button--icon"
                    onClick={() => this.props.history.goBack()}
                  />
                  {t('editHiring')}
                  <div className="pull-right">
                    <Button
                      label={t('save')}
                      type={this.props.isAdd ? 'button' : 'submit'}
                      className={
                        this.props.loading
                          ? 'restaurant-button restaurant-button--save transparent-text'
                          : 'restaurant-button restaurant-button--save'
                      }
                      disabled={this.validState(props) || this.props.loading}
                    />
                    {this.props.loading && (
                      <ProgressSpinner
                        loadingText=""
                        className="restaurant-spinner restaurant-spinner--button"
                      />
                    )}
                  </div>
                </div>
                <fieldset
                  className="restaurant-fieldset"
                  disabled={this.props.loading}
                >
                  <div className="restaurant-main">
                    <div className="restaurant-main-inner">
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('title')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <InputText
                              id="title"
                              name="title"
                              type="text"
                              className="restaurant-form-input"
                              value={props.values.title}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            <ErrorMessage
                              name="title"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                          <div className="restaurant-form-group">
                            <div className="restaurant-form-group__label">
                              {t('description')}
                            </div>
                            <div className="restaurant-form-group__data">
                              <InputTextarea
                                id="description"
                                name="description"
                                className="restaurant-form-input"
                                value={props.values.description}
                                rows={5}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                              <ErrorMessage
                                name="description"
                                component="label"
                                className="restaurant-form-error"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('deliveryDate')}
                          </div>
                          <div className="restaurant-form-group__data">
                            <div
                              className="calendar-container"
                              style={
                                this.props.loading
                                  ? { opacity: '0.5' }
                                  : { opacity: 'initial' }
                              }
                            >
                              <Calendar
                                id="deliveryDate"
                                name="deliveryDate"
                                value={props.values.deliveryDate}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                showTime
                                readOnlyInput
                              />
                              <span className="restaurantCalendarIcon">
                                <CalendarIcon />
                              </span>
                            </div>
                            <ErrorMessage
                              name="time"
                              component="label"
                              className="restaurant-form-error"
                            />
                          </div>
                        </div>
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('photoOptional')}
                          </div>
                          <div className="restaurant-form-group__data restaurant-form-group__data--fileupload">
                            <div
                              style={
                                this.props.loading
                                  ? { opacity: '0.5' }
                                  : { opacity: 'initial' }
                              }
                            >
                              <Field
                                id="image"
                                name="image"
                                component={FileInput}
                                checkDimensionValidity={
                                  this.checkDimensionValidity
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </Form>
            )}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => hiringUpdateSelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  updateHiring: (payload: Object, restaurantId: string, hiringId: string) =>
    dispatch(updateStoreHiring(payload, restaurantId, hiringId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantHiringEdit),
);
