import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { IMAGE_URL } from '../../../config/config';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import ProgressSpinner from '../../../components/primereact/misc';
import './styles.scss';
import {
  fetchAppNotificationInfoSelector,
  deleteAppNotificationSelector,
} from '../store/admin.selector';
import {
  fetchAppNotificationInfo,
  deleteAppNotificationRequestAction,
} from '../store/admin.request';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isModalOpen: boolean,
  data: Object,
};

class AdminNotificationDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state } };
    return { data: { ...state } };
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isModalOpen: false,
    data: {},
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });

    const { id } = this.props.location.state;
    this.props.fetchNotificationInfo(id);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onClickToRedirect = (destination: string) => {
    this.props.history.push(destination, this.state.data);
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    if (isDeleting) this.props.deleteNotification(this.state.data.id);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onCloseClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="notification"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen || this.state.isModalOpen
              ? 'notificationDetailsContainerUnscrollable'
              : 'notificationDetailsContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          {this.props.notificationInfo.loading ? (
            <div className="restaurant-spinner-container">
              <ProgressSpinner
                loadingText={t('loading')}
                className="restaurant-spinner"
              />
            </div>
          ) : (
            <Fragment>
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {t('viewAppNotification')}
                <div className="pull-right">
                  <Button
                    label={t('edit')}
                    type="button"
                    className="restaurant-button restaurant-button--edit"
                    onClick={() => this.onClickToRedirect('edit')}
                  />
                  <Button
                    label={t('delete')}
                    type="button"
                    className="restaurant-button restaurant-button--delete"
                    onClick={this.onDeleteClick}
                  />
                </div>
              </div>
              <div className="notificationDetailsFormContainer">
                <div className="notificationDetails">
                  <div className="notificationDetailsChildrenContainer">
                    <div className="notificationDetailsLeft">
                      <div className="notificationDetailsLabel">
                        {t('title')}
                      </div>
                      <div className="notificationDetailsInput">
                        {this.props.notificationInfo &&
                          this.props.notificationInfo.data &&
                          this.props.notificationInfo.data.title}
                      </div>
                      <div className="notificationDetailsLabel">
                        {t('description')}
                      </div>
                      <div className="notificationDetailsInput">
                        {this.props.notificationInfo &&
                          this.props.notificationInfo.data &&
                          this.props.notificationInfo.data.description}
                      </div>
                    </div>
                  </div>
                  {this.props.notificationInfo &&
                    this.props.notificationInfo.data &&
                    this.props.notificationInfo.data.image && (
                      <div className="notificationDetailsChildrenContainer">
                        <div className="notificationDetailsRight">
                          <div className="notificationDetailsLabel">
                            {t('photoOptional')}
                          </div>
                          <div className="notificationDetailsLogoContainer">
                            <div className="notificationDetailsImageBtn">
                              <img
                                className="notificationDetailsLogo"
                                src={`${IMAGE_URL}${this.props.notificationInfo.data.image}`}
                                alt="..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  notificationInfo: fetchAppNotificationInfoSelector(state),
  notificationDeleteInfo: deleteAppNotificationSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  fetchNotificationInfo: (notificationId: string) =>
    dispatch(fetchAppNotificationInfo(notificationId)),
  deleteNotification: (notificationId: string) =>
    dispatch(deleteAppNotificationRequestAction(notificationId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminNotificationDetails),
);
