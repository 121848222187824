import React, { PureComponent } from 'react';

type Props = {};

class EditIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7991 6.31202C15.7999 6.28502 15.7923 6.25843 15.7772 6.23599C15.7622 6.21355 15.7405 6.1964 15.7152 6.18694C15.6899 6.17532 15.6617 6.1719 15.6345 6.17717C15.6072 6.18244 15.5823 6.19614 15.5632 6.21632L14.1344 7.64934C14.1095 7.67569 14.0954 7.71046 14.0949 7.74672V14.2948H2.0314V2.23124H8.80108C8.8393 2.2303 8.87568 2.21466 8.90266 2.18759L10.3298 0.760445C10.3497 0.741943 10.3634 0.717659 10.3688 0.691007C10.3742 0.664354 10.3711 0.636673 10.36 0.611854C10.3503 0.586197 10.3328 0.564264 10.3098 0.549187C10.2869 0.53411 10.2598 0.526661 10.2324 0.527905H1.75185C1.37335 0.527905 1.01035 0.678264 0.742707 0.945905C0.475066 1.21355 0.324707 1.57654 0.324707 1.95505V14.5718C0.324707 14.9503 0.475066 15.3133 0.742707 15.581C1.01035 15.8486 1.37335 15.999 1.75185 15.999H14.372C14.7505 15.999 15.1135 15.8486 15.3811 15.581C15.6487 15.3133 15.7991 14.9503 15.7991 14.5718V6.31202Z"
          fill="#2F80ED"
        />
        <path
          d="M11.1608 2.49906L13.8741 5.21231C13.9289 5.26738 13.9597 5.34194 13.9597 5.41966C13.9597 5.49739 13.9289 5.57195 13.8741 5.62702L7.30503 12.1961L4.51371 12.5084C4.42655 12.5176 4.33843 12.5073 4.2558 12.478C4.17316 12.4488 4.09811 12.4015 4.03613 12.3395C3.97416 12.2775 3.92683 12.2025 3.89762 12.1199C3.8684 12.0372 3.85804 11.9491 3.8673 11.8619L4.17707 9.06643L10.7453 2.49822C10.8003 2.44337 10.8749 2.41257 10.9526 2.41257C11.0304 2.41257 11.1049 2.44337 11.16 2.49822L11.1608 2.49906ZM16.0299 1.81068L14.5658 0.342401C14.3455 0.122478 14.0469 -0.0010376 13.7356 -0.0010376C13.4242 -0.0010376 13.1256 0.122478 12.9053 0.342401L11.8408 1.40772C11.786 1.46279 11.7552 1.53735 11.7552 1.61507C11.7552 1.6928 11.786 1.76736 11.8408 1.82243L14.5541 4.53316C14.6091 4.58801 14.6837 4.61881 14.7614 4.61881C14.8391 4.61881 14.9137 4.58801 14.9688 4.53316L16.0333 3.46868C16.2524 3.24844 16.3754 2.95038 16.3754 2.63968C16.3754 2.32898 16.2524 2.03092 16.0333 1.81068H16.0299Z"
          fill="#2F80ED"
        />
      </svg>
    );
  }
}

export default EditIcon;
