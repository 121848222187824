import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminUsersPage } from './adminUsersPage';
import { AdminUsersEdit } from './adminUsersEdit';
import { AdminRestaurantPage } from './adminRestaurantPage';
import { AdminRestaurantAdd } from './adminRestaurantAdd';
import { AdminRestaurantEdit } from './adminRestaurantEdit';
import { AdminRestaurantDetails } from './adminRestaurantDetails';
import { AdminCouponPage } from './adminCouponPage';
import { AdminCampaignPage } from './adminCampaignPage';
import { AdminWhatToEatTodayPage } from './adminWhatToEatTodayPage';
import { AdminWhatToEatTodayDetails } from './adminWhatToEatTodayDetails';
import { AdminWhatToEatTodayEdit } from './adminWhatToEatTodayEdit';
import { AdminCampaignDetails } from './adminCampaignDetails';
import { AdminCampaignEdit } from './adminCampaignEdit';
import { AdminHiringPage } from './adminHiringPage';
import { AdminHiringDetails } from './adminHiringDetails';
import { AdminHiringEdit } from './adminHiringEdit';
import { AdminNotificationPage } from './adminNotificationPage';
import { AdminNotificationEdit } from './adminNotificationEdit';
import { AdminNotificationDetails } from './adminNotificationDetails';
import { Error404 } from '../errors';

const Routes = () => (
  <Switch>
    <Route exact path="/admin/users/list" component={AdminUsersPage} />
    <Route exact path="/admin/users/edit" component={AdminUsersEdit} />
    <Route
      exact
      path="/admin/restaurant/list"
      component={AdminRestaurantPage}
    />
    <Route exact path="/admin/restaurant/add" component={AdminRestaurantAdd} />
    <Route
      exact
      path="/admin/restaurant/edit"
      component={AdminRestaurantEdit}
    />
    <Route
      exact
      path="/admin/restaurant/details"
      component={AdminRestaurantDetails}
    />
    <Route exact path="/admin/coupon/details" component={AdminCouponPage} />
    <Route exact path="/admin/campaign/list" component={AdminCampaignPage} />
    <Route
      exact
      path="/admin/campaign/details"
      component={AdminCampaignDetails}
    />
    <Route exact path="/admin/campaign/edit" component={AdminCampaignEdit} />
    <Route
      exact
      path="/admin/what-to-eat-today/list"
      component={AdminWhatToEatTodayPage}
    />
    <Route
      exact
      path="/admin/what-to-eat-today/details"
      component={AdminWhatToEatTodayDetails}
    />
    <Route
      exact
      path="/admin/what-to-eat-today/edit"
      component={AdminWhatToEatTodayEdit}
    />
    <Route exact path="/admin/hiring/list" component={AdminHiringPage} />
    <Route exact path="/admin/hiring/details" component={AdminHiringDetails} />
    <Route exact path="/admin/hiring/edit" component={AdminHiringEdit} />
    <Route
      exact
      path="/admin/notification/list"
      component={AdminNotificationPage}
    />
    <Route
      exact
      path="/admin/notification/edit"
      component={AdminNotificationEdit}
    />
    <Route
      exact
      path="/admin/notification/add"
      render={props => <AdminNotificationEdit isAdd {...props} />}
    />
    <Route
      exact
      path="/admin/notification/details"
      component={AdminNotificationDetails}
    />
    <Route component={Error404} />
  </Switch>
);

export default Routes;
