import React, { PureComponent } from 'react';

type Props = {};

class AddPhotoIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 0V4.5H0V7.5H4.5V12H7.5V7.5H12V4.5H7.5V0H4.5Z"
          fill="#4A4A4A"
        />
      </svg>
    );
  }
}

export default AddPhotoIcon;
