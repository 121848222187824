const urls = {
  apiUrl: {
    development:
      'https://us-central1-world-gourmet-3db5a.cloudfunctions.net/app',
    production:
      'https://us-central1-world-gourmet-prod-89e28.cloudfunctions.net/v2',
  },
  imageUrl: {
    development:
      'https://us-central1-world-gourmet-3db5a.cloudfunctions.net/app/images/',
    production:
      'https://us-central1-world-gourmet-prod-89e28.cloudfunctions.net/v2/images/',
  },
};
export const env =
  process.env.NODE_ENV === 'test'
    ? 'development'
    : process.env.REACT_APP_FIREBASE || process.env.NODE_ENV || 'development';

export const IMAGE_URL = urls.imageUrl[env];
export const API_URL = urls.apiUrl[env];
