import React from 'react';
import { InputText as PrimeReactInputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';

const InputText = (props: Object) => {
  const {
    id,
    name,
    type,
    className,
    placeholder,
    value,
    keyfilter,
    onChange,
    onBlur,
    readOnly,
    disabled,
    autoComplete,
  } = props;
  return (
    <PrimeReactInputText
      id={id}
      name={name}
      type={type}
      className={className}
      placeholder={placeholder}
      value={value}
      keyfilter={keyfilter}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
      disabled={disabled}
      autoComplete={autoComplete}
    />
  );
};

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  keyfilter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
};

InputText.defaultProps = {
  className: '',
  placeholder: '',
  keyfilter: '',
  readOnly: false,
  disabled: false,
  autoComplete: 'off',
};

export default InputText;
