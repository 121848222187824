import React, { Fragment } from 'react';
import { ProgressSpinner as PrimeReactProgressSpinner } from 'primereact/progressspinner';
import PropTypes from 'prop-types';

const ProgressSpinner = (props: Object) => {
  const {
    className,
    strokeWidth,
    fill,
    animationDuration,
    loadingText,
  } = props;

  return (
    <Fragment>
      <PrimeReactProgressSpinner
        className={className}
        strokeWidth={strokeWidth}
        fill={fill}
        animationDuration={animationDuration}
      />
      {loadingText}
    </Fragment>
  );
};

ProgressSpinner.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.string,
  fill: PropTypes.string,
  animationDuration: PropTypes.string,
  loadingText: PropTypes.string,
};

ProgressSpinner.defaultProps = {
  className: '',
  strokeWidth: '6',
  fill: 'transparent',
  animationDuration: '2s',
  loadingText: '',
};

export default ProgressSpinner;
