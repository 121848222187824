/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'qs';
import { withTranslation } from 'react-i18next';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { Table } from '../../../components/common/table';
import { CommonModal } from '../../../components/common/modal';
import { Paginator } from '../../../components/common/paginator';
import './styles.scss';
import { tableQuerySelector } from '../store/admin.selector';
import { tableRequestAction } from '../store/admin.request';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  query: Object,
  currFilterValue: string,
  currFilter: string,
  sortField: string,
  isSortAsc: boolean,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class AdminCampaignPage extends PureComponent<*, State> {
  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    query: {},
    currFilterValue: '',
    currFilter: '',
    sortField: '',
    isSortAsc: false,
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.getWindowWidth);
    this.props.fetchCampaign('/campaigns?_limit=10&_page=1');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  }

  onClickToRedirect(destination: string, params?: Object) {
    this.props.history.push(destination, params);
  }

  getWindowWidth = () => this.setState({ currentWidth: window.innerWidth });

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  getPaginationQuery = (pageNum: number) => {
    this.adminCampaignQueryGenerator({ _page: pageNum });
  };

  adminCampaignQueryGenerator = (queryData?: Object, field?: string) => {
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: 1,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (
        !key.includes('_') &&
        key !== field &&
        field !== undefined &&
        !key.includes(field)
      )
        return key;
      return '';
    });

    if (query[field] === '' || prevCol) {
      delete query[prevCol || field];
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchCampaign(
      `/campaigns?${queryString.stringify(this.state.query)}`,
    );
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.adminCampaignQueryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.adminCampaignQueryGenerator({ _page: this.state.currentPage + 1 });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.adminCampaignQueryGenerator({ _page: this.state.currentPage - 1 });
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.adminCampaignQueryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string, value: string) => {
    let filterQuery;
    const { sortField } = this.state;

    if (typeof field === 'object') {
      filterQuery = {
        [`${field.target.id}FromTo`]: field.target.value
          ? `${moment(field.target.value).format('YYYY/MM/DD')}_${moment(
              field.target.value,
            ).format('YYYY/MM/DD')}`
          : undefined,
      };

      const currSortField = sortField === field.target.id ? sortField : '';
      this.setState({
        currFilterValue: field.target.value,
        currFilter: field.target.id,
        sortField: currSortField,
      });

      this.adminCampaignQueryGenerator(filterQuery, field.target.id);
    } else {
      filterQuery = {
        [field]: value,
      };

      const currSortField = sortField === field ? sortField : '';

      this.setState({
        currFilterValue: filterQuery[field],
        currFilter: field,
        sortField: currSortField,
      });

      this.adminCampaignQueryGenerator(filterQuery, field);
    }
  };

  reactTable: any;

  render() {
    const { t } = this.props;

    const campaignCols = [
      {
        field: '',
        body: (rowData: Object) => {
          return (
            <div className="hiringPageLabeledButtonContainer">
              <Button
                onClick={() =>
                  this.props.history.push('/admin/campaign/edit', rowData)
                }
                label={t('edit')}
                className="restaurant-button restaurant-button--edit"
              />
              <Button
                onClick={() =>
                  this.props.history.push('/admin/campaign/details', rowData)
                }
                label={t('details')}
                className="restaurant-button restaurant-button--details"
              />
            </div>
          );
        },
        header: `${t('actions')}`,
        className: 'actions-width',
        filterElement: [''],
      },
      {
        field: 'storeName',
        filter: true,
        sortable: true,
        header: `${t('restaurantName')}`,
        className: 'store-name-width',
        filterElement: ['storeName', ''],
      },
      {
        field: 'title',
        filter: true,
        sortable: true,
        header: `${t('title')}`,
        className: 'title-width',
        filterElement: ['title', ''],
        body: rowData => {
          return (
            <button
              type="button"
              onClick={() => this.onClickToRedirect('details', rowData)}
              className="restaurantPageLinkCells"
            >
              {rowData.title}
            </button>
          );
        },
      },
      {
        field: 'couponUsed',
        filter: true,
        sortable: true,
        header: `${t('couponsUsed')}`,
        className: 'coupons-used-width',
        filterElement: ['couponUsed', ''],
        body: () => {
          return 1000;
        },
      },
      {
        field: 'deliveryDate',
        filter: true,
        sortable: true,
        header: `${t('deliveryDate')}`,
        className: 'delivery-date-width',
        filterElement: ['deliveryDate', ''],
        type: 'calendarTime',
        body: rowData => {
          return (
            <div>{moment(rowData.deliveryDate).format('YYYY/MM/DD HH:MM')}</div>
          );
        },
      },
    ];
    return (
      <>
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="campaign"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'campaignPageContainerUnscrollable'
              : 'campaignPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <div className="campaignPageTitle">{t('campaign')}</div>
          <div>
            <div className="campaignPageTableContainer">
              <Table
                currFilterValue={this.state.currFilterValue}
                currFilter={this.state.currFilter}
                data={this.props.data}
                columns={campaignCols}
                onFilter={this.getFilterQuery}
                onSort={this.getSortQuery}
                sortOrder={this.state.isSortAsc}
                loading={this.props.loading}
                pageName="campaign"
                sortField={this.state.sortField}
              />
              <Paginator
                currentPage={this.state.currentPage}
                goToFirstPage={this.goToFirstPage}
                goToNextPage={this.goToNextPage}
                goToPreviousPage={this.goToPreviousPage}
                isTableLoading={this.props.loading}
                isTableEmpty={this.props.data.length === 0}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => tableQuerySelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  fetchCampaign: (route: string) => dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminCampaignPage),
);
