import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RestaurantContainer } from './restaurantContainer';
import { Error404 } from '../errors';

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/store/information/details"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/information/edit"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/evaluation/list"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/evaluation/list"
      component={RestaurantContainer}
    />
    <Route exact path="/store/menu/list" component={RestaurantContainer} />
    <Route exact path="/store/menu/details" component={RestaurantContainer} />
    <Route exact path="/store/menu/edit" component={RestaurantContainer} />
    <Route exact path="/store/menu/add" component={RestaurantContainer} />
    <Route
      exact
      path="/store/usage-histories/list"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/print-qr-code/details"
      component={RestaurantContainer}
    />
    <Route exact path="/store/campaign/list" component={RestaurantContainer} />
    <Route
      exact
      path="/store/campaign/details"
      component={RestaurantContainer}
    />
    <Route exact path="/store/campaign/edit" component={RestaurantContainer} />
    <Route exact path="/store/campaign/add" component={RestaurantContainer} />
    <Route
      exact
      path="/store/what-to-eat-today/list"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/what-to-eat-today/details"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/what-to-eat-today/edit"
      component={RestaurantContainer}
    />
    <Route
      exact
      path="/store/what-to-eat-today/add"
      component={RestaurantContainer}
    />
    <Route exact path="/store/hiring/list" component={RestaurantContainer} />
    <Route exact path="/store/hiring/details" component={RestaurantContainer} />
    <Route exact path="/store/hiring/edit" component={RestaurantContainer} />
    <Route exact path="/store/hiring/add" component={RestaurantContainer} />
    <Route exact path="/store/contact" component={RestaurantContainer} />
    <Route component={Error404} />
  </Switch>
);

export default Routes;
