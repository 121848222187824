/* eslint-disable no-param-reassign */
import { createSlice } from 'redux-starter-kit';

const reducerDefaultObjectBuilder = () => {
  return {
    loading: false,
    error: false,
    response: null,
    errorMsg: '',
  };
};

const initialState = {
  'restaurant/fetch': reducerDefaultObjectBuilder(),
  'evaluations/fetch': reducerDefaultObjectBuilder(),
  'menus/fetch': reducerDefaultObjectBuilder(),
  'menus/update': reducerDefaultObjectBuilder(),
  'menus/delete': reducerDefaultObjectBuilder(),
  'restaurant/query': reducerDefaultObjectBuilder(),
  'store/query': reducerDefaultObjectBuilder(),
  'storeName/fetch': reducerDefaultObjectBuilder(),
  'storeBalance/fetch': reducerDefaultObjectBuilder(),
  'menus/item/fetch': reducerDefaultObjectBuilder(),
  'hiring/update': reducerDefaultObjectBuilder(),
  'hiring/delete': reducerDefaultObjectBuilder(),
  'hiring/info/fetch': reducerDefaultObjectBuilder(),
  'storeCampaign/delete': reducerDefaultObjectBuilder(),
  'contact/add': reducerDefaultObjectBuilder(),
  'whatToEat/fetch': reducerDefaultObjectBuilder(),
  'whatToEat/update': reducerDefaultObjectBuilder(),
  'whatToEat/delete': reducerDefaultObjectBuilder(),
};

const mapResponseToState = (
  stateKey: string,
  state: Object,
  action: Object,
) => {
  state[stateKey].loading = action.payload.loading;
  state[stateKey].error = action.payload.error;
  state[stateKey].errorMsg = action.payload.errorMsg;
  state[stateKey].response = action.payload.response;
};

const restaurantSlice = createSlice({
  slice: 'restaurantSlice',
  initialState,
  reducers: {
    fetchStoreInformationReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('restaurant/fetch', state, action),
    fetchEvaluationsReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('evaluations/fetch', state, action),
    fetchMenusReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('menus/fetch', state, action),
    fetchMenuItemReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('menus/item/fetch', state, action),
    addMenuReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('menus/update', state, action),
    updateMenuReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('menus/update', state, action),
    deleteMenuReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('menus/delete', state, action),
    fetchListReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('store/query', state, action),
    fetchStoreNameReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('storeName/fetch', state, action),
    fetchStoreBalanceReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('storeBalance/fetch', state, action),
    addHiringReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('hiring/update', state, action),
    updateHiringReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('hiring/update', state, action),
    deleteHiringReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('hiring/delete', state, action),
    fetchHiringInfoReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('hiring/info/fetch', state, action),
    deleteStoreCampaignReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('storeCampaign/delete', state, action),
    contactReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('contact/add', state, action),
    fetchWhatToEatReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('whatToEat/fetch', state, action),
    addWhatToEatReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('whatToEat/update', state, action),
    updateWhatToEatReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('whatToEat/update', state, action),
    deleteWhatToEatReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('whatToEat/delete', state, action),
  },
});

const { actions, reducer } = restaurantSlice;

export const {
  fetchStoreInformationReducerAction,
  fetchEvaluationsReducerAction,
  fetchMenusReducerAction,
  fetchMenuItemReducerAction,
  addMenuReducerAction,
  updateMenuReducerAction,
  deleteMenuReducerAction,
  fetchListReducerAction,
  fetchStoreNameReducerAction,
  fetchStoreBalanceReducerAction,
  addHiringReducerAction,
  updateHiringReducerAction,
  deleteHiringReducerAction,
  fetchHiringInfoReducerAction,
  deleteStoreCampaignReducerAction,
  contactReducerAction,
  fetchWhatToEatReducerAction,
  addWhatToEatReducerAction,
  updateWhatToEatReducerAction,
  deleteWhatToEatReducerAction,
} = actions;

export default reducer;
