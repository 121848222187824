/* eslint-disable no-param-reassign */
import { createSlice } from 'redux-starter-kit';

const reducerDefaultObjectBuilder = () => {
  return {
    loading: false,
    error: false,
    response: null,
    errorMsg: '',
  };
};

const initialState = {
  'restaurants/list/fetch': reducerDefaultObjectBuilder(),
  'restaurants/fetch': reducerDefaultObjectBuilder(),
  'restaurants/add': reducerDefaultObjectBuilder(),
  'restaurants/update': reducerDefaultObjectBuilder(),
  'restaurants/delete': reducerDefaultObjectBuilder(),
  'restaurant/name': reducerDefaultObjectBuilder(),
  'users/fetch': reducerDefaultObjectBuilder(),
  'users/delete': reducerDefaultObjectBuilder(),
  'campaign/fetch': reducerDefaultObjectBuilder(),
  'campaign/item/fetch': reducerDefaultObjectBuilder(),
  'campaign/add': reducerDefaultObjectBuilder(),
  'campaign/update': reducerDefaultObjectBuilder(),
  'campaign/delete': reducerDefaultObjectBuilder(),
  'menu/fetch': reducerDefaultObjectBuilder(),
  'hirings/fetch': reducerDefaultObjectBuilder(),
  'hirings/filter': reducerDefaultObjectBuilder(),
  'admin/query': reducerDefaultObjectBuilder(),
  'coupon/stats': reducerDefaultObjectBuilder(),
  'cuisines/fetch': reducerDefaultObjectBuilder(),
  'appNotification/info/fetch': reducerDefaultObjectBuilder(),
  'appNotification/delete': reducerDefaultObjectBuilder(),
  'appNotification/update': reducerDefaultObjectBuilder(),
  'users/location': reducerDefaultObjectBuilder(),
};

const mapResponseToState = (
  stateKey: string,
  state: Object,
  action: Object,
) => {
  state[stateKey].loading = action.payload.loading;
  state[stateKey].error = action.payload.error;
  state[stateKey].errorMsg = action.payload.errorMsg;
  state[stateKey].response = action.payload.response;
};

const adminSlice = createSlice({
  slice: 'adminSlice',
  initialState,
  reducers: {
    fetchRestaurantListReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('restaurants/list/fetch', state, action),
    fetchRestaurantReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('restaurants/fetch', state, action),
    addRestaurantReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('restaurants/add', state, action),
    updateRestaurantReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('restaurants/update', state, action),
    deleteRestaurantReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('restaurants/delete', state, action),
    updateUserReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('users/update', state, action),
    deleteUserReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('users/delete', state, action),
    fetchUserReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('users/fetch', state, action),
    fetchCampaignReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('campaign/fetch', state, action),
    fetchCampaignItemReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('campaign/item/fetch', state, action),
    addCampaignReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('campaign/update', state, action),
    updateCampaignReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('campaign/update', state, action),
    deleteCampaignReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('campaign/delete', state, action),
    fetchHiringReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('hirings/fetch', state, action),
    fetchQueriedListReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('admin/query', state, action),
    fetchCouponStatsReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('coupon/stats', state, action),
    fetchCuisinesReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('cuisines/fetch', state, action),
    fetchAppNotificationInfoAction: (state: Object, action: Object): void =>
      mapResponseToState('appNotification/info/fetch', state, action),
    addAppNotificationReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('appNotification/update', state, action),
    deleteAppNotificationReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('appNotification/delete', state, action),
    updateAppNotificationReducerAction: (state: Object, action: Object): void =>
      mapResponseToState('appNotification/update', state, action),
  },
});

const { actions, reducer } = adminSlice;

export const {
  fetchRestaurantListReducerAction,
  fetchRestaurantReducerAction,
  addRestaurantReducerAction,
  updateRestaurantReducerAction,
  deleteRestaurantReducerAction,
  deleteUserReducerAction,
  updateUserReducerAction,
  fetchUserReducerAction,
  fetchCampaignReducerAction,
  fetchCampaignItemReducerAction,
  addCampaignReducerAction,
  updateCampaignReducerAction,
  deleteCampaignReducerAction,
  fetchHiringReducerAction,
  fetchQueriedListReducerAction,
  fetchCouponStatsReducerAction,
  fetchCuisinesReducerAction,
  fetchAppNotificationInfoAction,
  addAppNotificationReducerAction,
  deleteAppNotificationReducerAction,
  updateAppNotificationReducerAction,
} = actions;

export default reducer;
