import React, { PureComponent } from 'react';

type Props = {};

class DeleteButtonIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6 17.0714C1.6 18.2423 2.5599 19.2 3.73335 19.2H12.2667C13.4401 19.2 14.4 18.2423 14.4 17.0714V4.8H1.6V17.0714ZM16 1.6H12L10.6608 0H5.3393L4 1.6H0V3.2H16V1.6Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default DeleteButtonIcon;
