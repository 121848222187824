import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { IMAGE_URL } from '../../../config/config';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import ProgressSpinner from '../../../components/primereact/misc';
import {
  hiringInfoSelector,
  hiringDeleteSelector,
} from '../../restaurant/store/restaurant.selector';
import {
  fetchHiringInfo,
  deleteHiringRequestAction,
} from '../../restaurant/store/restaurant.request';

import './styles.scss';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isModalOpen: boolean,
  data: Object,
};

class AdminHiringDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state } };
    return { data: { ...state } };
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isModalOpen: false,
    data: {},
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });

    const { id, restaurantId } = this.props.location.state;
    this.props.fetchHiringInfo(restaurantId, id);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state } };
    return { data: { ...state } };
  };

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onClickToRedirect = (destination: string) => {
    this.props.history.push(destination, this.state.data);
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    const hiringId =
      this.props.hiringInfo &&
      this.props.hiringInfo.data &&
      this.props.hiringInfo.data.id;

    const restaurantId =
      this.props.hiringInfo &&
      this.props.hiringInfo.data &&
      this.props.hiringInfo.data.restaurantId;

    if (isDeleting) this.props.deleteHiring(restaurantId, hiringId);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="hiring"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen || this.state.isModalOpen
              ? 'hiringDetailsContainerUnscrollable'
              : 'hiringDetailsContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          {this.props.hiringInfo.loading ? (
            <div className="restaurant-spinner-container">
              <ProgressSpinner
                loadingText={t('loading')}
                className="restaurant-spinner"
              />
            </div>
          ) : (
            <Fragment>
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {t('viewHiring')}
                <div className="pull-right">
                  <Button
                    label={t('edit')}
                    type="button"
                    className="restaurant-button restaurant-button--edit"
                    onClick={() => this.onClickToRedirect('edit')}
                  />
                  <Button
                    label={t('delete')}
                    type="button"
                    className="restaurant-button restaurant-button--delete"
                    onClick={this.onDeleteClick}
                  />
                </div>
              </div>
              <div className="hiringDetailsFormContainer">
                <div className="hiringDetails">
                  <div className="hiringDetailsChildrenContainer">
                    <div className="hiringDetailsLeft">
                      <div className="hiringDetailsLabel">{t('title')}</div>
                      <div className="hiringDetailsInput">
                        {this.props.hiringInfo &&
                          this.props.hiringInfo.data &&
                          this.props.hiringInfo.data.title}
                      </div>
                      <div className="hiringDetailsLabel">
                        {t('description')}
                      </div>
                      <div className="hiringDetailsInput">
                        {this.props.hiringInfo &&
                          this.props.hiringInfo.data &&
                          this.props.hiringInfo.data.description}
                      </div>
                    </div>
                  </div>
                  <div className="hiringDetailsChildrenContainer">
                    <div className="hiringDetailsRight">
                      <div className="hiringDetailsLabel">
                        {t('dateCreated')}
                      </div>
                      <div className="hiringDetailsInput">
                        {this.props.hiringInfo && this.props.hiringInfo.data
                          ? moment(this.props.hiringInfo.data.createdAt).format(
                              'YYYY/MM/DD HH:MM',
                            )
                          : ''}
                      </div>
                      <div className="hiringDetailsLabel">
                        {t('deliveryDate')}
                      </div>
                      <div className="hiringDetailsInput">
                        {this.props.hiringInfo && this.props.hiringInfo.data
                          ? moment(
                              this.props.hiringInfo.data.deliveryDate,
                            ).format('YYYY/MM/DD HH:MM')
                          : ''}
                      </div>
                      {this.props.hiringInfo &&
                        this.props.hiringInfo.data &&
                        this.props.hiringInfo.data.image && (
                          <>
                            <div className="hiringDetailsLabel">
                              {t('photoOptional')}
                            </div>
                            <div className="hiringDetailsLogoContainer">
                              <div className="hiringDetailsImageBtn">
                                <img
                                  className="hiringDetailsLogo"
                                  src={`${IMAGE_URL}${this.props.hiringInfo.data.image}`}
                                  alt="..."
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  hiringInfo: hiringInfoSelector(state),
  hiringDeleteInfo: hiringDeleteSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  fetchHiringInfo: (restaurantId: string, hiringId: string) =>
    dispatch(fetchHiringInfo(restaurantId, hiringId)),
  deleteHiring: (restaurantId: string, hiringId: string) =>
    dispatch(deleteHiringRequestAction(restaurantId, hiringId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminHiringDetails),
);
