import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class NextArrow extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={this.props.className}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.03491 6.56869L0 13.1374L0 1.14441e-05L6.03491 6.56869Z"
          fill="#999999"
        />
      </svg>
    );
  }
}

export default NextArrow;
