import firebase from 'firebase/app';

const config = {
  development: {
    apiKey: 'AIzaSyCVmfKwzgy6TX_ig4Ji10J1KZLalT77Ci0',
    authDomain: 'world-gourmet-3db5a.firebaseapp.com',
    databaseURL: 'https://world-gourmet-3db5a.firebaseio.com',
    projectId: 'world-gourmet-3db5a',
    storageBucket: 'world-gourmet-3db5a.appspot.com',
    messagingSenderId: '322718014780',
    appId: '1:322718014780:web:d5b36ac795137af5',
  },
  production: {
    apiKey: 'AIzaSyD5La4szHaOE_DPKBeaG-wE5MhDjWEh7CE',
    authDomain: 'world-gourmet-prod-89e28.firebaseapp.com',
    databaseURL: 'https://world-gourmet-prod-89e28.firebaseio.com',
    projectId: 'world-gourmet-prod-89e28',
    storageBucket: 'world-gourmet-prod-89e28.appspot.com',
    messagingSenderId: '844755304417',
    appId: '1:844755304417:web:0d87dd0223853ad4981ca0',
    measurementId: 'G-FWE62D52ZM',
  },
};

const configEnv =
  process.env.NODE_ENV === 'test'
    ? 'development'
    : process.env.REACT_APP_FIREBASE || process.env.NODE_ENV || 'development';
export default () => firebase.initializeApp(config[configEnv]);
