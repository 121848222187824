import { createDeepEqualSelector } from '../../../utils/selector.util';

const selectorListResponse = (state: Object) => {
  let data = [];
  let status = '';
  const { response, loading } = state;

  if (response) {
    // eslint-disable-next-line prefer-destructuring
    data = response.data;
    const { httpStatusCode } = response;
    status = httpStatusCode;
  }
  return {
    status,
    data,
    loading,
    error: state.error,
    errorMsg: state.errorMsg,
  };
};

const selectorResponse = (state: Object) => {
  let data = null;
  let status = '';
  const { response, loading } = state;

  if (response) {
    // eslint-disable-next-line prefer-destructuring
    data = response.data;
    const { httpStatusCode } = response;
    status = httpStatusCode;
  }
  return {
    status,
    data,
    loading,
    error: state.error,
    errorMsg: state.errorMsg,
  };
};

export const restaurantSelector = createDeepEqualSelector(
  ['restaurantStore.restaurant/fetch'],
  (state: Object) => selectorResponse(state),
);

export const evaluationsSelector = createDeepEqualSelector(
  ['restaurantStore.evaluations/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const menusSelector = createDeepEqualSelector(
  ['restaurantStore.menus/fetch'],
  (state: Object) => selectorListResponse(state),
);

export const menuItemSelector = createDeepEqualSelector(
  ['restaurantStore.menus/item/fetch'],
  (state: Object) => selectorResponse(state),
);

export const menuAddSelector = createDeepEqualSelector(
  ['restaurantStore.menus/add'],
  (state: Object) => selectorListResponse(state),
);

export const menuUpdateSelector = createDeepEqualSelector(
  ['restaurantStore.menus/update'],
  (state: Object) => selectorListResponse(state),
);

export const menuDeleteSelector = createDeepEqualSelector(
  ['restaurantStore.menus/delete'],
  (state: Object) => selectorListResponse(state),
);

export const tableQuerySelector = createDeepEqualSelector(
  ['restaurantStore.store/query'],
  (state: Object) => selectorListResponse(state),
);

export const storeNameSelector = createDeepEqualSelector(
  ['restaurantStore.storeName/fetch'],
  (state: Object) => selectorResponse(state),
);

export const storeBalanceSelector = createDeepEqualSelector(
  ['restaurantStore.storeBalance/fetch'],
  (state: Object) => selectorResponse(state),
);

export const hiringAddSelector = createDeepEqualSelector(
  ['restaurantStore.hiring/add'],
  (state: Object) => selectorListResponse(state),
);

export const hiringUpdateSelector = createDeepEqualSelector(
  ['restaurantStore.hiring/update'],
  (state: Object) => selectorResponse(state),
);
export const hiringDeleteSelector = createDeepEqualSelector(
  ['restaurantStore.hiring/delete'],
  (state: Object) => selectorResponse(state),
);

export const hiringInfoSelector = createDeepEqualSelector(
  ['restaurantStore.hiring/info/fetch'],
  (state: Object) => selectorResponse(state),
);

export const storeCampaignDeleteSelector = createDeepEqualSelector(
  ['restaurantStore.storeCampaign/delete'],
  (state: Object) => selectorListResponse(state),
);

export const storeContactSelector = createDeepEqualSelector(
  ['restaurantStore.contact/add'],
  (state: Object) => selectorListResponse(state),
);

export const fetchWhatToEatSelector = createDeepEqualSelector(
  ['restaurantStore.whatToEat/fetch'],
  (state: Object) => selectorResponse(state),
);

export const addWhatToEatSelector = createDeepEqualSelector(
  ['restaurantStore.whatToEat/add'],
  (state: Object) => selectorResponse(state),
);

export const updateWhatToEatSelector = createDeepEqualSelector(
  ['restaurantStore.whatToEat/update'],
  (state: Object) => selectorResponse(state),
);

export const deleteWhatToEatSelector = createDeepEqualSelector(
  ['restaurantStore.whatToEat/delete'],
  (state: Object) => selectorResponse(state),
);
