import React, { PureComponent, Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Growl } from 'primereact/growl';
import { CommonModal } from '../../../components/common/modal';
import { FileInput } from '../../../components/common/input';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
} from '../../../components/primereact/inputs';
import ProgressSpinner from '../../../components/primereact/misc';
import {
  addMenuSchema,
  updateMenuSchema,
} from './restaurantMenuEdit.validation';
import { IMAGE_URL } from '../../../config/config';
import { addStoreMenu, updateStoreMenu } from '../store/restaurant.request';
import { menuUpdateSelector } from '../store/restaurant.selector';

type State = {
  initialValues: Object,
  isDimensionAccepted: boolean,
  isShowMessage: boolean,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
  menuId: string | null,
};

export const defaultInitialValues = {
  name: '',
  photo: '',
  file: null,
  description: '',
  price: '',
};

class RestaurantMenusEdit extends PureComponent<*, State> {
  static defaultProps = {
    isAdd: false,
  };

  static buildEditableData = (data: Object) => {
    const editData = { ...data };
    const { photo } = editData;
    return {
      photo: `${IMAGE_URL}${photo}`,
      name: editData.name,
      description: editData.description,
      price: editData.price,
    };
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    let data;
    let menuId;
    Object.keys(nextProps).forEach((key: string) => {
      if (key === 'location') {
        const { state } = nextProps.location;
        if (state) {
          data = this.buildEditableData(state);
          menuId = state.id;
        }
      }
    });
    if (data) return { initialValues: { ...data }, menuId };
    return { initialValues: { ...defaultInitialValues }, menuId };
  };

  state = {
    isDimensionAccepted: true,
    initialValues: null,
    isShowMessage: false,
    isDisconnected: false,
    isErrorModalOpen: false,
    menuId: null,
  };

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate() {
    if (this.props.isAdd) {
      if (
        this.state.isShowMessage &&
        this.props.status === 201 &&
        !this.props.loading
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isShowMessage: false,
        });
        this.showInfo();
      }

      if (
        this.state.isShowMessage &&
        this.props.status === 400 &&
        !this.props.loading
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isShowMessage: false,
        });
        this.showError();
      }
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  checkDimensionValidity = (status: boolean) => {
    this.setState({
      isDimensionAccepted: status,
    });
  };

  submitForm = (data: Object) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      if (this.props.isAdd)
        this.props.addMenu({ data, restaurantId: uid }, uid);
      else
        this.props.updateMenu(
          { data, restaurantId: uid, menuId: this.state.menuId },
          uid,
          this.state.menuId,
        );
    }

    this.setState({
      isShowMessage: true,
    });
  };

  validState = (props: Object) => {
    if (props.isValid && this.state.isDimensionAccepted) return false;
    return true;
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: `${t('toastSuccessSummmary')}`,
      detail: `${t('toastSuccessDetail')}`,
    });
  };

  showError = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'error',
      summary: `${t('toastErrorSummary')}`,
      detail: `${t('toastErrorDetail')}`,
    });
  };

  growl: any;

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onClick={() => {}}
            onDeleteGetValue={() => {}}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <Formik
          initialValues={this.state.initialValues}
          onSubmit={this.submitForm}
          validationSchema={this.props.isAdd ? addMenuSchema : updateMenuSchema}
          render={props => (
            <Form>
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {this.props.isAdd ? `${t('addMenu')}` : `${t('editMenu')}`}
                <div className="pull-right">
                  <Button
                    label={t('save')}
                    type="submit"
                    className={
                      this.props.loading
                        ? 'restaurant-button restaurant-button--save transparent-text'
                        : 'restaurant-button restaurant-button--save'
                    }
                    disabled={
                      this.validState(props) ||
                      this.props.loading ||
                      (this.props.isAdd && !props.values.file)
                    }
                  />
                  {this.props.loading && (
                    <ProgressSpinner
                      loadingText=""
                      className="restaurant-spinner restaurant-spinner--button"
                    />
                  )}
                </div>
              </div>
              <fieldset
                className="restaurant-fieldset"
                disabled={this.props.loading}
              >
                <div className="restaurant-main">
                  <div className="restaurant-main-inner">
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('name')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="name"
                            name="name"
                            type="text"
                            className="restaurant-form-input"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="name">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('description')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputTextarea
                            id="description"
                            name="description"
                            className="restaurant-form-input"
                            value={props.values.description}
                            rows={5}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="description">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('price')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="price"
                            name="price"
                            type="number"
                            keyfilter="pint"
                            className="restaurant-form-input"
                            value={props.values.price}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="price">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('photo')}
                        </div>
                        <div className="restaurant-form-group__data restaurant-form-group__data--fileupload">
                          <div
                            style={
                              this.props.loading
                                ? { opacity: '0.5' }
                                : { opacity: 'initial' }
                            }
                          >
                            <Field
                              id="photo"
                              name="photo"
                              component={FileInput}
                              checkDimensionValidity={
                                this.checkDimensionValidity
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => menuUpdateSelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  addMenu: (payload: Object, restaurantId: string) =>
    dispatch(addStoreMenu(payload, restaurantId)),
  updateMenu: (payload: Object, restaurantId: string, menuId: string) =>
    dispatch(updateStoreMenu(payload, restaurantId, menuId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantMenusEdit),
);
