import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class ToFirstArrow extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className={this.props.className ? this.props.className : ''}
          x="0.311462"
          y="0.512787"
          width="0.622925"
          height="12.1043"
          fill="#DDDDDD"
          stroke="#979797"
          strokeWidth="0.622925"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.34326 6.56869L7.37814 13.1374L7.37814 1.14441e-05L1.34326 6.56869Z"
          fill="#999999"
        />
      </svg>
    );
  }
}

export default ToFirstArrow;
