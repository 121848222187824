import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './styles.scss';

type Props = {
  id: string,
  name: string,
  inputReadOnly: boolean,
  use12Hours: boolean,
  allowEmpty: boolean,
  showSecond: boolean,
  minuteStep: number,
  value: Function,
  setFieldValue: Function,
  form: Object,
};

class TimePickerInput extends PureComponent<Props> {
  handleTimeValueChange = (value: Function) => {
    this.props.form.setFieldValue('time', value.format('HH:mm'));
  };

  render() {
    const {
      id,
      name,
      inputReadOnly,
      use12Hours,
      allowEmpty,
      showSecond,
      minuteStep,
      value,
    } = this.props;

    return (
      <Fragment>
        <TimePicker
          id={id}
          name={name}
          inputReadOnly={inputReadOnly}
          use12Hours={use12Hours}
          allowEmpty={allowEmpty}
          showSecond={showSecond}
          minuteStep={minuteStep}
          value={moment(value, 'LT')}
          onChange={this.handleTimeValueChange}
        />
      </Fragment>
    );
  }
}

export default TimePickerInput;
