import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import './styles.scss';

const CaptchaValidation = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const captcha = new firebase.auth.RecaptchaVerifier('captcha-container', {
      size: 'normal',
      callback: (captchaToken: string) => setToken(captchaToken),
      'expired-callback': () => console.log('Captcha error.'),
    });
    setTimeout(() => {
      captcha.render().then(() => {
        captcha.verify();
      });
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (token) {
      window.postMessage(token);
    }
  }, [token]);

  return (
    <div className="captcha">
      <b className="captcha__header">
        {/* Please click below to continue */}
        続行する場合は、下のボタンをクリックしてください
      </b>
      <div>
        {loading ? (
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        ) : null}
      </div>
      <div id="captcha-container" />
    </div>
  );
};

export default CaptchaValidation;
