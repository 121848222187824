import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import {
  successResponseInterceptor,
  errorResponseInterceptor,
} from './api.interceptors';
import api from './Api.service';
import { API_URL } from '../../config/config';

const TIMEOUT = 40 * 1000;

const createAxiosInstance = () => {
  const axiosApi = axios.create({
    baseURL: API_URL,
    timeout: TIMEOUT,
  });
  axiosApi.interceptors.response.use(
    successResponseInterceptor,
    errorResponseInterceptor,
  );
  axiosApi.defaults.headers.common.Accept = 'application/json; charset=utf-8';
  return axiosApi;
};

export const extractToken = async (user: Object) => {
  const tokenResult = await user.getIdTokenResult();
  const bearer = `Bearer ${tokenResult.token}`;
  return bearer;
};

const setBearerToken = (apiInst: Object) =>
  firebase.auth().onIdTokenChanged(async (user: Object) => {
    if (user) {
      // eslint-disable-next-line no-param-reassign
      apiInst.defaults.headers.common.Authorization = await extractToken(user);
    }
  });

export default () => {
  api.initialize(createAxiosInstance());
  setBearerToken(api.getApi().apiInst);
};
