import React from 'react';
import { Button as PrimeReactButton } from 'primereact/button';
import PropTypes from 'prop-types';

const Button = (props: Object) => {
  const { label, icon, type, className, onClick, disabled } = props;

  return (
    <PrimeReactButton
      label={label}
      icon={icon}
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  label: '',
  icon: '',
  type: 'button',
  className: '',
  onClick: null,
  disabled: false,
};

export default Button;
