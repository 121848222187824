import React from 'react';
import { Dropdown as PrimeReactDropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';

const Dropdown = (props: Object) => {
  const {
    id,
    name,
    className,
    placeholder,
    options,
    value,
    onChange,
    onBlur,
    disabled,
  } = props;
  return (
    <PrimeReactDropdown
      id={id}
      name={name}
      className={className}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
};

export default Dropdown;
