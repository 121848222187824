import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { IMAGE_URL } from '../../../config/config';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { CommonModal } from '../../../components/common/modal';
import noImage from '../../../assets/images/noimage.png';
import ProgressSpinner from '../../../components/primereact/misc';
import {
  campaignDeleteSelector,
  campaignItemSelector,
} from '../store/admin.selector';
import {
  fetchCampaignItemRequestAction,
  deleteCampaignRequestAction,
} from '../store/admin.request';
import { defaultInitialValues } from '../adminCampaignEdit/AdminCampaignEdit';

type State = {
  isSidebarOpen: boolean,
  currentWidth: number,
  isModalOpen: boolean,
  data: Object,
  campaignId: string,
};

class AdminCampaignDetails extends PureComponent<*, State> {
  static getDerivedStateFromProps = (nextProps: Object) => {
    const { state } = nextProps.location;
    if (state) return { data: { ...state }, campaignId: state.id };
    return { data: { ...defaultInitialValues } };
  };

  state = {
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isModalOpen: false,
    data: {},
    campaignId: '',
  };

  componentDidMount() {
    this.props.fetchCampaignItem(this.state.campaignId);

    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  onClickToRedirect(destination: string) {
    this.props.history.push(destination, this.state.data);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onDeleteClick = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onDeleteGetValue = (isDeleting: boolean) => {
    if (isDeleting) this.props.deleteCampaign(this.state.campaignId);
  };

  render() {
    const { t } = this.props;

    return (
      <div className="restaurant-container">
        <CommonModal
          isOpen={this.state.isModalOpen}
          onClick={this.onDeleteClick}
          onDeleteGetValue={this.onDeleteGetValue}
        />
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="campaign"
          userType="admin"
          isInSubmenu
        />
        <div
          className={
            this.state.isSidebarOpen || this.state.isModalOpen
              ? 'restaurantEditPageContainerUnscrollable'
              : 'restaurantEditPageContainer'
          }
        >
          <div>
            <Header onMenuClick={this.onMenuClick} />
            {this.props.fetchCampaignItemSelector.loading ? (
              <div className="restaurant-spinner-container">
                <ProgressSpinner
                  loadingText={t('loading')}
                  className="restaurant-spinner"
                />
              </div>
            ) : (
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {t('viewCampaign')}
                <div className="pull-right">
                  <Button
                    label={t('edit')}
                    type="button"
                    className="restaurant-button restaurant-button--edit"
                    onClick={() => this.onClickToRedirect('edit')}
                  />
                  <Button
                    label={t('delete')}
                    type="button"
                    className="restaurant-button restaurant-button--delete"
                    onClick={this.onDeleteClick}
                  />
                </div>
              </div>
            )}
          </div>
          {!this.props.fetchCampaignItemSelector.loading && (
            <div className="restaurant-main">
              <div className="restaurant-main-inner">
                <div className="restaurant-column">
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('title')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchCampaignItemSelector &&
                      this.props.fetchCampaignItemSelector.data &&
                      this.props.fetchCampaignItemSelector.data.title
                        ? this.props.fetchCampaignItemSelector.data.title
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('description')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchCampaignItemSelector &&
                      this.props.fetchCampaignItemSelector.data &&
                      this.props.fetchCampaignItemSelector.data.description
                        ? this.props.fetchCampaignItemSelector.data.description
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('couponsUsed')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchCampaignItemSelector &&
                      this.props.fetchCampaignItemSelector.data &&
                      this.props.fetchCampaignItemSelector.data.couponsUsed
                        ? this.props.fetchCampaignItemSelector.data.couponsUsed
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="restaurant-column">
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('dateCreated')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchCampaignItemSelector &&
                      this.props.fetchCampaignItemSelector.data &&
                      this.props.fetchCampaignItemSelector.data.createdAt
                        ? moment(
                            this.props.fetchCampaignItemSelector.data.createdAt,
                          ).format('YYYY/MM/DD HH:MM')
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('deliveryDate')}
                    </div>
                    <div className="restaurant-form-group__data">
                      {this.props.fetchCampaignItemSelector &&
                      this.props.fetchCampaignItemSelector.data &&
                      this.props.fetchCampaignItemSelector.data.deliveryDate
                        ? moment(
                            this.props.fetchCampaignItemSelector.data
                              .deliveryDate,
                          ).format('YYYY/MM/DD HH:MM')
                        : ''}
                    </div>
                  </div>
                  <div className="restaurant-form-group">
                    <div className="restaurant-form-group__label">
                      {t('photoOptional')}
                    </div>
                    <div className="restaurant-form-group__data">
                      <div className="restaurant-image-wrapper">
                        {this.props.fetchCampaignItemSelector &&
                        this.props.fetchCampaignItemSelector.data &&
                        this.props.fetchCampaignItemSelector.data.image ? (
                          <img
                            src={
                              this.state.data
                                ? `${IMAGE_URL}${this.props.fetchCampaignItemSelector.data.image}`
                                : { noImage }
                            }
                            alt="..."
                          />
                        ) : (
                          <img src={noImage} alt="..." />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => ({
  deleteCampaignSelector: campaignDeleteSelector(state),
  fetchCampaignItemSelector: campaignItemSelector(state),
});

const mapDispatchToProps = (dispatch: Object) => ({
  deleteCampaign: (id: string) => dispatch(deleteCampaignRequestAction(id)),
  fetchCampaignItem: (campaignId: string) =>
    dispatch(fetchCampaignItemRequestAction(campaignId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminCampaignDetails),
);
