import React, { PureComponent } from 'react';

type Props = {};

class Warning extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.8052 17.5571L12.2476 1.00028C11.6936 0.0409219 10.3064 0.0409219 9.75237 1.00028L0.194815 17.5571C-0.359181 18.5165 0.33444 19.719 1.44243 19.719H20.5576C21.6655 19.719 22.3592 18.5165 21.8052 17.5571ZM11.0135 6.09885C11.6261 6.09885 12.1215 6.6078 12.1035 7.22035L11.9233 13.508C11.9098 14.0034 11.5044 14.3953 11.009 14.3953C10.5136 14.3953 10.1082 13.9989 10.0947 13.508L9.91902 7.22035C9.90551 6.6078 10.3965 6.09885 11.0135 6.09885ZM11 17.4715C10.3739 17.4715 9.86498 16.9626 9.86498 16.3365C9.86498 15.7105 10.3739 15.2015 11 15.2015C11.6261 15.2015 12.135 15.7105 12.135 16.3365C12.135 16.9626 11.6261 17.4715 11 17.4715Z"
          fill="black"
        />
      </svg>
    );
  }
}

export default Warning;
