import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import english from './en.json';
import japanese from './ja.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: english,
      },
      ja: {
        translations: japanese,
      },
    },
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test',
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
