/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'qs';
import firebase from 'firebase/app';
import 'firebase/auth';
import { withTranslation } from 'react-i18next';
import Button from '../../../components/primereact/buttons';
import { Table } from '../../../components/common/table';
import { CommonModal } from '../../../components/common/modal';
import { Paginator } from '../../../components/common/paginator';
import './styles.scss';
import { tableQuerySelector } from '../../admin/store/admin.selector';
import { tableRequestAction } from '../../admin/store/admin.request';

type State = {
  query: Object,
  currFilter: string,
  currFilterValue: string,
  isSortAsc: boolean,
  sortField: string | boolean,
  currentPage: number,
  isErrorModalOpen: boolean,
};

class RestaurantCampaignPage extends PureComponent<*, State> {
  state = {
    query: {},
    currFilterValue: '',
    currFilter: '',
    sortField: '',
    isSortAsc: false,
    currentPage: 1,
    isErrorModalOpen: false,
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.props.fetchQueriedCampaign(
        `/campaigns?restaurantId=${uid}&_limit=10&_page=1`,
      );
    }
  }

  componentDidUpdate(props: Object, nextState: Object) {
    /* eslint-disable-next-line react/no-did-update-set-state */
    if (nextState.isErrorModalOpen) this.setState({ isErrorModalOpen: false });
  }

  onClickToRedirect = (destination: string, params?: Object) => {
    this.props.history.push(destination, params);
  };

  getPaginationQuery = (pageNum: number) => {
    this.restaurantCampaignQueryGenerator({ _page: pageNum });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  restaurantCampaignQueryGenerator = (queryData?: Object, field?: string) => {
    let uid = '';
    const query = Object.assign(
      this.state.query,
      {
        _limit: 10,
        _page: this.state.currentPage,
      },
      queryData,
    );

    const prevCol = Object.keys(query).find(key => {
      if (
        !key.includes('_') &&
        key !== field &&
        field !== undefined &&
        !key.includes(field)
      )
        return key;
      return '';
    });

    if (query[field] === '' || prevCol || query[field] === null) {
      delete query[prevCol || field];
    }

    const user = firebase.auth().currentUser;
    if (user) {
      /* eslint-disable-next-line prefer-destructuring */
      uid = user.uid;
    }

    this.setState(prevState => {
      const updatedQuery = Object.assign({}, prevState.query, query);
      return {
        query: updatedQuery,
      };
    });

    this.props.fetchQueriedCampaign(
      `/campaigns?restaurantId=${uid}&${queryString.stringify(
        this.state.query,
      )}`,
    );
  };

  getSortQuery = (e: Object) => {
    const sortInfo = {
      _sortBy: e.sortField,
      _sortDirection: this.state.isSortAsc ? 'desc' : 'asc',
    };

    this.setState(prevState => ({
      sortField: e.sortField,
      currFilter: e.sortField,
      currFilterValue:
        e.sortField !== prevState.currFilter ? '' : prevState.currFilterValue,
      isSortAsc: e.sortOrder === 1,
    }));

    this.restaurantCampaignQueryGenerator(sortInfo, sortInfo._sortBy);
  };

  getFilterQuery = (field: string, value: string) => {
    let filterQuery;
    const { sortField } = this.state;

    if (typeof field === 'object') {
      filterQuery = {
        [`${field.target.id}FromTo`]: field.target.value
          ? `${moment(field.target.value).format('YYYY/MM/DD')}_${moment(
              field.target.value,
            ).format('YYYY/MM/DD')}`
          : undefined,
      };

      const currSortField = sortField === field.target.id ? sortField : '';
      this.setState({
        currFilterValue: field.target.value,
        currFilter: field.target.id,
        sortField: currSortField,
      });

      this.restaurantCampaignQueryGenerator(filterQuery, field.target.id);
    } else {
      filterQuery = {
        [field]: value,
      };

      const currSortField = sortField === field ? sortField : '';

      this.setState({
        currFilterValue: filterQuery[field],
        currFilter: field,
        sortField: currSortField,
      });

      this.restaurantCampaignQueryGenerator(filterQuery, field);
    }
  };

  goToFirstPage = () => {
    this.setState({
      currentPage: 1,
    });

    this.restaurantCampaignQueryGenerator({ _page: 1 });
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
    }));
    this.restaurantCampaignQueryGenerator({
      _page: this.state.currentPage + 1,
    });
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
    }));
    this.restaurantCampaignQueryGenerator({
      _page: this.state.currentPage - 1,
    });
  };

  reactTable: any;

  render() {
    const { t } = this.props;

    const campaignCols = [
      {
        field: '',
        body: (rowData: Object) => {
          return (
            <div className="hiringPageLabeledButtonContainer">
              <Button
                onClick={() => this.props.history.push('edit', rowData)}
                label={t('edit')}
                className="restaurant-button restaurant-button--edit"
              />
              <Button
                onClick={() => this.props.history.push('details', rowData)}
                label={t('details')}
                className="restaurant-button restaurant-button--details"
              />
            </div>
          );
        },
        header: `${t('actions')}`,
        className: 'actions-width',
        filterElement: [''],
      },
      {
        field: 'title',
        filter: true,
        sortable: true,
        header: `${t('title')}`,
        className: 'title-width',
        filterElement: ['title', ''],
        body: rowData => {
          return (
            <button
              type="button"
              onClick={() => this.onClickToRedirect('details', rowData)}
              className="restaurantPageLinkCells"
            >
              {rowData.title}
            </button>
          );
        },
      },
      {
        field: 'pointsUsed',
        filter: true,
        sortable: true,
        header: `${t('pointsUsed')}`,
        className: 'points-used-width',
        filterElement: ['pointsUsed', ''],
        body: () => {
          return 1000;
        },
      },
      {
        field: 'deliveryDate',
        filter: true,
        sortable: true,
        header: `${t('postingDate')}`,
        className: 'posting-date-width',
        filterElement: ['deliveryDate', ''],
        type: 'calendarTime',
        body: (rowData: Object) => {
          return rowData.deliveryDate
            ? `${moment(rowData.deliveryDate).format('YYYY/MM/DD HH:mm')}`
            : '';
        },
      },
      {
        field: 'numberOfRecipients',
        filter: true,
        sortable: true,
        header: `${t('numberOfRecipients')}`,
        className: 'number-of-recipients-width',
        filterElement: ['numberOfRecipients', ''],
        body: () => {
          return 1000;
        },
      },
    ];
    return (
      <>
        <CommonModal
          type="error"
          isOpen={
            this.props.error &&
            this.props.errorMsg &&
            !this.state.isErrorModalOpen
          }
          message={this.props.errorMsg}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onErroModalClose()}
        />
        <div className="campaignPageTitle">
          {t('campaign')}
          <div className="pull-right">
            <Button
              label={t('addCampaign')}
              type="button"
              className="restaurant-button restaurant-button--save"
              onClick={() => this.onClickToRedirect('add')}
            />
          </div>
        </div>
        <div className="restaurant-main">
          <div className="table-container restaurant-campaign">
            <Table
              currFilterValue={this.state.currFilterValue}
              currFilter={this.state.currFilter}
              onSort={this.getSortQuery}
              onFilter={this.getFilterQuery}
              sortOrder={this.state.isSortAsc}
              data={this.props.data}
              columns={campaignCols}
              loading={this.props.loading}
              sortField={this.state.sortField}
              emptyMessage={t('noCampaignRecords')}
            />
            <Paginator
              currentPage={this.state.currentPage}
              goToFirstPage={this.goToFirstPage}
              goToNextPage={this.goToNextPage}
              goToPreviousPage={this.goToPreviousPage}
              isTableLoading={this.props.loading}
              isTableEmpty={this.props.data.length === 0}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Object) => tableQuerySelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  fetchQueriedCampaign: (route: string) => dispatch(tableRequestAction(route)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantCampaignPage),
);
