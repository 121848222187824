import { createSelector } from 'redux-starter-kit';

const selectoratorOptions = { deepEqual: true };

/** Safe comparison of deep nested store */
export const createDeepEqualSelector = (
  reducerArray: Array<string>,
  storeMutatorFunction: (store: Object) => Object,
): Object =>
  createSelector(
    reducerArray,
    storeMutatorFunction,
    selectoratorOptions,
  );

/** For memoization of same selectors on different component instances  */
export const createMultilpleInstSafeDeepEqualSelector = (
  reducerArray: Array<string>,
  storeMutatorFunction: (store: Object) => Object,
): Function => (): Object =>
  createSelector(
    reducerArray,
    storeMutatorFunction,
    selectoratorOptions,
  );
