import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Upload, AddPhotoIcon } from '../../../../assets/svg';
import noImage from '../../../../assets/images/noimage.png';
import './styles.scss';

type Props = {
  handleBlur: Function,
  setFieldValue: Function,
  checkDimensionValidity: Function,
  form: Object,
  t: Function,
};

type State = {
  image: any,
  isValidDimension: boolean,
  showError: boolean,
  fileName: string,
};

class FileInput extends PureComponent<Props, State> {
  state = {
    showError: false,
    image: null,
    isValidDimension: true,
    fileName: '',
  };

  static getDerivedStateFromProps = (nextProps: Object, prevState: Object) => {
    if (prevState.image === null) return { image: nextProps.field.value };
    return {};
  };

  triggerInputFile = (e: Object) => {
    e.preventDefault();
    this.fileInput.click();
  };

  clearErrorState = () => {
    this.setState({
      showError: false,
      isValidDimension: false,
    });
  };

  validateDimension = (image: Object) =>
    image.naturalHeight <= 550 && image.naturalWidth <= 1000;

  handleImageChange(e: Object) {
    e.preventDefault();
    // reset dimension acceptance every file
    // upload changes
    this.setState({ isValidDimension: false, fileName: '', image: '' });
    const file = e.target.files[0];
    const reader = new FileReader();
    this.clearErrorState();
    if (file) {
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result.toString();
        image.onload = () => {
          const isValidDimension = this.validateDimension(image);
          this.props.checkDimensionValidity(isValidDimension);
          this.setState({
            isValidDimension,
            showError: !isValidDimension,
          });
        };
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          image: reader.result,
        });
        this.props.form.setFieldValue('file', {
          file,
        });
        this.props.form.setFieldTouched('file', true);
        this.setState({ fileName: file.name });
      };
    }
    this.props.form.handleChange(e);
  }

  fileInput: any;

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="file-upload">
          <div className="restaurantEditInputField">
            <button
              type="button"
              onClick={e => this.triggerInputFile(e)}
              className="edit-restaurant-field-upload-btn"
            >
              {this.state.fileName ? <Upload /> : <AddPhotoIcon />}
              <span className="edit-restaurant-upload-text">
                {this.state.fileName || `${t('browse')}`}
              </span>
            </button>
            <input
              id="file"
              name="file"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref={fileInput => {
                this.fileInput = fileInput;
              }}
              onChange={e => this.handleImageChange(e)}
              onBlur={this.props.form.handleBlur}
              className="restaurantEditInputField"
            />
          </div>
          <div className="edit-restaurant-upload-guide">
            <div style={{ color: 'red' }}>
              {this.props.form.errors.file && this.props.form.touched.file
                ? t(`${this.props.form.errors.file}`)
                : ''}
              {!this.state.isValidDimension && this.state.showError
                ? `${t('imageErrorDimension')}`
                : ''}
            </div>
          </div>
        </div>
        <div className={!this.state.image ? 'error-padding' : ''} />
        <div
          // className={
          //   this.state.isValidDimension || this.state.image
          //     ? `uploaded-image-container`
          //     : `hide`
          // }
          className="uploaded-image-container"
        >
          <button
            type="button"
            onClick={e => this.triggerInputFile(e)}
            className="edit-restaurant-upload-btn"
          >
            {this.state.image === '' ? (
              <img className="edit-restaurant-logo" src={noImage} alt="..." />
            ) : (
              <img
                className="edit-restaurant-logo"
                src={this.state.image}
                alt="..."
              />
            )}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation()(FileInput);
