import { firebaseSignin, firebaseSignOut } from './firebaseAuth.service';
import api from '../api/Api.service';

export const loginService = async (
  email: string,
  password: string,
  dispatch: Function,
) => {
  const errMsg = { error: 'Invalid Username or Password' };

  try {
    const { isAdmin, error } = await firebaseSignin(email, password);
    if (error) return errMsg;
    if (!isAdmin) {
      dispatch({ type: 'enableFetch' });
      await api.getApi().post('restaurants/login', { email, password });
    }
    dispatch({ type: 'restaurantAuth', payload: { restaurantAuth: true } });
    return { error: null };
  } catch (err) {
    dispatch({ type: 'restaurantAuth', payload: { restaurantAuth: false } });
    return errMsg;
  } finally {
    dispatch({ type: 'disableFetch' });
  }
};

export const logoutService = (dispatch: Function) => {
  dispatch({ type: 'disableFetch' });
  return firebaseSignOut();
};
