/* eslint-disable no-underscore-dangle */
import React, { PureComponent } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CommonModal } from '../../../components/common/modal';
import { Header } from '../../../components/common/header';
import { Sidebar } from '../../../components/common/sidebar';
import Button from '../../../components/primereact/buttons';
import { InputText } from '../../../components/primereact/inputs';
import ProgressSpinner from '../../../components/primereact/misc';
import addRestaurantSchema from './adminRestaurant.validation';
import './styles.scss';
import { addRestaurantRequestAction } from '../store/admin.request';
import { restaurantAddSelector } from '../store/admin.selector';

type State = {
  initialValues: Object,
  isSidebarOpen: boolean,
  currentWidth: number,
  initialValues: Object,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
  isShowMessage: boolean,
};

export const defaultInitialValues = {
  email: '',
  password: '',
};

class AdminRestaurantAdd extends PureComponent<*, State> {
  state = {
    initialValues: defaultInitialValues,
    isSidebarOpen: false,
    currentWidth: window.innerWidth,
    isDisconnected: false,
    isErrorModalOpen: false,
    isShowMessage: false,
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({ currentWidth: window.innerWidth });
    });

    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});

    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onMenuClick = () => {
    this.setState(prevState => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  validState = (props: Object) => {
    if (props.isValid) return false;
    return true;
  };

  submitForm = (data: Object) => {
    this.props.addRestaurant(data);

    this.setState({
      isShowMessage: true,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div className="restaurant-container">
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onClick={() => {}}
            onDeleteGetValue={() => {}}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <Sidebar
          currentWidth={this.state.currentWidth}
          onOverlayClick={this.onMenuClick}
          isSidebarOpen={this.state.isSidebarOpen}
          currentPage="restaurants"
          userType="admin"
        />
        <div
          className={
            this.state.isSidebarOpen
              ? 'restaurantEditPageContainerUnscrollable'
              : 'restaurantEditPageContainer'
          }
        >
          <Header onMenuClick={this.onMenuClick} />
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.submitForm}
            validationSchema={addRestaurantSchema}
            render={props => (
              <Form>
                <div className="restaurant-title">
                  <Button
                    icon="pi pi-arrow-left"
                    type="button"
                    className="restaurant-button restaurant-button--icon"
                    onClick={() => this.props.history.goBack()}
                  />
                  {t('issueAccount')}
                  <div className="pull-right">
                    <div>
                      {this.props.status === 201 &&
                      this.state.isShowMessage ? null : (
                        <div>
                          <Button
                            label={t('create')}
                            type="submit"
                            className={
                              this.props.loading
                                ? 'restaurant-button restaurant-button--save transparent-text'
                                : 'restaurant-button restaurant-button--save'
                            }
                            onClick={() => null}
                            disabled={
                              this.validState(props) || this.props.loading
                            }
                          />
                          {this.props.loading && (
                            <ProgressSpinner
                              loadingText=""
                              className="restaurant-spinner restaurant-spinner--button"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <fieldset
                  className="restaurant-fieldset"
                  disabled={this.props.loading}
                >
                  {this.props.status === 201 && this.state.isShowMessage && (
                    <div className="issue-account-banner">
                      {t('issueAccountMessage')}
                    </div>
                  )}
                  {this.props.status === 400 && this.state.isShowMessage && (
                    <div className="issue-account-banner issue-account-banner--error">
                      {t('issueAccountErrMessage')}
                    </div>
                  )}
                  <div className="restaurant-main">
                    <div className="restaurant-main-inner">
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('emailAddress')}
                          </div>
                          {this.props.status === 201 &&
                          this.state.isShowMessage ? (
                            <div className="restaurant-form-group__data">
                              {props.values.email}
                            </div>
                          ) : (
                            <div className="restaurant-form-group__data">
                              <InputText
                                id="email"
                                name="email"
                                type="email"
                                className="restaurant-form-input"
                                value={props.values.email}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                              <ErrorMessage name="email">
                                {msg => (
                                  <div className="restaurant-form-error">
                                    {t(`${msg}`)}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="restaurant-column">
                        <div className="restaurant-form-group">
                          <div className="restaurant-form-group__label">
                            {t('password')}
                          </div>
                          {this.props.status === 201 &&
                          this.state.isShowMessage ? (
                            <div className="restaurant-form-group__data">
                              {props.values.password}
                            </div>
                          ) : (
                            <div className="restaurant-form-group__data">
                              <InputText
                                id="password"
                                name="password"
                                type="password"
                                className="restaurant-form-input"
                                value={props.values.password}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                              <ErrorMessage name="password">
                                {msg => (
                                  <div className="restaurant-form-error">
                                    {t(`${msg}`)}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object) => restaurantAddSelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  addRestaurant: (data: Object) => dispatch(addRestaurantRequestAction(data)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminRestaurantAdd),
);
