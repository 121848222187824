import * as yup from 'yup';
import i18n from '../../../utils/i18n';

const addRestaurantSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('invalidEmailAddress'))
    .required(i18n.t('required')),
  password: yup
    .string()
    .min(8, i18n.t('passwordLength'))
    .required(i18n.t('required')),
});

export default addRestaurantSchema;
