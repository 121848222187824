import React, { PureComponent } from 'react';

type Props = {};

class PinIcon extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99992 0C2.80476 0 0.205322 2.59944 0.205322 5.79456C0.205322 9.75981 5.39092 15.581 5.6117 15.8269C5.81907 16.0579 6.18114 16.0575 6.38813 15.8269C6.60892 15.581 11.7945 9.75981 11.7945 5.79456C11.7944 2.59944 9.19504 0 5.99992 0ZM5.99992 8.70997C4.39235 8.70997 3.08454 7.40212 3.08454 5.79456C3.08454 4.187 4.39238 2.87919 5.99992 2.87919C7.60745 2.87919 8.91526 4.18703 8.91526 5.79459C8.91526 7.40216 7.60745 8.70997 5.99992 8.70997Z"
          fill="#E7251E"
        />
      </svg>
    );
  }
}

export default PinIcon;
