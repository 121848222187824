import React, { PureComponent, Fragment } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LogoutBtn from '../../../containers/auth/Logout';
import {
  Logo,
  Users,
  Restaurant,
  Information,
  Coupon,
  ArrowHidden,
  ArrowExpanded,
  Logout,
} from '../../../assets/svg';
import Accordion from './Accordion';
import { env } from '../../../config/config';
import './styles.scss';

type Props = {
  userType: 'admin' | 'restaurant',
  isSidebarOpen: boolean,
  onOverlayClick: Function,
  currentWidth: number,
  currentPage?: string,
  isInSubmenu?: boolean,
  isInRestaurantSubMenu?: boolean,
  isInCouponsSubMenu?: boolean,
  isInInformationSubMenu?: boolean,
  availableActiveCoupons?: number,
  t: Function,
};

type State = {
  isInformationOpen: boolean,
  page: string,
};

class Sidebar extends PureComponent<Props, State> {
  static defaultProps = {
    currentPage: '',
    isInSubmenu: false,
    isInRestaurantSubMenu: false,
    isInCouponsSubMenu: false,
    isInInformationSubMenu: false,
    availableActiveCoupons: 0,
  };

  state = {
    isInformationOpen: false,
    page: '',
  };

  onInformationClick = (e: Object) => {
    e.preventDefault();
    this.setState(prevState => ({
      isInformationOpen: !prevState.isInformationOpen,
    }));
  };

  handleClick = (destination: string, e: Object) => {
    e.preventDefault();
    this.setState({
      page: destination,
    });
  };

  getSubmenu = () => {
    if (this.props.isInSubmenu) {
      return !this.state.isInformationOpen && this.props.isInSubmenu
        ? `sidebar-submenu`
        : `siderbar-submenu hidden`;
    }
    return this.state.isInformationOpen
      ? `sidebar-submenu`
      : `siderbar-submenu hidden`;
  };

  getDropdownIndicator = (cb?: string) => {
    if (cb === 'information') {
      if (this.props.isInSubmenu) {
        return !this.state.isInformationOpen && this.props.isInSubmenu ? (
          <ArrowExpanded />
        ) : (
          <ArrowHidden />
        );
      }
      return this.state.isInformationOpen ? <ArrowExpanded /> : <ArrowHidden />;
    }
    return false;
  };

  getIndicatorMargin = (cb?: string) => {
    if (cb === 'information') {
      if (this.props.isInSubmenu) {
        return !this.state.isInformationOpen && this.props.isInSubmenu
          ? 'dropdown-indicator dropdown-toggle'
          : 'dropdown-indicator';
      }
      return this.state.isInformationOpen
        ? 'dropdown-indicator dropdown-toggle'
        : 'dropdown-indicator';
    }
    return false;
  };

  getSidebarContent = () => {
    const { userType, currentPage, t } = this.props;
    const isProduction = env === 'production';

    if (userType === 'restaurant') {
      return (
        <div className="sidebarButtonContainer">
          <div>
            <Accordion>
              <div
                label={t('store')}
                icon={<Restaurant />}
                isOpen={this.props.isInRestaurantSubMenu}
              >
                <NavLink
                  to="/store/information/details"
                  className="sidebar-button"
                  isActive={(match, location) => {
                    return location.pathname.includes('/store/information/');
                  }}
                >
                  <span className="dropdown-items">{t('information')}</span>
                </NavLink>
                <NavLink to="/store/evaluation/list" className="sidebar-button">
                  <span className="dropdown-items">{t('evaluation')}</span>
                </NavLink>
                <NavLink
                  to="/store/menu/list"
                  className="sidebar-button"
                  isActive={(match, location) => {
                    return location.pathname.includes('/store/menu/');
                  }}
                >
                  <span className="dropdown-items">{t('menus')}</span>
                </NavLink>
              </div>
              <div
                label={t('coupons')}
                icon={<Coupon />}
                isOpen={this.props.isInCouponsSubMenu}
              >
                {isProduction ? (
                  <span
                    to="/store/usage-histories/list"
                    className="sidebar-button sidebar-button__disabled"
                  >
                    <span className="dropdown-items">
                      {t('usageHistories')}
                    </span>
                  </span>
                ) : (
                  <NavLink
                    to="/store/usage-histories/list"
                    className="sidebar-button"
                  >
                    <span className="dropdown-items">
                      {t('usageHistories')}
                    </span>
                  </NavLink>
                )}

                <NavLink
                  to="/store/print-qr-code/details"
                  className="sidebar-button"
                >
                  <span className="dropdown-items">{t('printQRCode')}</span>
                </NavLink>
              </div>
              <div
                label={t('information')}
                icon={<Information />}
                isOpen={this.props.isInInformationSubMenu}
              >
                {isProduction ? (
                  <span
                    to="/store/campaign/list"
                    className="sidebar-button sidebar-button__disabled"
                  >
                    <span className="dropdown-items">{t('campaign')}</span>
                  </span>
                ) : (
                  <NavLink
                    to="/store/campaign/list"
                    className="sidebar-button"
                    isActive={(match, location) => {
                      return location.pathname.includes('/store/campaign/');
                    }}
                  >
                    <span className="dropdown-items">{t('campaign')}</span>
                  </NavLink>
                )}
                {isProduction ? (
                  <span
                    to="/store/what-to-eat-today/list"
                    className="sidebar-button sidebar-button__disabled"
                    isActive={(match, location) => {
                      return location.pathname.includes(
                        '/store/what-to-eat-today/',
                      );
                    }}
                  >
                    <span className="dropdown-items">
                      {t('whatToEatToday')}
                    </span>
                  </span>
                ) : (
                  <NavLink
                    to="/store/what-to-eat-today/list"
                    className="sidebar-button"
                    isActive={(match, location) => {
                      return location.pathname.includes(
                        '/store/what-to-eat-today/',
                      );
                    }}
                  >
                    <span className="dropdown-items">
                      {t('whatToEatToday')}
                    </span>
                  </NavLink>
                )}
                {isProduction ? (
                  <span
                    to="/store/hiring/list"
                    className="sidebar-button sidebar-button__disabled"
                    isActive={(match, location) => {
                      return location.pathname.includes('/store/hiring/');
                    }}
                  >
                    <span className="dropdown-items">{t('hiring')}</span>
                  </span>
                ) : (
                  <NavLink
                    to="/store/hiring/list"
                    className="sidebar-button"
                    isActive={(match, location) => {
                      return location.pathname.includes('/store/hiring/');
                    }}
                  >
                    <span className="dropdown-items">{t('hiring')}</span>
                  </NavLink>
                )}
                {isProduction ? (
                  <span
                    to="/store/contact"
                    className="sidebar-button sidebar-button__disabled"
                  >
                    <span className="dropdown-items">{t('contactWG')}</span>
                  </span>
                ) : (
                  <NavLink to="/store/contact" className="sidebar-button">
                    <span className="dropdown-items">{t('contactWG')}</span>
                  </NavLink>
                )}
                <span className="sidebar-button sidebar-button__disabled">
                  <span className="dropdown-items">{t('usersMap')}</span>
                </span>
              </div>
            </Accordion>
            <div className="store-points">
              {t('availableCoupons')}
              {`: `}
              {this.props.availableActiveCoupons}
            </div>
          </div>
          <div>
            <LogoutBtn className="sidebar-button logout-button">
              <div className="admin-items">
                <Logout className="logout-sidebar" />
              </div>
              <div className="dropdown-indicator">{t('logout')}</div>
            </LogoutBtn>
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <div className="sidebarButtonContainer">
          <div>
            <button
              onClick={e => this.handleClick('restaurants', e)}
              type="button"
              className="sidebar-button"
            >
              <div className="admin-items">
                <Restaurant
                  className={
                    currentPage === 'restaurants'
                      ? 'current-page-color-svg'
                      : ''
                  }
                />
              </div>
              <div
                className={
                  currentPage === 'restaurants'
                    ? `dropdown-indicator current-page-text`
                    : `dropdown-indicator`
                }
              >
                {t('store')}
              </div>
            </button>
            <button
              onClick={e => this.handleClick('coupon', e)}
              type="button"
              className="sidebar-button"
            >
              <div className="admin-items">
                <Coupon
                  className={
                    currentPage === 'coupon' ? 'current-page-color-svg' : ''
                  }
                />
              </div>
              <div
                className={
                  currentPage === 'coupon'
                    ? `dropdown-indicator current-page-text`
                    : `dropdown-indicator`
                }
              >
                {t('coupon')}
              </div>
            </button>
            <button
              onClick={e => this.handleClick('users', e)}
              type="button"
              className="sidebar-button"
            >
              <div className="admin-items">
                <Users
                  className={
                    currentPage === 'users' ? 'current-page-color-svg' : ''
                  }
                />
              </div>
              <div
                className={
                  currentPage === 'users'
                    ? `dropdown-indicator current-page-text`
                    : `dropdown-indicator`
                }
              >
                {t('users')}
              </div>
            </button>
            <button
              type="button"
              onClick={e => this.onInformationClick(e)}
              className="sidebar-button"
            >
              <div className={this.getIndicatorMargin('information')}>
                {this.getDropdownIndicator('information')}
              </div>
              <div className="dropdown-indicator">
                <Information />
              </div>
              <span className="dropdown-indicator">{t('information')}</span>
            </button>
            <div className={this.getSubmenu()}>
              <button
                onClick={e => this.handleClick('campaign', e)}
                type="button"
                className="sidebar-button"
              >
                <span
                  className={
                    this.props.currentPage === 'campaign'
                      ? 'dropdown-items current-page-text'
                      : 'dropdown-items'
                  }
                >
                  {t('campaign')}
                </span>
              </button>
              <button
                onClick={e => this.handleClick('what-to-eat-today', e)}
                type="button"
                className="sidebar-button"
              >
                <span
                  className={
                    this.props.currentPage === 'what-to-eat-today'
                      ? 'dropdown-items current-page-text'
                      : 'dropdown-items'
                  }
                >
                  {t('whatToEatToday')}
                </span>
              </button>
              <button
                onClick={e => this.handleClick('hiring', e)}
                type="button"
                className="sidebar-button"
              >
                <span
                  className={
                    this.props.currentPage === 'hiring'
                      ? 'dropdown-items current-page-text'
                      : 'dropdown-items'
                  }
                >
                  {t('hiring')}
                </span>
              </button>
              <button
                onClick={e => this.handleClick('notification', e)}
                type="button"
                className="sidebar-button"
              >
                <span
                  className={
                    this.props.currentPage === 'notification'
                      ? 'dropdown-items current-page-text'
                      : 'dropdown-items'
                  }
                >
                  {t('app')}
                </span>
              </button>
              <button
                onClick={() => {}}
                type="button"
                className="sidebar-button"
              >
                <span className="dropdown-items p-disabled">
                  {t('usersMap')}
                </span>
              </button>
            </div>
          </div>
          <div>
            <LogoutBtn className="sidebar-button logout-button">
              <div className="admin-items">
                <Logout className="logout-sidebar" />
              </div>
              <div className="dropdown-indicator">{t('logout')}</div>
            </LogoutBtn>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { isSidebarOpen, onOverlayClick, currentWidth } = this.props;

    if (this.state.page === 'logout') {
      return <Redirect to="/" push />;
    }
    if (
      this.state.page === 'restaurants' &&
      this.props.currentPage !== 'restaurants'
    ) {
      return <Redirect to="/admin/restaurant/list" push />;
    }
    if (this.state.page === 'users' && this.props.currentPage !== 'users') {
      return <Redirect to="/admin/users/list" push />;
    }
    if (this.state.page === 'coupon' && this.props.currentPage !== 'coupon') {
      return <Redirect to="/admin/coupon/details" push />;
    }
    if (
      this.state.page === 'campaign' &&
      this.props.currentPage !== 'campaign'
    ) {
      return <Redirect to="/admin/campaign/list" push />;
    }
    if (
      this.state.page === 'what-to-eat-today' &&
      this.props.currentPage !== 'what-to-eat-today'
    ) {
      return <Redirect to="/admin/what-to-eat-today/list" push />;
    }
    if (this.state.page === 'hiring' && this.props.currentPage !== 'hiring') {
      return <Redirect to="/admin/hiring/list" push />;
    }
    if (
      this.state.page === 'notification' &&
      this.props.currentPage !== 'notification'
    ) {
      return <Redirect to="/admin/notification/list" push />;
    }

    return (
      <>
        <div
          className={
            isSidebarOpen && currentWidth <= 768 ? `overlay active ` : `overlay`
          }
          onClick={onOverlayClick}
          tabIndex={0}
          onKeyUp={null}
          role="button"
        />
        <div
          className={
            isSidebarOpen ? `sidebar-sidebar slide-in` : `sidebar-sidebar`
          }
        >
          <div className="sidebar-logo">
            <Logo />
          </div>
          {this.getSidebarContent()}
        </div>
      </>
    );
  }
}

export default withTranslation()(Sidebar);
