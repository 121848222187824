import i18n from '../../utils/i18n';

export const IMAGE_URL =
  'https://us-central1-world-gourmet-3db5a.cloudfunctions.net/app/images/';

export const MAP_API_KEY = 'AIzaSyAFizd8MP3Fj1SmPImAMX5-kOhaxPLfA0E';

export const GOOGLE_MAPS_QUERY = 'https://maps.google.com/maps?q=';

export const activeOptions = [
  { key: 1, label: 'On', value: 'on' },
  { key: 2, label: 'Off', value: 'off' },
];

export const genderOptions = [
  { key: 1, label: i18n.t('male'), value: 'male' },
  { key: 2, label: i18n.t('female'), value: 'female' },
  { key: 3, label: i18n.t('others'), value: 'others' },
];

export const cuisineOptions = [
  { label: i18n.t('japanese'), value: 'japanese' },
  { label: i18n.t('korean'), value: 'korean' },
  { label: i18n.t('chinese'), value: 'chinese' },
];

export const budgetOptions = [
  { label: '¥', value: '1' },
  { label: '¥¥', value: '2' },
  { label: '¥¥¥', value: '3' },
];

export const livingPrefecture = [
  { key: 1, label: i18n.t('hokkaido'), value: 'Hokkaido' },
  { key: 2, label: i18n.t('aomori'), value: 'Aomori' },
  { key: 3, label: i18n.t('iwate'), value: 'Iwate' },
  { key: 4, label: i18n.t('miyagi'), value: 'Miyagi' },
  { key: 5, label: i18n.t('akita'), value: 'Akita' },
  { key: 6, label: i18n.t('yamagata'), value: 'Yamagata' },
  { key: 7, label: i18n.t('fukushima'), value: 'Fukushima' },
  { key: 8, label: i18n.t('ibaraki'), value: 'Ibaraki' },
  { key: 9, label: i18n.t('tochigi'), value: 'Tochigi' },
  { key: 10, label: i18n.t('gunma'), value: 'Gunma' },
  { key: 11, label: i18n.t('saitama'), value: 'Saitama' },
  { key: 12, label: i18n.t('chiba'), value: 'Chiba' },
  { key: 13, label: i18n.t('tokyo'), value: 'Tokyo' },
  { key: 14, label: i18n.t('kanagawa'), value: 'Kanagawa' },
  { key: 15, label: i18n.t('niigata'), value: 'Niigata' },
  { key: 16, label: i18n.t('toyama'), value: 'Toyama' },
  { key: 17, label: i18n.t('ishikawa'), value: 'Ishikawa' },
  { key: 18, label: i18n.t('fukui'), value: 'Fukui' },
  { key: 19, label: i18n.t('yamanashi'), value: 'Yamanashi' },
  { key: 20, label: i18n.t('nagano'), value: 'Nagano' },
  { key: 21, label: i18n.t('gifu'), value: 'Gifu' },
  { key: 22, label: i18n.t('shizuoka'), value: 'Shizuoka' },
  { key: 23, label: i18n.t('aichi'), value: 'Aichi' },
  { key: 24, label: i18n.t('mie'), value: 'Mie' },
  { key: 25, label: i18n.t('shiga'), value: 'Shiga' },
  { key: 26, label: i18n.t('kyoto'), value: 'Kyoto' },
  { key: 27, label: i18n.t('osaka'), value: 'Osaka' },
  { key: 28, label: i18n.t('hyogo'), value: 'Hyogo' },
  { key: 29, label: i18n.t('nara'), value: 'Nara' },
  { key: 30, label: i18n.t('wakayama'), value: 'Wakayama' },
  { key: 31, label: i18n.t('tottori'), value: 'Tottori' },
  { key: 32, label: i18n.t('shimane'), value: 'Shimane' },
  { key: 33, label: i18n.t('okayama'), value: 'Okayama' },
  { key: 34, label: i18n.t('hiroshima'), value: 'Hiroshima' },
  { key: 35, label: i18n.t('yamaguchi'), value: 'Yamaguchi' },
  { key: 36, label: i18n.t('tokushima'), value: 'Tokushima' },
  { key: 37, label: i18n.t('kagawa'), value: 'Kagawa' },
  { key: 38, label: i18n.t('ehime'), value: 'Ehime' },
  { key: 39, label: i18n.t('kochi'), value: 'Kochi' },
  { key: 40, label: i18n.t('fukuoka'), value: 'Fukuoka' },
  { key: 41, label: i18n.t('saga'), value: 'Saga' },
  { key: 42, label: i18n.t('nagasaki'), value: 'Nagasaki' },
  { key: 43, label: i18n.t('kumamoto'), value: 'Kumamoto' },
  { key: 44, label: i18n.t('oita'), value: 'Oita' },
  { key: 45, label: i18n.t('miyazaki'), value: 'Miyazaki' },
  { key: 46, label: i18n.t('kagoshima'), value: 'Kagoshima' },
  { key: 47, label: i18n.t('okinawa'), value: 'Okinawa' },
];
