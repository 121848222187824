/* eslint-disable no-underscore-dangle */
import React, { PureComponent, Fragment } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Growl } from 'primereact/growl';
import { CommonModal } from '../../../components/common/modal';
import { Map } from '../../../components/common/map';
import { FileInput } from '../../../components/common/input';
import { PinIcon } from '../../../assets/svg';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
  Dropdown,
} from '../../../components/primereact/inputs';
import ProgressSpinner from '../../../components/primereact/misc';
import RestaurantSchedule from './restaurantSchedule';
import CuisineMultiSelect from './cuisineMultiSelect';
import restaurantSchema from './restaurantInformationEdit.validation';
import { MAP_API_KEY, GOOGLE_MAPS_QUERY, budgetOptions } from '../../constants';
import { IMAGE_URL } from '../../../config/config';
import noImage from '../../../assets/images/noimage.png';
import './styles.scss';
import { defaultInitialValues } from '../restaurantInformationDetails/RestaurantInformationDetails';
import { updateRestaurantAction } from '../../admin/store/admin.request';
import { restaurantUpdateSelector } from '../../admin/store/admin.selector';

type State = {
  isMapOpen: boolean,
  initialValues: Object,
  isDimensionAccepted: boolean,
  isShowMessage: boolean,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
  currentUserId: string | null,
};

class RestaurantInformationEdit extends PureComponent<*, State> {
  static buildEditableData = (data: Object) => {
    const editData = { ...data };
    const operationHours = {
      monday: { to: '00:00', from: '00:00', closed: false },
      tuesday: { to: '00:00', from: '00:00', closed: false },
      wednesday: { to: '00:00', from: '00:00', closed: false },
      thursday: { to: '00:00', from: '00:00', closed: false },
      friday: { to: '00:00', from: '00:00', closed: false },
      saturday: { to: '00:00', from: '00:00', closed: false },
      sunday: { to: '00:00', from: '00:00', closed: false },
    };

    return {
      image: editData.image ? `${IMAGE_URL}${editData.image}` : noImage,
      name: editData.name,
      usageRate: editData.usageRate,
      budget: editData.budget,
      cuisine: editData.cuisine,
      location: editData.location,
      googleMapsUrl: editData.googleMapsUrl,
      homepageUrl: editData.homepageUrl,
      address: editData.address,
      operationHours: editData.operationHours
        ? editData.operationHours
        : operationHours,
      description: editData.description,
      telephoneNumber: editData.telephoneNumber,
    };
  };

  static getDerivedStateFromProps = (nextProps: Object) => {
    let data;
    Object.keys(nextProps).forEach((key: string) => {
      if (key === 'location') {
        const { state } = nextProps.location;
        if (state) {
          data = this.buildEditableData(state);
        }
      }
    });
    if (data) return { initialValues: { ...data } };
    return { initialValues: { ...defaultInitialValues } };
  };

  state = {
    isMapOpen: false,
    isDimensionAccepted: true,
    isShowMessage: false,
    initialValues: null,
    isDisconnected: false,
    isErrorModalOpen: false,
    currentUserId: null,
  };

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      this.setState({
        currentUserId: uid,
      });
    }
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showInfo();
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  onEditClick = () => {
    this.setState({
      isMapOpen: false,
    });
  };

  checkDimensionValidity = (status: boolean) => {
    this.setState({
      isDimensionAccepted: status,
    });
  };

  onPinClick = () => {
    this.setState(prevState => ({
      isMapOpen: !prevState.isMapOpen,
    }));
  };

  submitForm = (data: Object) => {
    const d = {
      ...data,
      googleMapsUrl: `${GOOGLE_MAPS_QUERY}${data.location._latitude},${data.location._longitude}`,
    };

    this.props.updateRestaurant(
      { data: d, selectedId: this.state.currentUserId },
      this.state.currentUserId,
    );

    this.setState({
      isShowMessage: true,
    });
  };

  showInfo = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'info',
      summary: t('toastSuccessSummmary'),
      detail: t('toastSuccessDetail'),
    });
  };

  showError = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'error',
      summary: t('toastErrorSummary'),
      detail: t('toastErrorDetail'),
    });
  };

  validState = (props: Object) => {
    if (props.isValid && this.state.isDimensionAccepted) return false;
    return true;
  };

  // eslint-disable-next-line consistent-return
  handlePhoneNumberFormat = (value, country) => {
    const phoneNumber = parsePhoneNumberFromString(value, country);

    if (phoneNumber) {
      return phoneNumber.number;
    }

    return value;
  };

  growl: any;

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onClick={() => {}}
            onDeleteGetValue={() => {}}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <Formik
          initialValues={this.state.initialValues}
          onSubmit={this.submitForm}
          validationSchema={restaurantSchema}
          render={props => (
            <Form>
              <div className="restaurant-title">
                <Button
                  icon="pi pi-arrow-left"
                  type="button"
                  className="restaurant-button restaurant-button--icon"
                  onClick={() => this.props.history.goBack()}
                />
                {t('editStoreInfo')}
                <div className="pull-right">
                  <div>
                    <Button
                      label={t('save')}
                      type="submit"
                      className={
                        this.props.loading
                          ? 'restaurant-button restaurant-button--save transparent-text'
                          : 'restaurant-button restaurant-button--save'
                      }
                      onClick={this.onEditClick}
                      disabled={this.validState(props) || this.props.loading}
                    />
                    {this.props.loading && (
                      <ProgressSpinner className="restaurant-spinner restaurant-spinner--button" />
                    )}
                  </div>
                </div>
              </div>
              <fieldset
                className="restaurant-fieldset"
                disabled={this.props.loading}
              >
                <div className="restaurant-main">
                  <div className="restaurant-main-inner">
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('name')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="name"
                            name="name"
                            type="text"
                            className="restaurant-form-input"
                            value={props.values.name ? props.values.name : ''}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="name">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('description')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputTextarea
                            id="description"
                            name="description"
                            className="restaurant-form-input"
                            value={
                              props.values.description
                                ? props.values.description
                                : ''
                            }
                            rows={5}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="description">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('cuisine')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <CuisineMultiSelect
                            value={
                              props.values.cuisine
                                ? props.values.cuisine.map(cuisine => cuisine)
                                : []
                            }
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            disabled={this.props.loading}
                          />
                          <ErrorMessage name="cuisine">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('usageRate')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <div className="p-inputgroup">
                            <InputText
                              id="usageRate"
                              name="usageRate"
                              type="number"
                              keyfilter="pnum"
                              className="restaurant-form-input"
                              value={
                                props.values.usageRate >= 0
                                  ? props.values.usageRate
                                  : ''
                              }
                              onChange={e => {
                                props.setFieldValue(
                                  'usageRate',
                                  e.target.value !== ''
                                    ? e.target.value.replace(
                                        /\b(0(?!\b))+/g,
                                        '',
                                      )
                                    : e.target.value,
                                );
                              }}
                              onBlur={props.handleBlur}
                            />
                            <span
                              style={
                                this.props.loading
                                  ? { opacity: '0.5' }
                                  : { opacity: 'initial' }
                              }
                              className="p-inputgroup-addon p-inputgroup-addon--percent"
                            >
                              %
                            </span>
                          </div>
                          <ErrorMessage name="usageRate">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('budget')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <Dropdown
                            id="budget"
                            name="budget"
                            className="restaurant-form-input restaurant-form-input--dropdown"
                            placeholder="Select a budget"
                            options={budgetOptions}
                            value={
                              props.values.budget ? props.values.budget : ''
                            }
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            disabled={this.props.loading}
                          />
                          <ErrorMessage name="budget">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('location')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <div
                            className="restaurant-map-container"
                            style={
                              this.props.loading
                                ? { opacity: '0.5' }
                                : { opacity: 'initial' }
                            }
                          >
                            <div className="restaurant-form-input-wrapper">
                              <InputText
                                id="location"
                                name="location"
                                type="text"
                                className="restaurant-form-input restaurant-form-input--map"
                                // eslint-disable-next-line no-underscore-dangle
                                value={`${props.values.location._latitude}, ${props.values.location._longitude}`}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                readOnly
                              />
                              <div
                                onClick={this.onPinClick}
                                onKeyUp={null}
                                role="button"
                                tabIndex="0"
                                className="restaurantEditPinButton"
                              >
                                <PinIcon />
                              </div>
                            </div>
                            {!this.state.isMapOpen || (
                              <div className="restaurantEditMap">
                                <Field
                                  component={Map}
                                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}`}
                                  loadingElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  containerElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  mapElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  center={{
                                    lat:
                                      props.values.location._latitude === 0
                                        ? 35.680167363485616
                                        : props.values.location._latitude,
                                    lng:
                                      props.values.location._longitude === 0
                                        ? 139.76943969726562
                                        : props.values.location._longitude,
                                  }}
                                  zoom={15}
                                  hasMarker
                                  isMarkerDraggable
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('address')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="address"
                            name="address"
                            type="text"
                            className="restaurant-form-input"
                            value={
                              props.values.address ? props.values.address : ''
                            }
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="address">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('telephoneNum')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="telephoneNumber"
                            name="telephoneNumber"
                            type="tel"
                            className="restaurant-form-input"
                            value={
                              props.values.telephoneNumber
                                ? props.values.telephoneNumber
                                : ''
                            }
                            onChange={e =>
                              props.setFieldValue(
                                'telephoneNumber',
                                this.handlePhoneNumberFormat(
                                  e.target.value,
                                  t('countryCode'),
                                ),
                              )
                            }
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="telephoneNumber">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('homepageURL')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="homepageUrl"
                            name="homepageUrl"
                            type="url"
                            className="restaurant-form-input"
                            value={
                              props.values.homepageUrl
                                ? props.values.homepageUrl
                                : ''
                            }
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          <ErrorMessage name="homepageUrl">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                    <div className="restaurant-column">
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('googleMapURL')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <InputText
                            id="googleMapsUrl"
                            name="googleMapsUrl"
                            type="url"
                            className="restaurant-form-input restaurant-form-input--google-map-url"
                            value={`${GOOGLE_MAPS_QUERY}${props.values.location._latitude},${props.values.location._longitude}`}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            readOnly
                            disabled
                          />
                          <ErrorMessage name="googleMapsUrl">
                            {msg => (
                              <div className="restaurant-form-error">
                                {t(`${msg}`)}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('logo')}
                        </div>
                        <div className="restaurant-form-group__data restaurant-form-group__data--fileupload">
                          <div
                            style={
                              this.props.loading
                                ? { opacity: '0.5' }
                                : { opacity: 'initial' }
                            }
                          >
                            <Field
                              name="image"
                              component={FileInput}
                              checkDimensionValidity={
                                this.checkDimensionValidity
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="restaurant-form-group">
                        <div className="restaurant-form-group__label">
                          {t('operationHours')}
                        </div>
                        <div className="restaurant-form-group__data">
                          <Field
                            name="operationHours"
                            render={({ field, form }) => {
                              return (
                                <Fragment>
                                  {field.value
                                    ? [
                                        'monday',
                                        'tuesday',
                                        'wednesday',
                                        'thursday',
                                        'friday',
                                        'saturday',
                                        'sunday',
                                      ].map((dayOfWeek, index) => (
                                        <div
                                          className="restaurant-operation-hours__row"
                                          key={`${dayOfWeek}`}
                                        >
                                          <RestaurantSchedule
                                            {...field}
                                            {...form}
                                            day={dayOfWeek}
                                            index={index}
                                            loading={this.props.loading}
                                          />
                                        </div>
                                      ))
                                    : null}
                                </Fragment>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => restaurantUpdateSelector(state);

const mapDispatchToProps = (dispatch: Function) => ({
  updateRestaurant: (payload: Object, restaurantId: string) =>
    dispatch(updateRestaurantAction(payload, restaurantId)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RestaurantInformationEdit),
);
