import React, { useCallback, useContext } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { AuthContext } from './AuthContextProvider';
import { LoginForm } from '../../components/forms';
import { loginService } from '../../service/auth/auth.service';

const Login = (props: Object) => {
  const auth = useContext(AuthContext);

  const submit = useCallback(
    // eslint-disable-next-line consistent-return
    async ({ email, password }) => {
      const goToRoot = () => props.history.push('/');
      const { error } = await loginService(email, password, auth.dispatch);
      if (error) return auth.dispatch({ type: 'error', payload: error });
      goToRoot();
    },
    [auth, props.history],
  );

  return (
    <Route
      exact
      path="/login"
      render={() => <LoginForm handleSubmit={submit} />}
    />
  );
};

export default withRouter(React.memo(Login));
