import React, { PureComponent } from 'react';

type Props = {};

class ImageMaintenance extends PureComponent<Props> {
  render() {
    return (
      <svg
        width="240"
        height="240"
        viewBox="0 0 240 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="120" cy="120" r="120" fill="#FCAE17" />
        <g cliPath="url(#clip0)">
          <path
            d="M49.5112 61.6085C44.9788 71.1412 46.5594 82.8526 54.4491 90.7423C62.3388 98.632 74.0502 100.213 83.5829 95.6802L144.32 156.417C139.787 165.95 141.367 177.662 149.257 185.551C157.148 193.441 168.859 195.021 178.392 190.489L161.356 173.452L173.454 161.354L190.49 178.39C195.022 168.858 193.443 157.146 185.552 149.256C177.662 141.366 165.951 139.786 156.418 144.318L95.6809 83.5822C100.213 74.0507 98.6338 62.3381 90.7441 54.4484C82.8533 46.5576 71.1419 44.9781 61.6092 49.5105L78.645 66.5464L66.5471 78.6444L49.5112 61.6085Z"
            fill="#1F0F08"
          />
          <path
            d="M52.0114 187.988C58.6925 194.671 69.5263 194.671 76.2074 187.988L113.981 150.215L120.03 156.265L126.078 150.215L89.7846 113.921L83.735 119.97L89.7846 126.019L52.0114 163.793C45.3292 170.474 45.3292 181.307 52.0114 187.988ZM92.8088 141.142L98.8572 147.191L67.1336 178.915L61.0852 172.865L92.8088 141.142Z"
            fill="#1F0F08"
          />
          <path
            d="M156.377 57.0807L163.6 64.3032L126.048 101.854L138.146 113.953L175.698 76.4001L182.919 83.6225L193 57.4093L182.592 47L156.377 57.0807Z"
            fill="#1F0F08"
          />
        </g>
        <defs>
          <clipPath id="clip0" />
        </defs>
      </svg>
    );
  }
}

export default ImageMaintenance;
