import React, { PureComponent, Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Growl } from 'primereact/growl';
import { CommonModal } from '../../../components/common/modal';
import { FileInput } from '../../../components/common/input';
import ProgressSpinner from '../../../components/primereact/misc';
import Button from '../../../components/primereact/buttons';
import {
  InputText,
  InputTextarea,
} from '../../../components/primereact/inputs';
import contactSchema from './restaurantContactWorldGourmet.validation';
import { addStoreContact } from '../store/restaurant.request';
import { storeContactSelector } from '../store/restaurant.selector';

type State = {
  initialValues: Object,
  isDimensionAccepted: boolean,
  isShowMessage: boolean,
  isDisconnected: boolean,
  isErrorModalOpen: boolean,
  isModalOpen: boolean,
};

export const defaultInitialValues = {
  email: '',
  subject: '',
  text: '',
  file: null,
  image: '',
};

class ContactWorldGourmetPage extends PureComponent<*, State> {
  state = {
    isDimensionAccepted: true,
    isShowMessage: false,
    initialValues: null,
    isDisconnected: false,
    isErrorModalOpen: false,
    isModalOpen: false,
  };

  static getDerivedStateFromProps = () => {
    let data;

    if (data) return { initialValues: { ...data } };
    return { initialValues: { ...defaultInitialValues } };
  };

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate() {
    if (
      this.state.isShowMessage &&
      this.props.status === 200 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
        isModalOpen: true,
      });
    }

    if (
      this.state.isShowMessage &&
      this.props.status === 400 &&
      !this.props.loading
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowMessage: false,
      });
      this.showError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const ping = setInterval(() => {
        fetch('https://www.google.com/', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState(
              { isDisconnected: false, isErrorModalOpen: false },
              () => {
                return clearInterval(ping);
              },
            );
          })
          .catch(() =>
            this.setState({ isDisconnected: true, isErrorModalOpen: true }),
          );
      }, 2000);
      return;
    }

    this.setState({ isDisconnected: true, isErrorModalOpen: true });
  };

  onErroModalClose = () => {
    this.setState(prevState => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  onModalClose = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  checkDimensionValidity = (status: boolean) => {
    this.setState({
      isDimensionAccepted: status,
    });
  };

  submitForm = (data: Object) => {
    const d = { ...data, email: this.props.email };
    this.props.addStoreContact({ data: d });
    this.setState({
      isShowMessage: true,
    });
  };

  validState = (props: Object) => {
    if (props.isValid && this.state.isDimensionAccepted) return false;
    return true;
  };

  showError = () => {
    const { t } = this.props;
    this.growl.show({
      severity: 'error',
      summary: `${t('toastErrorSummary')}`,
      detail: `${t('toastErrorDetail')}`,
    });
  };

  growl: any;

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Growl
          position="topright"
          ref={el => {
            this.growl = el;
          }}
        />
        <CommonModal
          type="contact"
          isOpen={this.state.isModalOpen}
          message={t('modalContactMessage')}
          onClick={() => {}}
          onDeleteGetValue={() => {}}
          onCloseClick={() => this.onModalClose()}
        />
        {this.state.isDisconnected && (
          <CommonModal
            isOpen={this.state.isErrorModalOpen}
            type="error"
            message={t('checkInternetConnection')}
            onClick={() => {}}
            onDeleteGetValue={() => {}}
            onCloseClick={() => this.onErroModalClose()}
          />
        )}
        <Formik
          initialValues={this.state.initialValues}
          validationSchema={contactSchema}
          onSubmit={this.submitForm}
          render={props => (
            <Form>
              <div className="restaurant-title">
                {t('contactWG')}
                <div className="pull-right">
                  <Button
                    label={t('send')}
                    type="submit"
                    className={
                      this.props.loading
                        ? 'restaurant-button restaurant-button--save transparent-text'
                        : 'restaurant-button restaurant-button--save'
                    }
                    onClick={() => null}
                    disabled={this.validState(props) || this.props.loading}
                  />
                  {this.props.loading && (
                    <ProgressSpinner
                      loadingText=""
                      className="restaurant-spinner restaurant-spinner--button"
                    />
                  )}
                </div>
              </div>
              <div className="restaurant-main">
                <div className="restaurant-main-inner">
                  <div className="restaurant-column">
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('subject')}
                      </div>
                      <div className="restaurant-form-group__data">
                        <InputText
                          id="subject"
                          name="subject"
                          type="text"
                          className="restaurant-form-input"
                          value={props.values.subject}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <ErrorMessage name="subject">
                          {msg => (
                            <div className="restaurant-form-error">
                              {t(`${msg}`)}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('message')}
                      </div>
                      <div className="restaurant-form-group__data">
                        <InputTextarea
                          id="text"
                          name="text"
                          className="restaurant-form-input"
                          value={props.values.text}
                          rows={5}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <ErrorMessage name="text">
                          {msg => (
                            <div className="restaurant-form-error">
                              {t(`${msg}`)}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="restaurant-column">
                    <div className="restaurant-form-group">
                      <div className="restaurant-form-group__label">
                        {t('photoOptional')}
                      </div>
                      <div className="restaurant-form-group__data restaurant-form-group__data--fileupload">
                        <Field
                          name="image"
                          component={FileInput}
                          checkDimensionValidity={this.checkDimensionValidity}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: Object) => storeContactSelector(state);

const mapDispatchToProps = (dispatch: Object) => ({
  addStoreContact: (payload: Object) => dispatch(addStoreContact(payload)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactWorldGourmetPage),
);
