import { takeEvery, put, call } from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';
import {
  updateMenuRequestAction,
  addMenuRequestAction,
  updateStoreMenu,
  addStoreMenu,
  deleteStoreMenu,
  addStoreHiring,
  updateStoreHiring,
  updateHiringRequestAction,
  addHiringRequestAction,
  deleteStoreHiring,
  deleteStoreCampaign,
  addStoreContact,
  addContactRequestAction,
  deleteStoreWhatToEat,
  addWhatToEatRequestAction,
  updateWhatToEatRequestAction,
  addStoreWhatToEat,
  updateStoreWhatToEat,
} from './restaurant.request';
import {
  updateMenuReducerAction,
  deleteMenuReducerAction,
  updateHiringReducerAction,
  deleteHiringReducerAction,
  deleteStoreCampaignReducerAction,
  contactReducerAction,
  deleteWhatToEatReducerAction,
  updateWhatToEatReducerAction,
} from './restaurant.reducer';
import ApiService from '../../../service/api/Api.service';
import { returnErrorResponseAction } from '../../../store/request/request.saga';

function* updateStoreMenuInfo(
  data: Object,
  menuId?: string,
  restaurantId: string,
  photo?: string,
) {
  /* eslint-disable no-param-reassign */
  if (photo) data.photo = photo;
  else delete data.photo;
  if (menuId) yield put(updateMenuRequestAction(data, menuId, restaurantId));
  else yield put(addMenuRequestAction(data, restaurantId));
  /* eslint-enable no-param-reassign */
}

function* updateStoreHiringInfo(
  data: Object,
  image?: string,
  restaurantId: string,
  hiringId?: string,
) {
  /* eslint-disable no-param-reassign */
  if (image) data.image = image;
  else delete data.image;
  if (hiringId) {
    yield put(updateHiringRequestAction(data, restaurantId, hiringId));
  } else {
    delete data.file;
    yield put(addHiringRequestAction(data, restaurantId));
  }
  /* eslint-enable no-param-reassign */
}

function* updateMenuFlow(action: Object) {
  try {
    let result = null;
    const { data, restaurantId, menuId } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(updateMenuReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      delete data.file;
    }

    if (menuId) {
      if (result)
        yield call(
          updateStoreMenuInfo,
          data,
          menuId,
          restaurantId,
          result.response.data.filename,
        );
      else {
        yield call(updateStoreMenuInfo, data, menuId, restaurantId);
      }
    }

    if (result)
      yield call(
        updateStoreMenuInfo,
        data,
        null,
        restaurantId,
        result.response.data.filename,
      );
  } catch (error) {
    yield call(returnErrorResponseAction, error);
  }
}

function* redirectMenuOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(push('/store/menu/list'));
  }
  yield put(deleteMenuReducerAction(action.payload));
}

function* updateHiringFlow(action: Object) {
  try {
    let result = null;
    const { data, restaurantId, hiringId } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(updateHiringReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      delete data.file;
    }

    if (hiringId) {
      if (result)
        yield call(
          updateStoreHiringInfo,
          data,
          result.response.data.filename,
          restaurantId,
          hiringId,
        );
      else {
        yield call(updateStoreHiringInfo, data, null, restaurantId, hiringId);
      }
    }

    if (result) {
      yield call(
        updateStoreHiringInfo,
        data,
        result.response.data.filename,
        restaurantId,
      );
    } else {
      yield call(updateStoreHiringInfo, data, null, restaurantId);
    }
  } catch (error) {
    yield call(returnErrorResponseAction, error);
  }
}

function* redirectHiringOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(goBack());
  }
  yield put(deleteHiringReducerAction(action.payload));
}

function* redirectCampaignOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(push('/store/campaign/list'));
  }
  yield put(deleteStoreCampaignReducerAction(action.payload));
}

function* redirectWhatToEatOnDeleteSuccess(action: Object) {
  const { error, loading } = action.payload;
  if (!error && !loading) {
    yield put(push('/store/what-to-eat-today/list'));
  }
  yield put(deleteWhatToEatReducerAction(action.payload));
}

function* addStoreContactInfo(data: Object, image?: string) {
  /* eslint-disable no-param-reassign */
  if (image) data.image = image;
  else delete data.image;
  delete data.file;
  yield put(addContactRequestAction(data));
  /* eslint-enable no-param-reassign */
}

function* addContactFlow(action: Object) {
  try {
    let result = null;
    const { data } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(contactReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      delete data.file;
    }

    if (result) {
      yield call(addStoreContactInfo, data, result.response.data.filename);
    } else {
      yield call(addStoreContactInfo, data);
    }
  } catch (error) {
    yield call(returnErrorResponseAction, error, contactReducerAction);
  }
}

function* updateStoreWhatToEatInfo(
  data: Object,
  whatToEatId?: string,
  restaurantId: string,
  image?: string,
) {
  /* eslint-disable no-param-reassign */
  if (image) data.image = image;
  else delete data.image;
  // TODO: Remove line below once delivery time on API side is done
  delete data.time;

  if (whatToEatId) {
    yield put(updateWhatToEatRequestAction(restaurantId, whatToEatId, data));
  } else {
    delete data.file;
    yield put(addWhatToEatRequestAction(restaurantId, data));
  }
  /* eslint-enable no-param-reassign */
}

function* updateWhatToEatFlow(action: Object) {
  try {
    let result = null;

    const { data, restaurantId, whatToEatId } = action.payload;
    const { file } = data;

    if (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      yield put(updateWhatToEatReducerAction({ loading: true }));
      result = yield call(ApiService.getApi().post, '/images', formData);
      delete data.file;
    }

    if (whatToEatId) {
      if (result)
        yield call(
          updateStoreWhatToEatInfo,
          data,
          whatToEatId,
          restaurantId,
          result.response.data.filename,
        );
      else {
        yield call(updateStoreWhatToEatInfo, data, whatToEatId, restaurantId);
      }
    }

    if (result) {
      yield call(
        updateStoreWhatToEatInfo,
        data,
        null,
        restaurantId,
        result.response.data.filename,
      );
    } else {
      yield call(updateStoreWhatToEatInfo, data, null, restaurantId);
    }
  } catch (error) {
    yield call(returnErrorResponseAction, error);
  }
}

export default function* root(): Generator<*, *, *> {
  yield takeEvery(updateStoreMenu, updateMenuFlow);
  yield takeEvery(addStoreMenu, updateMenuFlow);
  yield takeEvery(deleteStoreMenu, redirectMenuOnDeleteSuccess);
  yield takeEvery(addStoreHiring, updateHiringFlow);
  yield takeEvery(updateStoreHiring, updateHiringFlow);
  yield takeEvery(deleteStoreHiring, redirectHiringOnDeleteSuccess);
  yield takeEvery(deleteStoreCampaign, redirectCampaignOnDeleteSuccess);
  yield takeEvery(addStoreContact, addContactFlow);
  yield takeEvery(addStoreWhatToEat, updateWhatToEatFlow);
  yield takeEvery(updateStoreWhatToEat, updateWhatToEatFlow);
  yield takeEvery(deleteStoreWhatToEat, redirectWhatToEatOnDeleteSuccess);
}
