// eslint-disable-next-line import/prefer-default-export
export const removeEmptyValues = (data: Object) => {
  const nonEmptyFields = { operationHours: {} };
  const isNonEmptyValues = value =>
    value !== '' && value !== undefined && value !== null;
  Object.keys(data).forEach(key => {
    if (isNonEmptyValues(data[key])) nonEmptyFields[key] = data[key];
  });
  return nonEmptyFields;
};

export const currencyFormatter = (
  locale: string = 'en-US',
  style: any = 'currency',
  currency: string = 'USD',
) => new Intl.NumberFormat(locale, { style, currency });
