import { createAction } from 'redux-starter-kit';
import ApiService from '../../../service/api/Api.service';
import { searchRequestAction } from '../../../store/request/request.action';
import {
  fetchStoreInformationReducerAction,
  fetchEvaluationsReducerAction,
  fetchMenusReducerAction,
  fetchMenuItemReducerAction,
  addMenuReducerAction,
  updateMenuReducerAction,
  fetchListReducerAction,
  fetchStoreNameReducerAction,
  fetchStoreBalanceReducerAction,
  addHiringReducerAction,
  updateHiringReducerAction,
  fetchHiringInfoReducerAction,
  contactReducerAction,
  fetchWhatToEatReducerAction,
  addWhatToEatReducerAction,
  updateWhatToEatReducerAction,
} from './restaurant.reducer';

const FETCH_LIMIT = 10;

export const updateStoreMenu = createAction('REQUEST_UPDATE_STORE_MENU');

export const addStoreMenu = createAction('REQUEST_ADD_STORE_MENU');

export const deleteStoreMenu = createAction('REQUEST_DELETE_STORE_MENU');

export const addStoreHiring = createAction('REQUST_ADD_STORE_HIRING');

export const updateStoreHiring = createAction('REQUST_UPDATE_STORE_HIRING');

export const deleteStoreHiring = createAction('REQUEST_DELETE_HIRING_MENU');

export const deleteStoreCampaign = createAction(
  'REQUEST_DELETE_STORE_CAMPAIGN',
);

export const addStoreContact = createAction('REQUST_ADD_STORE_CONTACT');

export const addStoreWhatToEat = createAction('REQUST_ADD_STORE_WHAT_TO_EAT');

export const updateStoreWhatToEat = createAction(
  'REQUST_UPDATE_STORE_WHAT_TO_EAT',
);

export const deleteStoreWhatToEat = createAction('REQUEST_DELETE_WHAT_TO_EAT');

export const tableRequestAction = (route: Object) =>
  searchRequestAction(ApiService.getApi().get, route, fetchListReducerAction);

export const fetchStoreInformationRequestAction = (restaurantId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}`,
  resultReducerAction: fetchStoreInformationReducerAction,
});

export const fetchStoreNameRequestAction = (restaurantId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}`,
  resultReducerAction: fetchStoreNameReducerAction,
});

export const fetchStoreBalanceRequestAction = (restaurantId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}/balance`,
  resultReducerAction: fetchStoreBalanceReducerAction,
});

export const fetchEvaluationsRequestAction = (restaurantId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}/evaluations?_limit=${FETCH_LIMIT}`,
  resultReducerAction: fetchEvaluationsReducerAction,
});

export const fetchMenusRequestAction = (restaurantId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}/menu?_limit=${FETCH_LIMIT}`,
  resultReducerAction: fetchMenusReducerAction,
});

export const addMenuRequestAction = (
  payload: Object,
  restaurantId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: `/restaurants/${restaurantId}/menu`,
  resultReducerAction: addMenuReducerAction,
  payload,
});

export const fetchMenuItemAction = (restaurantId: string, menuId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}/menu/${menuId}`,
  resultReducerAction: fetchMenuItemReducerAction,
});

export const updateMenuRequestAction = (
  payload: Object,
  menuId: string,
  restaurantId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/restaurants/${restaurantId}/menu/${menuId}`,
  resultReducerAction: updateMenuReducerAction,
  payload,
});

export const deleteMenuRequestAction = (
  restaurantId: string,
  menuId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/restaurants/${restaurantId}/menu/${menuId}`,
  resultReducerAction: deleteStoreMenu,
});

export const addHiringRequestAction = (
  payload: Object,
  restaurantId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: `/restaurants/${restaurantId}/hirings`,
  resultReducerAction: addHiringReducerAction,
  payload,
});

export const updateHiringRequestAction = (
  payload: Object,
  restaurantId: string,
  hiringId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/restaurants/${restaurantId}/hirings/${hiringId}`,
  resultReducerAction: updateHiringReducerAction,
  payload,
});

export const deleteHiringRequestAction = (
  restaurantId: string,
  hiringId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/restaurants/${restaurantId}/hirings/${hiringId}`,
  resultReducerAction: deleteStoreHiring,
});

export const fetchHiringInfo = (restaurantId: string, hiringId: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}/hirings/${hiringId}`,
  resultReducerAction: fetchHiringInfoReducerAction,
});

export const deleteStoreCampaignRequestAction = (id: string) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/campaigns/${id}`,
  resultReducerAction: deleteStoreCampaign,
});

export const addContactRequestAction = (payload: Object) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: `/admin/inquiry`,
  resultReducerAction: contactReducerAction,
  payload,
});

export const fetchWhatToEatRequestAction = (
  restaurantId: string,
  whatToEatId: Object,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().get,
  route: `/restaurants/${restaurantId}/whatToEats/${whatToEatId}`,
  resultReducerAction: fetchWhatToEatReducerAction,
});

export const addWhatToEatRequestAction = (
  restaurantId: string,
  payload: Object,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().post,
  route: `/restaurants/${restaurantId}/whatToEats`,
  resultReducerAction: addWhatToEatReducerAction,
  payload,
});

export const updateWhatToEatRequestAction = (
  restaurantId: string,
  whatToEatId: string,
  payload: Object,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().patch,
  route: `/restaurants/${restaurantId}/whatToEats/${whatToEatId}`,
  resultReducerAction: updateWhatToEatReducerAction,
  payload,
});

export const deleteWhatToEatRequestAction = (
  restaurantId: string,
  whatToEatId: string,
) => ({
  type: 'REQUEST',
  method: ApiService.getApi().delete,
  route: `/restaurants/${restaurantId}/whatToEats/${whatToEatId}`,
  resultReducerAction: deleteStoreWhatToEat,
});
