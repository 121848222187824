import * as yup from 'yup';

const FILE_SIZE = 512 * 1024;

export default yup.object().shape({
  title: yup.string().required('required'),
  description: yup.string().required('required'),
  time: yup.string().required('required'),
  file: yup.mixed().test('fileSize', 'imageErrorSize', value => {
    if (value) return value.file.size <= FILE_SIZE;
    return true;
  }),
  image: yup.string(),
});
