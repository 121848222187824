import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ImageMaintenance } from '../../../assets/svg';

type State = {
  destination: string,
};

class MaintenancePage extends PureComponent<*, State> {
  render() {
    const { t } = this.props;
    return (
      <div className="errorContainer">
        <ImageMaintenance />
        <h1 className="errorHeader">{t('maintenanceTitle')}</h1>
        <div className="messageContainer">{t('maintenanceMessage')}</div>
        <div className="messageContainer">{t('backSoon')}</div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MaintenancePage));
