import React, { PureComponent } from 'react';

type Props = {
  className?: string,
};

class Users extends PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <svg
        width="23"
        height="20"
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={this.props.className ? this.props.className : ''}
          d="M20.8692 15.4901L17.419 13.7651C17.0936 13.6023 16.8913 13.275 16.8913 12.911V11.6899C16.974 11.5888 17.0611 11.4735 17.151 11.3464C17.5985 10.7144 17.9571 10.0108 18.2179 9.25201C18.7275 9.01833 19.0583 8.5145 19.0583 7.94567V6.501C19.0583 6.1532 18.9283 5.81623 18.6972 5.55294V3.63189C18.7174 3.43325 18.7969 2.25079 17.9416 1.27528C17.1998 0.429066 15.996 0 14.3632 0C12.7303 0 11.5266 0.429066 10.7847 1.27492C9.92949 2.25043 10.0089 3.43325 10.0292 3.63153V5.55258C9.79803 5.81587 9.66801 6.15284 9.66801 6.50064V7.94531C9.66801 8.38485 9.86773 8.79477 10.2087 9.06817C10.5395 10.3781 11.2319 11.3652 11.4738 11.6819V12.877C11.4738 13.2266 11.2831 13.5474 10.9765 13.7149L7.75455 15.4724C6.70716 16.0437 6.0567 17.1395 6.0567 18.3328V19.8642H22.6704V18.404C22.67 17.1623 21.9798 16.0456 20.8692 15.4901Z"
          fill="white"
        />
        <path
          className={this.props.className ? this.props.className : ''}
          d="M6.49486 14.9975L9.71683 13.24C9.84938 13.1674 9.93208 13.0284 9.93208 12.8774V11.8611C9.61245 11.4161 9.04867 10.5204 8.72687 9.36543C8.34295 8.99271 8.12625 8.48527 8.12625 7.94568V6.50102C8.12625 6.09507 8.25338 5.69959 8.48742 5.36659V3.65719C8.47514 3.51886 8.46069 3.19887 8.52209 2.7922C8.02476 2.61884 7.47145 2.52818 6.86217 2.52818C3.11001 2.52818 2.8915 5.72631 2.88933 5.77868V7.43247C2.6553 7.68745 2.52817 7.9818 2.52817 8.27398V9.52145C2.52817 9.91115 2.70261 10.2745 3.00166 10.5201C3.29962 11.6433 4.03243 12.492 4.31775 12.79V13.7807C4.31775 14.0649 4.16317 14.3257 3.89482 14.4723L1.49198 15.9795C0.571727 16.4815 0 17.4447 0 18.4928V19.8642H4.51458V18.3336C4.51458 16.942 5.27339 15.6638 6.49486 14.9975Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default Users;
